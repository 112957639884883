import styled from '@emotion/styled/macro';

interface Props {
  checked?: boolean;
  disabled: boolean;
}

export const CheckboxContainer = styled.div`
  align-items: center;
  display: inline-flex;
  pointer-events: ${(props: Props) => (props.disabled ? 'none' : 'initial')};
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props: Props) => (props.disabled ? '#e8e8e8' : (props: any) => props.theme.neutral80)};
  border-radius: 1px;
  background: ${(props: Props) => (props.disabled ? '#f6f6f6' : props.checked ? '#002340' : '#FFFFFF')};
  transition: background-color 150ms;
  ${Icon} {
    visibility: ${(props: Props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const LabelText = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${(props: Props) => (props.disabled ? '#a8aaaf' : (props: any) => props.theme.neutral80)};
  margin-left: 8px;
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: ${(props: Props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
