/* istanbul ignore file */
import axios from 'axios';
import { model, Model, modelAction, prop } from 'mobx-keystone';
import { Map } from 'ol';
import { boundingExtent } from 'ol/extent';
import { fromExtent as PolygonFromExtent } from 'ol/geom/Polygon';
import { WKT } from 'ol/format';
import { computed } from 'mobx';

@model('geodas/IdentifyStore')
export class IdentifyStore extends Model({
  currentFeatureData: prop<any>(),
  features: prop<any>(),
}) {
  pixelRadius: number = 10;

  @computed
  get currentData(){
    return this.currentFeatureData;
  }

  @modelAction
  setCurrentData(v: any, prop?: {k: string, v: any}){
    if(prop) v[prop.k] = prop.v;
    this.currentFeatureData = v;
  }

  @computed
  get getFeatures(){
    return this.features ? this.features : {};
  }

  getFeature(ftId: number, layerId: number){
    if(this.getFeatures[layerId]){
      if(this.getFeatures[layerId][ftId]){
        return this.getFeatures[layerId][ftId];
      }
    }
    return null;
  }

  @modelAction
  setFeature(ft: {id: number, fields: any, geom: string}, layerId: number){
    const features = this.getFeatures;
    if(!features[layerId]) features[layerId] = {}
    features[layerId][ft.id]= ft;
    this.features = features;
  }


  getPointInMap(map: Map, pixel: Array<number>){
    const bounds = boundingExtent([
      map.getCoordinateFromPixel([
        pixel[0] - this.pixelRadius,
        pixel[1] - this.pixelRadius
      ]),
      map.getCoordinateFromPixel([
        pixel[0] + this.pixelRadius,
        pixel[1] + this.pixelRadius
      ])
    ])

    return new WKT().writeGeometry(
      PolygonFromExtent(bounds)
    )
  }

  async execute(map: Map, pixel: Array<number>, layers: Array<number>, _fm: boolean = false,onSuccess?: () => void, onError?: () => void){  

    if(layers.length < 1){
      return false;
    }

    const request = {
      srs: 3857,
      geom: this.getPointInMap(map, pixel),
      layers: layers
    };
    const config = {
      headers: {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    };

    const {data} = await axios.post(
      'https://mvp.clients.satellogic.com/api/feature_layer/identify',
      request,
      config
    )

    if(data.featureCount > 0 && data[layers[0]].features){
      this.setCurrentData({
        id: data[layers[0]].features[0].id,
        layerId: data[layers[0]].features[0].layerId,
        data: _fm ? data[layers[0]].features[0].fields : this.formatData(data[layers[0]].features[0].fields)
      });
      if(!this.getFeature(data[layers[0]].features[0].id, data[layers[0]].features[0].layerId)){
        await this.fetchGeom(data[layers[0]].features[0].id, data[layers[0]].features[0].layerId);
      }
      if(typeof onSuccess === "function"){onSuccess()}
      return this.getFeature(data[layers[0]].features[0].id, data[layers[0]].features[0].layerId)
    } else {
      this.setCurrentData(undefined)
      if(typeof onError === "function"){onError()}
    }

    return null
  }

  saveFeature(geom: string, layerId: number, callback?: (data: any) => void){
    let request: any = [{
      geom: geom,
    }]

    if(this.currentFeatureData){
      layerId = this.currentFeatureData.layerId
      request[0]['id'] = this.currentFeatureData.id
    }

    const config = {
      headers: {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    };

    axios.patch(
      `https://mvp.clients.satellogic.com/api/resource/${layerId}/feature/`,
      request,
      config
    )
    .then(response => {
      if(callback) callback(response)
    })
  }

  async fetchGeom(ftId: number, layerId: number){
    const { data } = await axios.get(`https://mvp.clients.satellogic.com/api/resource/${layerId}/feature/${ftId}`)
    this.setFeature(data, layerId);
  }


  deleteFeature(callback?: (data: any) => void){
    const request = [{
      id: this.currentFeatureData.id
    }]
    axios.delete(
      `https://mvp.clients.satellogic.com/api/resource/${this.currentFeatureData.layerId}/feature/`,
      {
        headers: {
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        data: request
      }
    )
    .then(response => {
      if(callback) callback(response)
    })
  }


  formatData(resp: any){
    let _returner: any = {};
    Object.entries(resp).map(item => {
      if(typeof item[1] === "number" && item[0].indexOf('_') !== -1){
        const date_split = item[0].split('_');
        if(date_split[0] !== 'ngw'){
          const date = `${date_split[1].slice(4,6)}/${date_split[1].slice(6,8)}/${date_split[1].slice(0,4)}`
          if(!_returner[date_split[0]]){_returner[date_split[0]] = []}
          _returner[date_split[0]].push({"date": date, "value": item[1].toFixed(2)});
        }
      }
      return false;
    })
    const keys = Object.keys(_returner).sort();
    const _r: any = {};
    keys.map(item => {
      _r[item] = _returner[item].sort((a: {date: string}, b: {date: string}) => {
        return (Date.parse(a.date) > Date.parse(b.date)) ? 1 : -1
      })
      return false;
    })
    return _r;
  }
}
