import styled from '@emotion/styled/macro';

export const FiltersContainer = styled.div`
  display: flex;
  /* opacity: 0;
  transition: all 330ms ease-in-out; */
  //transform: translate(0%, -150px);
  /* animation: fade-out 0.5s forwards; */

  /* &.visible {
    opacity: 1;
    transition: all 900ms ease-in-out;
    //transform: translate(0%, 0%);
    display: flex;
    gap: 1rem;
  } */
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    margin-top: -1.5rem;
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
  }
`;

export const FilterButtonAcordeon = styled.div`
  background: red;
  cursor: pointer;
  font-size: 20pt;
  padding: 16px;
  width: 500px;
  box-sizing: border-box;
  &:hover {
    opacity: 0.7;
  }
`;

export const InputTriggerFilter = styled.input`
  &:checked ~ .content {
    max-height: 150px;
  }
`;

export const NumericInputFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;

  .dropdown {
    height: 44px;
  }

  .input {
    width: 300px;
  }
`;

export const TitleWithIcon = styled.div`
  align-items: center;
  color: ${(props: any) => props.theme.primary10};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
  ${(props: any) => props.theme.small.styles};
  .action--icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    &.close--icon {
      width: 9px;
      height: 9px;
      fill: ${(props: any) => props.theme.primary10};
    }
    path {
      stroke: ${(props: any) => props.theme.primary10};
      transition: stroke 0.2s ease-in, fill 0.2s ease-in;
    }
  }
  &:hover {
    color: ${(props: any) => props.theme.primary40};
    .action--icon {
      path {
        stroke: ${(props: any) => props.theme.primary40};
      }
    }
  }
`;