import React from 'react';
import { observer } from 'mobx-react-lite';
import { Global, css } from '@emotion/react';

import { Layout } from 'components/layout';
import { Button } from 'components/button';
import { MagnifierIcon } from 'components/icons';

import { Container, Title, FlexContainer } from './playground.styles';

export const Playground = observer(() => {
  return (
    <Layout>
      <Global styles={
        css`
          body.root {
            overflow: auto;
          }`
      } />
      <Container>
        <Title>Button Component</Title>
        <FlexContainer>
          <Button text="Regular Button" />
          <Button text="Disabled Button" disabled />
          <Button text="Disabled Button with Icon" icon={<MagnifierIcon />} disabled />
          <Button text="Button with Left Icon" icon={<MagnifierIcon />} />
          <Button text="Button with Right Icon" icon={<MagnifierIcon />} rightIcon />
        </FlexContainer>
        <FlexContainer>
          <Button text="Secondary Button" secondary />
          <Button text="Disabled Secondary Button" secondary disabled />
          <Button text="Disabled Secondary Button with Icon" icon={<MagnifierIcon />} secondary disabled />
          <Button text="Secondary Button with Left Icon" icon={<MagnifierIcon />} secondary />
          <Button text="Secondary Button with Right Icon" icon={<MagnifierIcon />} secondary rightIcon />
        </FlexContainer>
        <FlexContainer>
          <Button text="Regular Small Button" small />
          <Button text="Disabled Small Button" small disabled />
          <Button text="Disabled Small Button with Icon" icon={<MagnifierIcon />} small disabled />
          <Button text="Small Button with Left Icon" icon={<MagnifierIcon />} small />
          <Button text="Small Button with Right Icon" icon={<MagnifierIcon />} small rightIcon />
        </FlexContainer>
        <FlexContainer>
          <Button text="Small Secondary Button" small secondary />
          <Button text="Disabled Small Secondary Button" small secondary disabled />
          <Button text="Disabled Small Secondary Button with Icon" icon={<MagnifierIcon />} small secondary disabled />
          <Button text="Small Secondary Button with Left Icon" icon={<MagnifierIcon />} secondary small />
          <Button text="Small Secondary Button with Right Icon" icon={<MagnifierIcon />} secondary small rightIcon />
        </FlexContainer>
        <FlexContainer>
          <Button text="Only Text Button" onlyText />
          <Button text="Disabled Only Text Button" onlyText disabled />
          <Button text="Disabled Only Text Button with Icon" icon={<MagnifierIcon />} onlyText disabled />
          <Button text="Only Text Button with Icon" icon={<MagnifierIcon />} onlyText />
          <Button text="Only Text Button with Icon" icon={<MagnifierIcon />} onlyText rightIcon />
        </FlexContainer>
        <FlexContainer>
          <Button text="Only Text Small Button" onlyText small />
          <Button text="Disabled Only Text Small Button" onlyText disabled small />
          <Button text="Disabled Only Text Small Button with Icon" icon={<MagnifierIcon />} onlyText disabled small />
          <Button text="Only Text Small Button with Icon" icon={<MagnifierIcon />} onlyText small />
          <Button text="Only Text Small Button with Icon" icon={<MagnifierIcon />} onlyText rightIcon small />
        </FlexContainer>
        <FlexContainer>
          <Button text="Create" fab />
          <Button text="Create" fab disabled />
        </FlexContainer>
        <FlexContainer>
          <Button icon={<MagnifierIcon />} />
          <Button icon={<MagnifierIcon />} disabled />
        </FlexContainer>
        <FlexContainer>
          <Button icon={<MagnifierIcon />} small />
          <Button icon={<MagnifierIcon />} small disabled />
        </FlexContainer>
        <FlexContainer>
          <Button icon={<MagnifierIcon />} clearIcon />
        </FlexContainer>

        <Title>Link Component</Title>
        <FlexContainer>
          <Button to="/" text="Regular Button" />
          <Button to="/" text="Disabled Button" disabled />
          <Button to="/" text="Disabled Button with Icon" icon={<MagnifierIcon />} disabled />
          <Button to="/" text="Button with Left Icon" icon={<MagnifierIcon />} />
          <Button to="/" text="Button with Right Icon" icon={<MagnifierIcon />} rightIcon />
        </FlexContainer>
        <FlexContainer>
          <Button to="/" text="Secondary Button" secondary />
          <Button to="/" text="Disabled Secondary Button" secondary disabled />
          <Button to="/" text="Disabled Secondary Button with Icon" icon={<MagnifierIcon />} secondary disabled />
          <Button to="/" text="Secondary Button with Left Icon" icon={<MagnifierIcon />} secondary />
          <Button to="/" text="Secondary Button with Right Icon" icon={<MagnifierIcon />} secondary rightIcon />
        </FlexContainer>
        <FlexContainer>
          <Button to="/" text="Regular Small Button" small />
          <Button to="/" text="Disabled Small Button" small disabled />
          <Button to="/" text="Disabled Small Button with Icon" icon={<MagnifierIcon />} small disabled />
          <Button to="/" text="Small Button with Left Icon" icon={<MagnifierIcon />} small />
          <Button to="/" text="Small Button with Right Icon" icon={<MagnifierIcon />} small rightIcon />
        </FlexContainer>
        <FlexContainer>
          <Button to="/" text="Small Secondary Button" small secondary />
          <Button to="/" text="Disabled Small Secondary Button" small secondary disabled />
          <Button to="/" text="Disabled Small Secondary Button with Icon" icon={<MagnifierIcon />} small secondary disabled />
          <Button to="/" text="Small Secondary Button with Left Icon" icon={<MagnifierIcon />} secondary small />
          <Button to="/" text="Small Secondary Button with Right Icon" icon={<MagnifierIcon />} secondary small rightIcon />
        </FlexContainer>
        <FlexContainer>
          <Button to="/" text="Only Text Button" onlyText />
          <Button to="/" text="Disabled Only Text Button" onlyText disabled />
          <Button to="/" text="Disabled Only Text Button with Icon" icon={<MagnifierIcon />} onlyText disabled />
          <Button to="/" text="Only Text Button with Icon" icon={<MagnifierIcon />} onlyText />
          <Button to="/" text="Only Text Button with Icon" icon={<MagnifierIcon />} onlyText rightIcon />
        </FlexContainer>
        <FlexContainer>
          <Button to="/" text="Only Text Small Button" onlyText small />
          <Button to="/" text="Disabled Only Text Small Button" onlyText disabled small />
          <Button to="/" text="Disabled Only Text Small Button with Icon" icon={<MagnifierIcon />} onlyText disabled small />
          <Button to="/" text="Only Text Small Button with Icon" icon={<MagnifierIcon />} onlyText small />
          <Button to="/" text="Only Text Small Button with Icon" icon={<MagnifierIcon />} onlyText rightIcon small />
        </FlexContainer>
        <FlexContainer>
          <Button to="/" icon={<MagnifierIcon />} />
          <Button to="/" icon={<MagnifierIcon />} disabled />
        </FlexContainer>
        <FlexContainer>
          <Button to="/" icon={<MagnifierIcon />} small />
          <Button to="/" icon={<MagnifierIcon />} small disabled />
        </FlexContainer>
        <FlexContainer>
          <Button to="/" icon={<MagnifierIcon />} clearIcon />
        </FlexContainer>
      </Container>
    </Layout>
  )
})
