import { styled } from 'config/common';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  margin-top: 48px;
  position: relative;
  .inner--map {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

export const MapContainer = styled.div`
  width: calc(100% - 400px);
  height: 100%;
  position: relative;
`;

export const Sidebar = styled.aside`
  width: 576px;
  height: 100%;
  background: ${(props: any) => props.theme.neutral};
  border-left: 1px solid #cdcdcd;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 25px 15px;
  position: relative;
  .request-task {
    width: 100%;
    max-width: 237px;
    flex: 0 0 auto;
    margin-left: auto;
    z-index: 100;
  }
`;

export const FormTitle = styled.h1`
  color: ${(props: any) => props.theme.neutral80};
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const FormSubtitle = styled.h2`
  width: 100%;
  align-items: center;
  color: ${(props: any) => props.theme.neutral80};
  display: flex;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 15px;
  white-space: nowrap;
  &::after {
    width: 100%;
    height: 1px;
    background-color: ${(props: any) => props.theme.neutral20};
    content: ' ';
    margin-left: 15px;
  }
`;

export const FormText = styled.p`
  color: ${(props: any) => props.theme.neutral60};
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  line-height: 1.25rem;
  margin-bottom: 15px;
  &.target-area {
    flex: 0 0 100%;
    color: ${(props: any) => props.theme.neutral80};
    font-size: ${(props: any) => props.theme.sizes.small};
  }
`;

export const Form = styled.form`
  width: 100%;
  flex: 1 1 auto;
`;

export const FormGroup = styled.section`
  width: 100%;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
  .form-input {
    width: 100%;
    margin: 0 0 20px 0;
    input {
      padding: 10px 12px;
    }
    &.half,
    &.date {
      width: calc(50% - 0.5rem);
    }
    &.delete-draw {
      width: auto;
      color: ${(props: any) => props.theme.errorDark};
      font-family: 'Public Sans', sans-serif;
      font-size: 14px;
      margin-top: -30px;
      margin-left: auto;
      padding: 0;
      z-index: 1;
      svg {
        width: 16px;
        height: 16px;
        fill: ${(props: any) => props.theme.errorDark};
        margin-right: 6px;
      }
    }
  }
  textarea.form-input {
    height: 109px;
  }
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .form-input {
    width: 100%;
    margin: 0 10px 0 0;
  }
  .pointer {
    cursor: pointer;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  min-width: 16.688rem;
`;

export const InfoTitle = styled.h3`
  width: 100%;
  color: ${(props: any) => props.theme.neutral80};
  font-size: ${(props: any) => props.theme.sizes.small};
  font-weight: 500;
  white-space: nowrap;
`;

export const InfoText = styled.div`
  width: 100%;
  color: ${(props: any) => props.theme.neutral60};
  display: block;
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  line-height: 1.25rem;
  margin-top: 10px;
`;

export const MapPopup = styled.div`
  width: 400px;
  .popup-title {
    ${(props: any) => props.theme.heading4};
    font-weight: 500;
    line-height: 24px;
    margin-bottom: ${(props: any) => props.theme.sizes.xxlarge};
  }
  .lat-long {
    ${(props: any) => props.theme.body};
    li {
      margin-bottom: ${(props: any) => props.theme.sizes.xxlarge};
    }
  }
  .delete-button {
    width: 100%;
    height: auto;
    box-shadow: none;
    justify-content: flex-start;
    padding: 0;
    text-align: left;
    &:hover {
      background-color: transparent;
      color: ${(props: any) => props.theme.primary};
    }
  }
  .close--btn {
    color: ${(props: any) => props.theme.neutral60};
    cursor: pointer;
    font-size: 40px;
    line-height: 16px;
    font-weight: 300;
    text-decoration: none;
    position: absolute;
    top: 12px;
    right: 8px;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  svg {
    stroke: ${(props: any) => props.theme.primary};
  }
`;

export const BigAOIDisclaimer = styled.div`
  width: 100%;
  align-items: center;
  background-color: ${(props: any) => props.theme.warningLight};
  border-radius: 8px;
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  margin-bottom: 16px;
  padding: 16px;
`
