import { styled } from 'config/common';

export const PopupContainer = styled.div`
  width: auto;
  z-index: 9999;
  opacity: 0;
  border-radius: 4px;
  border: 1px solid ${(props: any) => props.theme.neutral};
  padding: ${(props: any) => props.theme.sizes.small};
  background: ${(props: any) => props.theme.neutral};
  box-shadow: rgb(0 0 0 / 20%) 0px 8px 16px;
  color: ${(props: any) => props.theme.primary};
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  pointer-events: none;
  transform: translate(-50%, calc(-100% - 30px));
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  &::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    border-style: solid;
    border-color: ${(props: any) => props.theme.neutral} transparent transparent transparent;
    border-width: 10px 10px 0 10px;
  }
  &::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    bottom: -11px;
    left: calc(50% - 10px);
    border-style: solid;
    border-color: ${(props: any) => props.theme.neutral} transparent transparent transparent;
    border-width: 10px 10px 0 10px;
  }
  &.show {
    opacity: 1;
    pointer-events: all;
  }
`;
