import styled from '@emotion/styled/macro';

export const Title = styled.h1`
  margin: 0 40px 20px 0;
  color: ${(props: any) => props.theme.neutral80};
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`

export const ProjectModalContainer = styled.div`
  width: 100%;
  background-color: ${(props: any) => props.theme.neutral};
  padding: 1rem;
  .input {
    width: 360px;
    margin-bottom: 16px;
  }
  .confirmButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button {
      width: 130px;
      svg {
        margin-right: 18px;
      }
    }
  }
`
