import Control from 'ol/control/Control';

export class SearchControl extends Control {
  constructor(callback: () => void, className: string = '') {
    const button = document.createElement('button');
    button.type = 'button';
    button.className = 'ol-search-button';
    const element = document.createElement('div');
    element.className = `ol-search ${className}`;
    element.appendChild(button);
    super({
      element: element
    });
    button.addEventListener('click', () => callback());
  }

}