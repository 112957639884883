import styled from '@emotion/styled';

export const Title = styled.h4`
  margin-bottom: 20px;
  color: ${(props: any) => props.theme.neutral80};
  ${(props: any) => props.theme.subtitle.styles};
  line-height: 24px;
`;

export const Text = styled.p`
  ${(props: any) => props.theme.body.styles};
  line-height: 20px;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;
  * {
    margin-left: 16px;
  }
`;

export const ModalContent = styled.div`
  ${(props: any) => props.theme.body.styles};
  line-height: 20px;
`;

export const ModalContainer = styled.div`
  max-width: 550px;
  background: ${(props: any) => props.theme.neutral};
  border: 1px solid ${(props: any) => props.theme.neutral20};
  padding: 1rem;
`;
