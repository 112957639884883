import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Global, css } from '@emotion/react';

import { Layout, Container, TopBar } from 'components/layout';
import { BlankIcon, LoadingIcon, ChevronIconThin } from 'components/icons';
import { TaskItem } from './components/taskitem';
import { TaskDetail } from './components/taskdetail';
import { ProjectModal } from 'components/modal/projectmodal';
import { NoTask } from './components/notask';
import { PlusRoundIcon } from 'components/icons';

import { useModal, useStores } from 'config/hooks';
import { classes } from 'config/common';
import { Task } from 'typings';

import {
  TasksList,
  TaskDetailWrapper,
  LoadingWrapper,
  TasksTable,
  TableContainer,
  TableRow,
  ArrowSort,
  TableColumn,
  PagMoreWrapper,
  More,
  MoreText,
  Paginator,
  RowsPerPage,
  PageDrop,
  PageList,
  PageItem,
  RowText,
  Pages,
  Arrows,
  ArrowButton
} from './projects.styles';

export const Projects = observer(() => {
  const {
    rootStore: { taskStore }
  } = useStores();
  const navigate = useNavigate();
  const { isShown, toggle } = useModal();
  const [showSidebar, setShowSidebar] = React.useState(false);

  //Handle Sort
  const [tagOrder, setTagOrder] = React.useState('start');
  const [order, setOrder] = React.useState(false);
  const desc = order === false;
  const orderColumn = (tag: string) => {
    setTagOrder(tag);
    setOrder(!order)
  }

  const productMatcher = (pk: number) => {
    const product =taskStore.products?.find((i: { pk: number; }) => i.pk.toString() === pk.toString())
    return product && product.name ? product.name : ''
  };

  const compareData = (a: any, b: any) => {
    if (tagOrder === 'product') {
      if (productMatcher(a[tagOrder])?.toLowerCase() === productMatcher(b[tagOrder])?.toLowerCase()) {
        return 0;
      } else if (productMatcher(a[tagOrder]) === undefined || productMatcher(a[tagOrder]) === null) {
        return desc ? -1 : 1;
      } else if (productMatcher(b[tagOrder]) === undefined || productMatcher(b[tagOrder]) === null) {
        return desc ? 1 : -1;
      } else if (productMatcher(a[tagOrder])?.toLowerCase() > productMatcher(b[tagOrder])?.toLowerCase()) {
        return desc ? -1 : 1;
      } else if (productMatcher(a[tagOrder])?.toLowerCase() < productMatcher(b[tagOrder])?.toLowerCase()) {
        return desc ? 1 : -1;
      } else {
        return 0
      }
    } else if (tagOrder === 'task_id') {
      if (a[tagOrder] === b[tagOrder]) {
        return 0;
      } else if (a[tagOrder] === undefined || a[tagOrder] === null) {
        return desc ? 1 : -1;
      } else if (b[tagOrder] === undefined || b[tagOrder] === null) {
        return desc ? -1 : 1;
      } else if (a[tagOrder] > b[tagOrder]) {
        return desc ? -1 : 1;
      } else if (a[tagOrder] < b[tagOrder]) {
        return desc ? 1 : -1;
      } else {
        return 0
      }
    } else {
      if (a[tagOrder]?.toLowerCase() === b[tagOrder]?.toLowerCase()) {
        return 0;
      } else if (a[tagOrder] === undefined || a[tagOrder] === null) {
        return desc ? 1 : -1;
      } else if (b[tagOrder] === undefined || b[tagOrder] === null) {
        return desc ? -1 : 1;
      } else if (a[tagOrder]?.toLowerCase() > b[tagOrder]?.toLowerCase()) {
        return desc ? -1 : 1;
      } else if (a[tagOrder]?.toLowerCase() < b[tagOrder]?.toLowerCase()) {
        return desc ? 1 : -1;
      } else {
        return 0
      }
    }
  }
  const ordererTasks = taskStore.tasks?.slice().sort(compareData);

  //Handle Pagination tasks
  const totalTasks = taskStore.tasks?.length;
  const [tasksPerPage, setTasksPerPage] = React.useState(25);
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalPages = Math.ceil(totalTasks / tasksPerPage);

  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = ordererTasks?.slice(indexOfFirstTask, indexOfLastTask);

  const increment = () => { setCurrentPage(currentPage + 1) }
  const decrement = () => { setCurrentPage(currentPage - 1) }
  React.useEffect(() => {
    if (currentPage > totalPages || currentPage <= 0) setCurrentPage(1)
  }, [currentPage, totalPages]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (taskStore.filtering && totalTasks <= 25)
      setTasksPerPage(25)
    else setTasksPerPage(tasksPerPage)
  });

  React.useEffect(() => {
    taskStore.init();
  }, [taskStore]);

  React.useEffect(() => {
    if (!taskStore.activeTask) setShowSidebar(false);
  }, [taskStore.activeTask]);

  const buttonOptions = [
    {
      icon: <PlusRoundIcon stroke="#282828" />,
      text: 'New task',
      to: '/tasking'
    },
    {
      icon: <PlusRoundIcon stroke="#282828" />,
      text: 'New project',
      action: () => toggle()
    }
  ];

  const cancelTaskAction = (t: Task, success?: () => void) => {
    taskStore.cancelTask(t, () => {
      if (success) success();
    });
  };

  return (
    <Layout>
      <Global
        styles={css`
          body.root {
            overflow: auto;
          }
        `}
      />
      <TopBar
        title="My Tasks"
        filters={{
          onApply: data => {
            setShowSidebar(false);
            taskStore.setActiveTask('');
            taskStore.applyFilters(data);
          },
          default: taskStore.filters
        }}
        createButton={{ options: buttonOptions }}
      />
      <TaskDetailWrapper
        className={classes({
          active: showSidebar,
          shadow: showSidebar,
          topBarExpanded: taskStore.topBarExpanded
        })}
      >
        {taskStore.activeTask ? (
          <TaskDetail
            products={taskStore.products}
            task={taskStore.tasks.find(
              (item: Task) => item.task_id === taskStore.activeTask
            )}
            onClose={() => {
              setShowSidebar(false);
              setTimeout(() => {
                taskStore.setActiveTask('');
              }, 350);
            }}
            onCancelTask={(t, success) => cancelTaskAction(t, success)}
          />
        ) : null}
      </TaskDetailWrapper>

      <TasksList
        className={classes({ sidebarOpen: showSidebar, topBarExpanded: taskStore.topBarExpanded })}
      >
        <Container className="container">

          {!taskStore.tasks && totalTasks === 0 && (
            <NoTask
              icon={<BlankIcon />}
              title="You haven’t tasked any image yet"
              text="It looks like there’s no information on this section. Check again at some other time"
              buttonText="Create a task"
              buttonAction={() => navigate('/tasking')}
            />
          )}
          {totalTasks === 0 && taskStore.filtering ? (
            <NoTask
              icon={<BlankIcon />}
              title="No tasks matched your search"
              text="Try searching by project or task name"
            />
          ) : (
            <React.Fragment>
              <TasksTable>
                <TableContainer>
                  <TableRow className={classes('headers', { 'disabled': !taskStore.tasks })}>
                    <TableColumn onClick={() => orderColumn('task_id')}>Task ID <ArrowSort className={classes({ up: !order && tagOrder === 'task_id' })} /></TableColumn>
                    <TableColumn onClick={() => orderColumn('task_name')}>Task name <ArrowSort className={classes({ up: !order && tagOrder === 'task_name' })} /></TableColumn>
                    <TableColumn onClick={() => orderColumn('project_name')}>Project<ArrowSort className={classes({ up: !order && tagOrder === 'project_name' })} /></TableColumn>
                    <TableColumn onClick={() => orderColumn('status')}>Status <ArrowSort className={classes({ up: !order && tagOrder === 'status' })} /></TableColumn>
                    <TableColumn onClick={() => orderColumn('product')}>Product <ArrowSort className={classes({ up: !order && tagOrder === 'product' })} /></TableColumn>
                    <TableColumn onClick={() => orderColumn('start')}>Start date <ArrowSort className={classes({ up: !order && tagOrder === 'start' })} /></TableColumn>
                    <TableColumn onClick={() => orderColumn('end')}>End date <ArrowSort className={classes({ up: !order && tagOrder === 'end' })} /></TableColumn>
                  </TableRow>
                  {!taskStore.tasks ?
                    (
                      <LoadingWrapper>
                        <LoadingIcon />
                      </LoadingWrapper>
                    ) :
                    (currentTasks.map((item: Task, k: number) => (
                      <TaskItem
                        key={k}
                        task={item}
                        className={classes({ active: taskStore.activeTask === item.task_id })}
                        onTaskClick={async (id: number) => {
                          await taskStore.getTaskInfo(item)
                          setShowSidebar(true)
                          taskStore.setActiveTask(id)
                        }}
                        products={taskStore.products ? taskStore.products : []}
                      />
                    )))

                  }
                </TableContainer>
              </TasksTable>
              <PagMoreWrapper>
                <Paginator className={classes({ 'disabled': !taskStore.tasks })}>
                  <RowsPerPage>
                    <RowText>Tasks per page:</RowText>
                    <PageDrop>
                      {tasksPerPage} <ArrowSort />
                      <PageList>
                        <PageItem onClick={() => setTasksPerPage(25)}>25</PageItem>
                        <PageItem onClick={() => setTasksPerPage(50)}>50</PageItem>
                        <PageItem onClick={() => setTasksPerPage(100)}>100</PageItem>
                      </PageList>
                    </PageDrop>
                  </RowsPerPage>

                  <Pages>
                    {!totalTasks
                      ? (``)
                      : (`${currentPage} of ${totalPages} ${totalPages === 1 ? 'page' : 'pages'}`)
                    }
                  </Pages>
                  <Arrows>
                    <ArrowButton onClick={decrement} className={classes({ 'disabled': currentPage === 1 })}><ChevronIconThin className="prev-page" /></ArrowButton>
                    <ArrowButton onClick={increment} className={classes({ 'disabled': currentPage === totalPages })} ><ChevronIconThin /></ArrowButton>
                  </Arrows>
                </Paginator>

                {!taskStore.tasks ? ' '
                  : <More>
                    {!taskStore.hasNext
                      ? ''
                      : <React.Fragment><MoreText>Loading more tasks...</MoreText> <LoadingWrapper><LoadingIcon /></LoadingWrapper></React.Fragment>
                    }
                  </More>}
              </PagMoreWrapper>

            </React.Fragment>
          )
          }
        </Container>
      </TasksList>
      <ProjectModal
        isShown={isShown}
        onClose={toggle}
        onClick={name =>
          taskStore.addProject({ name }, () => navigate(`/tasking?project=${encodeURI(name)}`))
        }
      />
    </Layout>
  );
});
