import React from 'react';

import { PlusRoundIcon } from 'components/icons';

import { StyledButton, StyledLink, StyledExternalLink } from './button.style';
import { classes } from 'config/common';

interface Props {
  /** CSS class name */
  className?: string;
  /** Text to display on the button */
  text?: string;
  /** Icon to display */
  icon?: JSX.Element
  /** On click handler */
  onClick?: (e?: any) => void;
  /** Url to navigate to */
  to?: string;
  /** Button styles */
  disabled?: boolean;
  small?: boolean;
  secondary?: boolean;
  onlyText?: boolean;
  fab?: boolean
  rightIcon?: boolean
  onlyIcon?: boolean
  clearIcon?: boolean
  external?: boolean
}

export const Button = ({className, to, onClick, text, disabled, small, secondary, onlyText, fab, icon, rightIcon, clearIcon, onlyIcon, external}: Props) => {

  if (!to && !external) {
    return (
      <StyledButton className={ classes(className, { disabled, small, secondary, onlyText, fab, icon: onlyIcon, rightIcon, clearIcon }) } disabled={disabled} onClick={onClick}>
        {fab ? <PlusRoundIcon /> : icon} {text}
      </StyledButton>
    );
  } else if (to && !external) {
    return (
      <StyledLink className={ classes(className, { disabled, small, secondary, onlyText, fab, icon: onlyIcon, rightIcon, clearIcon }) } onClick={onClick} to={to}>
        {icon} {text}
      </StyledLink>
    )
  } else {
    return (
      <StyledExternalLink className={ classes(className, { disabled, small, secondary, onlyText, fab, icon: onlyIcon, rightIcon, clearIcon }) }  href={to}>
        {icon} {text}
      </StyledExternalLink>
    )
  }
};

Button.displayName = "Button";
