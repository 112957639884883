import styled from '@emotion/styled/macro';

export const BottomContainer = styled.div`
  height: calc(100vh - 48px);
  align-items: stretch;
  display: flex;
  margin-top: 48px;
`;

export const LeftPane = styled.aside`
  background-color: ${(props: any) => props.theme.neutral};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: 0 0 344px;
  padding: 16px;
  overflow-y: scroll;
  .pane-title {
  }
`;

export const TopBarTitle = styled.h1`
  margin-bottom: 16px;
  ${(props: any) => props.theme.heading1.styles};
`;

export const MapContainer = styled.main`
  flex: 1 1 auto;
`;

export const PaneTitle = styled.h2`
  color: ${(props: any) => props.theme.neutral80};
  ${(props: any) => props.theme.heading4.styles};
  font-weight: 500;
  line-height: 25px;
  margin: 16px 0;
`;

export const CaptureList = styled.div`
  width: 100%;
  border-top: 1px solid ${(props: any) => props.theme.neutral20};
  padding: 40px 0 24px 0;
  position: relative;
  .capture {
    margin-bottom: 24px;
  }
`;

export const FiltersHeader = styled.div`
  width: 100%;
  .search {
    margin: 16px 0;
  }
  .active-filters.show {
    margin-bottom: 12px;
  }
`;

export const FiltersWrapper = styled.div`
  max-height: 0;
  width: 100%;
  background-color: ${(props: any) => props.theme.neutral};
  margin: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &.show {
    max-height: 400px;
    border-top: 1px solid ${(props: any) => props.theme.neutral20};
    margin-bottom: 24px;
    padding-top: 24px;
    opacity: 1;
    overflow: visible;
  }
  .dropdownFilter,
  .dateFilter,
  .idFilter {
    margin-bottom: 24px;
  }
  .dateFilter {
    z-index: 9999;
  }
`;

export const FilterButton = styled.div`
  align-items: center;
  color: ${(props: any) => props.theme.secondary10};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
  ${(props: any) => props.theme.small.styles};
  .action--icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    &.close--icon {
      width: 9px;
      height: 9px;
      fill: ${(props: any) => props.theme.secondary10};
    }
    path {
      stroke: ${(props: any) => props.theme.secondary10};
      transition: stroke 0.2s ease-in, fill 0.2s ease-in;
    }
  }
  &:hover {
    color: ${(props: any) => props.theme.secondary40};
    .action--icon {
      path {
        stroke: ${(props: any) => props.theme.secondary40};
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  svg {
    stroke: ${(props: any) => props.theme.primary};
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const NoResults = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  h1 {
    color: ${(props: any) => props.theme.neutral60};
    font-size: ${(props: any) => props.theme.sizes.large};
    font-weight: 500;
    line-height: 24px;
    margin: 24px 0;
  }
  p {
    color: ${(props: any) => props.theme.neutral60};
    line-height: 20px;
    margin-bottom: 24px;
    text-align: center;
  }
`;
export const CapturesTotal = styled.small`
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  position: absolute;
  top: 12px;
  left: 0;
`;

export const NoTaskingFilter = styled.section`
  width: 100%;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 16px;
  .dateFilter {
    margin: 16px 0;
  }
  .filter-button {
    margin-left: auto;
  }
`
