import styled from '@emotion/styled/macro'

export const ActiveFiltersList = styled.ul`
  display: flex;
  opacity: 0;
  transition: all 0.3s linear;
  &.show {
    opacity: 1;
  }
`

export const ActiveFilterContainer = styled.li`
  height: 18px;
  align-items: center;
  background-color: ${(props: any) => props.theme.neutral10};
  border-radius: 25px;
  color: ${(props: any) => props.theme.neutral80};
  display: flex;
  line-height: 20px;
  margin-right: 8px;
  padding: 12px;
  ${(props: any) => props.theme.caption.styles};
  .clear-icon {
    width: 9px;
    cursor: pointer;
    fill: ${(props: any) => props.theme.neutral40};
    margin-right: 5px;
  }
`
