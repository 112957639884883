import { useContext, useEffect } from 'react';
import { MapContext } from '../mapContext';
import { LayerItem } from 'components/layers/layers.store';

interface Props {
  layer: LayerItem;
}

export const TileLayer = ({layer}: Props) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if(layer.getOLItem()) map?.addLayer(layer.getOLItem() as any);
    return () => {
      if(layer.getOLItem()) map?.removeLayer(layer.getOLItem() as any);
    }

  }, [map, layer]);

  useEffect(() => {
    // 
  }, [layer.visible])

  return null;
}