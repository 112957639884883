import React from 'react';
import { MapContext } from '../mapContext';

import * as ol from 'ol';
import { GeoJSON } from 'ol/format';
import { Geometry, Point } from 'ol/geom';
import { transformExtent } from 'ol/proj';
import { getCenter } from 'ol/extent';

interface PropsFromLayer {
  layer: any;
}

export const MarkerFromLayer = ({ layer }: PropsFromLayer) => {
  const { map, vectorLayer } = React.useContext(MapContext);

  React.useEffect(() => {
    if (!map) return;
    let ext = transformExtent(
      (new GeoJSON().readFeature(layer.metadata.footprint).getGeometry() as Geometry).getExtent(),
      'EPSG:4326',
      'EPSG:3857'
    );
    layer.getOLItem()?.setExtent(ext);

    var iconFeature = new ol.Feature({
      geometry: new Point(getCenter(ext)),
      name: layer.name
    });
    iconFeature.setId(layer.layerId)
    vectorLayer?.getSource().addFeature(iconFeature);

    return () => {
      try {
        if (map) vectorLayer?.getSource().removeFeature(iconFeature);
      } catch (error) {}
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};

interface Props {
  coordinates: number[];
  id?: string;
  projectName?: string;
}

export const Marker = ({ coordinates, id, projectName }: Props) => {
  const { map, vectorLayer } = React.useContext(MapContext);

  React.useEffect(() => {
    if (!map || !coordinates) return;

    var iconFeature = new ol.Feature({ geometry: new Point(coordinates) });

    iconFeature.setId(id);
    iconFeature.set('project_name', projectName);

    vectorLayer?.getSource().addFeature(iconFeature);

    return () => {
      try {
        if (map) vectorLayer?.getSource().removeFeature(iconFeature);
      } catch (error) {}
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, coordinates]);

  return null;
};
