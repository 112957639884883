import styled from '@emotion/styled/macro';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 40px;
  background: linear-gradient(90deg, rgba(29,181,221,1) 0%, rgba(1,34,62,1) 100%);
  display: flex;
  justify-content: space-between;
  z-index: 9991;
  position: fixed;
  left: 0;
  top: 0;
`;

export const Logo = styled.svg`
  height: 30px;
  display: flex;
  padding-top: 3px;
  padding-left: 20px;
`;

export const User = styled.svg`
  height: 30px;
  fill: white;
  padding-top: 9px;
  padding-right: 10px;
`;

export const Dots = styled.svg`
  height: 27px;
  fill: white;
  padding-top: 11px;
  padding-right: 20px;
`;

export const MenuContainer = styled.div`
  width: 77px;
  height: 100vh;
  border-right: 5px solid transparent;
  background-color: #011D35;
  z-index: 999;
  position: fixed;
  left: 0;
`;

export const IconGroup = styled.div`
  width: 77px;
  margin: 0;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
`;

export const FullIcon = styled.div`
  width: 78px;
  height: 50px;
  border-right: 4px solid transparent;
  display: flex;
  flex-direction: column;
  margin: 40px 0 40px 0;
  padding-bottom: 5px;
  text-align: center;
`;

export const World = styled.svg`
  width: 25px;
  height: 25px;
  display: block;
  fill: #6E7F8D;
  margin: auto;
  margin-bottom: 5px;
`;

export const Folder = styled.svg`
  width: 25px;
  height: 25px;
  display: block;
  fill: white;
  margin: auto;
  margin-bottom: 5px;
`;

export const Plus = styled.svg`
  width: 35px;
  height: 30px;
  display: block;
  fill: white;
  margin: auto;
  margin-bottom: 5px;
`;

export const Wording = styled.h1`
  color: #6E7F8D;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-weight: 50;
  margin: 0;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 95%;
  margin: auto;
  &.relative{
    position: relative;
  }
`;