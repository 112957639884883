import React from 'react';
import { createContext } from 'mobx-keystone';
import { DashboardStore, RootStore, UserStore, TaskStore, NotificationStore } from '../stores';
import { MapStore } from 'components/oldMap/map.store';
import { IdentifyStore } from 'components/oldMap/identify.store';
import { SearchModalStore } from 'components/searchmodal/searchmodal.store';

export const mobxRootContext = createContext<RootStore>();

export const rootContext = React.createContext({
  rootStore: new RootStore({
    dashboardStore: new DashboardStore({ products: undefined, filtering: undefined, layers: undefined }),
    mapStore: new MapStore({
      identifyStore: new IdentifyStore({ currentFeatureData: undefined, features: undefined })
    }),
    searchModalStore: new SearchModalStore({}),
    userStore: new UserStore({ geofence: undefined }),
    taskStore: new TaskStore({ products: [], filters: undefined, tasks: undefined, projects: undefined, activeTask: ''}),
    notificationStore: new NotificationStore({})
  })
});
