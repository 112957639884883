import React, { useEffect } from 'react'

import { PlaceSearchInput } from 'components/placesearchinput'

import {
  ModalOverlay, ModalWindow, ModalBase
} from './placesearchmodal.styles'

export interface PlaceSearchModalProps {
  className?: string
  isShown: boolean
  hide: () => void
  selectPlace: (latLong: Array<number>) => void
}

export const PlaceSearchModal = ({ className, isShown, hide, selectPlace }: PlaceSearchModalProps) => {

  const placeSearchModal = (
    <ModalBase>
      <ModalOverlay onClick={hide} />
      <ModalWindow className={className} role="dialog">
        <PlaceSearchInput
          name="placeSearch"
          onOptionSelect={(latLong) => {
            selectPlace(latLong)
            hide()
          }}
        />
      </ModalWindow>
    </ModalBase>
  )

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27 && isShown) {
      hide()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    }
  })

  return isShown ? placeSearchModal : null;
}

PlaceSearchModal.displayName = 'PlaceSearchModal'
