import React, { useState } from 'react'
import { classes } from 'config/common'

import {
  MenuContainer,
  ToggleElement,
  MenuComponent,
} from './tooltip.styles';

interface MenuProps {
  className?: string;
  menuClassName?: string;
  toggleElement: JSX.Element
  position: string
  content: string | JSX.Element
}

export const Tooltip = ({ className, toggleElement, position, menuClassName, content }: MenuProps) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <MenuContainer className={classes(className)}>
      <ToggleElement
        onMouseEnter={() => {
          setShowMenu(true)
        }}
        onMouseLeave={() => {
          setShowMenu(false)
        }}
      >
        {toggleElement}
      </ToggleElement>

      <MenuComponent className={classes(position, menuClassName, { showMenu })}>
        {content}
      </MenuComponent>
    </MenuContainer>
  )
};

Tooltip.displayName = "Tooltip";
