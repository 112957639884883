import React, { useState, ChangeEvent } from 'react';

import {
  CheckboxContainer,
  Icon,
  HiddenCheckbox,
  StyledCheckbox,
  Label,
  LabelText
} from './checkbox.styles';

interface CheckboxProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  name?: string;
  value?: string;
  onChange?: (isChecked: any) => void;
  updateCheckboxState?: () => void;
  updateCheckedList?: (value: string) => void;
}

export const Checkbox = ({
  checked,
  className,
  disabled = false,
  label,
  name,
  value,
  onChange,
  updateCheckboxState,
  updateCheckedList
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  React.useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const checkedHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!!updateCheckboxState) updateCheckboxState();
    if (!!updateCheckedList) updateCheckedList(e.currentTarget.value);
    if (!!onChange) onChange(e.target.checked);
    setIsChecked(!isChecked);
  };

  const checkbox = (
    <Label className={className} disabled={disabled}>
      <CheckboxContainer disabled={disabled}>
        <HiddenCheckbox
          checked={isChecked}
          disabled={disabled}
          name={name}
          type="checkbox"
          value={value}
          onChange={checkedHandler}
        />
        <StyledCheckbox checked={isChecked} disabled={disabled}>
          <Icon width="24px" viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      <LabelText disabled={disabled}>{label}</LabelText>
    </Label>
  );

  return checkbox;
};

Checkbox.displayName = "Checkbox";