import React, { FC } from 'react';

import {
  InputWrapper,
  InputStyled,
  ErrorText,
  Label,
  HelperText,
  BottomText
} from './input.styles';

import { classes, isTextValid } from 'config/common';

interface Props {
  className?: string;
  error?: { flag?: boolean; text?: string; validator?: any };
  label?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value?: string;
  testid?: string;
  color?: 'black';
  disabled?: boolean;
  icon?: any;
  helperText?: string;
  min?: string;
  max?: string;
}

export const Input: FC<Props> = ({
  value = '',
  className,
  error = {},
  onBlur,
  type = 'text',
  name,
  onChange,
  testid,
  color,
  label,
  disabled = false,
  icon,
  helperText,
  min,
  max
}) => {
  const [currentValue, setValue] = React.useState(value);
  const [showError, setError] = React.useState(error.flag ? error.flag : false);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (error.text) {
        if (typeof error.validator === 'function') {
          setError(error.validator(e));
        } else {
          setError(!isTextValid(e.currentTarget.value, type));
        }
      }
      if (onChange) onChange(e);
      setValue(e.currentTarget.value);
    },
    // eslint-disable-next-line
    [onChange]
  );

  React.useEffect(() => {
    setValue(value);
  }, [value]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e);

    if (error.text) {
      if (typeof error.validator === 'function') {
        setError(error.validator(e));
      } else {
        setError(!isTextValid(e.currentTarget.value, type));
      }
    }
  };

  return (
    <InputWrapper className={classes(className)}>
      <InputStyled
        name={name}
        type={type}
        value={currentValue}
        autoComplete="off"
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes(color, { error: showError, active: !!currentValue }, {'with--icon' : !!icon} )}
        data-testid={testid}
        disabled={disabled}
        required={!!error.text}
        min={min}
        max={max}
      />
      {label && (
        <Label>
          {disabled && <div className="disabled-background" />} {label}
        </Label>
      )}
      {icon}
      <BottomText className={classes({ shown: showError || !!helperText })}>
        {showError && <ErrorText>{error.text}</ErrorText>}
        {!!helperText && <HelperText>{helperText}</HelperText>}
      </BottomText>
    </InputWrapper>
  );
};

Input.displayName = 'Input';
