import React from 'react';
import { Toast } from './toast';

import { List } from './toast.styles';

interface Props {
  notifications: any[];
}

export const ToastList = ({notifications}: Props) => {

  return (
    <List>
      {notifications?.map((noti: any, k: number) => 
        <Toast
          description={noti.description}
          actionText= {noti.actionText}
          url={noti.url}
          onClick={noti.onClick}
          key={k}
        />
      )}
    </List>
  )
}