import styled from '@emotion/styled/macro';

export const Modal = styled.div`
  width: 445px;
  background-color: ${(props: any) => props.theme.neutral};
  border-radius: 5px;
  margin: auto;
  outline: none;
  position: relative;
`;

export const SpanBold = styled.span`
  font-weight: 600;
  margin-right: 10px;
`;

export const TaskInfo = styled.div`
  background-color: ${(props: any) => props.theme.primary};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
`;

export const TaskTitle = styled.h2`
  color: ${(props: any) => props.theme.neutral};
  ${(props: any) => props.theme.subtitle.styles};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TaskCoordinates = styled.div`
  color: ${(props: any) => props.theme.neutral05};
  ${(props: any) => props.theme.small.styles};
`;

export const TaskContent = styled.div`
`;

export const TaskRow = styled.div`
  border-bottom: 1px solid ${(props: any) => props.theme.neutral10};
  display: flex;
  ${(props: any) => props.theme.small.styles};
  /* flex-direction: column; */
  margin: 0 15px;
  padding: 15px 0;
  &:last-of-type, &.no-border{
    border-bottom: 0;
  }
  &.space-between{
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const RowTitle = styled.h4`
  color: ${(props: any) => props.theme.neutral80};
  ${(props: any) => props.theme.subtitle.styles};
`;