import styled from '@emotion/styled/macro';

export const StatusLabelStyled = styled.span`
  align-items: center;
  border-radius: 25px;
  color: ${(props: any) => props.theme.neutral80};
  display: flex;
  line-height: 20px;
  padding: 3px 17px;
  text-align: center;
  position: relative;
  ${(props: any) => props.theme.caption.styles};
  &.pending,
  &.canceled {
    background-color: ${(props: any) => props.theme.neutral10};
    color: ${(props: any) => props.theme.neutral60};
  }
  &.in_progress,
  &.received,
  &.planning {
    background-color: ${(props: any) => props.theme.feedbackInformation};
    color: ${(props: any) => props.theme.feedbackInformationDark};
  }
  &.failed,
  &.rejected {
    background-color: ${(props: any) => props.theme.feedbackError};
    color: ${(props: any) => props.theme.feedbackErrorDark};
  }
  &.delivered,
  &.completed,
  &.partially_completed {
    background-color: ${(props: any) => props.theme.feedbackSuccess};
    color: ${(props: any) => props.theme.feedbackSuccessDark};
  }
  &.small {
    height: 18px;
    font-weight: 500;
    padding: 0 8px;
  }
  & > span {
    display: block;
  }
  &:hover {
    > span {
      opacity: 1;
      transform: translate(-50%, -95%);
    }
  }
`;

export const TooltipDetails = styled.span`
  width: 17.313rem;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral05};
  ${(props: any) => props.theme.caption.styles};
  line-height: 1.25rem;
  padding: 0.625rem 0.938rem;
  text-align: left;
  transform: translate(-50%, -110%);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 50%;
  &:after {
    width: 0px;
    border: 9px solid transparent;
    border-top: 9px solid rgba(0, 0, 0, 0.8);
    border-bottom: 0;
    content: '';
    display: block;
    transform: translate(-50%, 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
  }
`;
