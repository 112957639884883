import React from 'react'
import { BigToast } from './bigtoast'

import { List } from './bigtoast.styles'

interface Props {
  notifications: any[]
}

export const BigToastList = ({notifications}: Props) => {

  return (
    <List>
      {notifications?.map((noti: any, k: number) =>
        <BigToast
          description={noti.description}
          actionText= {noti.actionText}
          url={noti.url}
          onClick={noti.onClick}
          key={k}
        />
      )}
    </List>
  )
}
