import React, { useState, useRef, useEffect } from 'react';

import * as ol from 'ol';
import { ScaleLine, Zoom } from 'ol/control';
import { fromLonLat, transformExtent } from 'ol/proj';
import { Draw } from 'ol/interaction';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { Geometry } from 'ol/geom';
import { Style, Icon, Stroke, Fill, Circle } from 'ol/style';
import { unByKey } from 'ol/Observable.js'
import { getArea } from 'ol/sphere.js'

import { MapContext } from './mapContext';
import { baseMaps, vectorLayer, drawVectorLayer, handleTooltipPosition } from './helpers';
import { MarkerFromLayer } from './marker';
import { VectorFeature } from './vectorFeature';
import { SearchControl } from './controls/search';
import { TileLayer } from './tileLayer';

import { LayerItem } from 'components/layers/layers.store';

import { MapWrapper, Tooltip } from './map.styles';
import { PlaceSearchModal } from 'components/modal/placesearchmodal';

import { classes } from 'config/common';
import { transformAreaToKms } from 'config/helpers'

interface Props {
  center?: { lon: number; lat: number };
  children?: React.ReactNode;
  geofence?: any;
  highlightedMarker?: string;
  layers?: Array<LayerItem>;
  showDraw?: { display: boolean; erase: boolean };
  showSearch?: boolean;
  view?: string;
  zoom?: number;
  extent?: any;
  onClick?: (event?: any, id?: string) => void;
  onDraw?: (feature: Feature) => void;
  onSearch?: (latLng: number[]) => void;
  onPointerMove?: (id: string | undefined) => void;
}

export const Map = ({
  center,
  children,
  highlightedMarker,
  geofence,
  layers,
  showDraw = { display: false, erase: false },
  showSearch = false,
  view,
  zoom = 6,
  extent,
  onClick,
  onDraw,
  onSearch,
  onPointerMove
}: Props) => {
  const [map, setMap] = useState<ol.Map>();
  const [showPlaceSearchModal, setShowPlaceSearchModal] = React.useState(false);
  const [showPointerCursor, setShowPointerCursor] = React.useState(false);
  const [highlightedMarkerId, setHighlightedMarkerId] = React.useState('')

  const mapElement = useRef<HTMLDivElement>(null);

  const mapRef = useRef<ol.Map>();
  mapRef.current = map;

  // Map Init
  useEffect(() => {
    if (map) return;

    const initialMap = new ol.Map({
      layers: [
        ...Object.entries(baseMaps).map(item => item[1] as any),
        vectorLayer,
        drawVectorLayer
      ],
      controls: [
        new Zoom({ zoomInLabel: '', zoomOutLabel: '' }),
        new ScaleLine({ units: 'metric' })
      ],
      view: new ol.View({
        center: center ? fromLonLat([center.lon, center.lat]) : [0, 0],
        zoom: geofence?.coordinates ? zoom : 2,
        maxZoom: 19
      }),
      target: mapElement?.current || undefined
    });

    if (onClick) initialMap.on('click', handleMapClick);
    initialMap.on('pointermove', handleMapPointerMove);

    setMap(initialMap);

    return () => {
      removeDrawPolygon();
      initialMap.setTarget(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Click Handler
  const handleMapClick = (e: any) => {
    switch (view) {
      case 'tasking':
        if (onClick) onClick(e);
        break;
      case 'captures':
        if (onClick) {
          const features = mapRef.current?.getFeaturesAtPixel(e.pixel);
          if (features && features.length > 0) onClick(e, features[0].getId()?.toString());
        }
        break;
    }
  };

  // Hover Handler
  const handleMapPointerMove = (e: any) => {
    const featureExists = mapRef.current?.hasFeatureAtPixel(e.pixel);
    const features = mapRef.current?.getFeaturesAtPixel(e.pixel);
    if (featureExists && features) {
      setShowPointerCursor(true);
      if (onPointerMove) onPointerMove(features[0].getId()?.toString());
    } else {
      setShowPointerCursor(false);
      if (onPointerMove) onPointerMove(undefined);
    }
  };

  // Extent handler
  useEffect(() => {
    if (!map) return;
    if (!extent) return
    let ext = transformExtent(
      (new GeoJSON()
        .readFeature(extent)
        .getGeometry() as Geometry).getExtent(),
      'EPSG:4326',
      'EPSG:3857'
    );
    map?.getView().fit(ext);
  }, [extent, map])

  // Layers handler
  useEffect(() => {
    if (!map) return;
    layers?.forEach(item => {
      const already = map
        ?.getLayers()
        .getArray()
        .some(layer => item.getOLItem()?.get('id') === layer.get('id'));
      if (!already && item.getOLItem()) map?.getLayers().extend([item.getOLItem() as any]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers]);

  // Zoom handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom]);

  // Center handler
  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center ? fromLonLat([center.lon, center.lat]) : [0, 0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  // Highlight Marker
  useEffect(() => {
    // Clean Previous Highlighted Marker
    if (map && highlightedMarkerId !== '' && highlightedMarker !== highlightedMarkerId) {
      const feature = vectorLayer?.getSource()?.getFeatureById(highlightedMarkerId);
      setHighlightedMarkerId('')
      let highlightedIconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 1],
          scale: 1,
          src: '/pin.svg'
        }),
        zIndex: 1
      });
      feature?.setStyle(highlightedIconStyle);
    }
    // Change Highlighted Marker
    if (map && highlightedMarker) {
      const feature = vectorLayer?.getSource()?.getFeatureById(highlightedMarker);
      setHighlightedMarkerId(highlightedMarker)
      let highlightedIconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 1],
          scale: 1.2,
          src: '/highlightedPin.svg'
        }),
        zIndex: 1000
      });
      feature?.setStyle(highlightedIconStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedMarker]);

  // Search Control Handler
  useEffect(() => {
    if (map && onSearch && showSearch) {
      const searchControl = new SearchControl(() => setShowPlaceSearchModal(true));
      map.addControl(searchControl);
    } else {
      const searchControl = map
        ?.getControls()
        .getArray()
        .find(item => item instanceof SearchControl);
      if (searchControl) map?.removeControl(searchControl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearch]);

  const handleSearch = (coordinates: number[]) => {
    if (onSearch) onSearch(coordinates);
  };

  const [tooltipText, setTooltipText] = useState('Click to start drawing')
  const [drawArea, setDrawArea] = useState(0)

  useEffect(() => {
    if (drawArea > 0 && drawArea < 500) {
      setTooltipText('Click to continue drawing the polygon')
    } else if (drawArea > 500) {
      setTooltipText('AOI is too big. Limit is 500 square kilometers.')
    }
  }, [drawArea])

  // Get Draw Interaction if it exists
  const getDrawInteraction = () =>
    map
      ?.getInteractions()
      .getArray()
      .find((interaction: any) => interaction instanceof Draw);

  // Toggle Draw
  const toggleDraw = () => {
    if (showDraw.erase) removeDrawPolygon();

    const drawInteraction = getDrawInteraction();

    if (drawInteraction) {
      drawInteraction.setActive(showDraw.display);
    } else if (showDraw.display) {
      let draw = new Draw({
        source: drawVectorLayer.getSource() || undefined,
        type: 'Polygon',
        geometryName: 'drawnPolygon',
        style: [
          new Style({
            stroke: new Stroke({
              color: '#00B6DA',
              lineDash: [10, 10],
              width: 1
            }),
            fill: new Fill({
              color: 'rgba(89, 200, 230, 0.25)'
            })
          }),
          new Style({
            image: new Circle({
              radius: 5,
              stroke: new Stroke({
                color: '#686868',
                width: 2
              }),
              fill: new Fill({
                color: 'white'
              })
            })
          })
        ]
      })

      let listener: any
      let sketch
      draw.on('drawstart', e => {
        sketch = e.feature
        listener = sketch.getGeometry()?.on('change', (evt) => {
          const area = getArea(evt.target)
          setDrawArea(transformAreaToKms(area))
        })
        setTooltipText('Click to continue drawing the polygon')
      });
      draw.on('drawend', e => {
        setTooltipText('Click to start drawing');
        map?.removeInteraction(draw);
        if (onDraw) onDraw(e.feature);
        unByKey(listener)
        setDrawArea(0)
      });
      map?.addInteraction(draw);
    }
  };

  // Remove Drawn Polygon
  const removeDrawPolygon = () => {
    const polygon = drawVectorLayer.getSource()?.forEachFeature(feature => {
      if (feature.getGeometryName() === 'drawnPolygon') return feature;
    });
    if (polygon) drawVectorLayer.getSource()?.removeFeature(polygon);
  };

  // Draw Handler
  useEffect(() => {
    if (showDraw.erase) removeDrawPolygon();
    if (map) toggleDraw();

    const mapWrapper = document.querySelector('.map-wrapper');
    if (showDraw.display) {
      mapWrapper?.addEventListener('mousemove', handleTooltipPosition);
    } else {
      mapWrapper?.removeEventListener('mousemove', handleTooltipPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDraw]);

  return (
    <MapContext.Provider value={{ map, vectorLayer, drawVectorLayer }}>
      <MapWrapper ref={mapElement} className={classes('map-wrapper', { draw: showDraw.display, pointer: showPointerCursor })}>
        {showDraw.display ? (
          <Tooltip className={classes('map-tooltip', { shown: showDraw.display, error: drawArea > 500 })}>
            {drawArea} km<sup>2</sup> | {tooltipText}
          </Tooltip>
        ) : null }
        {layers?.map((item, k) => {
          return (
            <React.Fragment key={k}>
              <MarkerFromLayer layer={item} key={item.layerId} />
              <TileLayer layer={item} key={k} />
            </React.Fragment>
          )
        })}
        {view === 'tasking' && geofence.coordinates && (
          <VectorFeature geojson={geofence} zoom id="geofence" geofence />
        )}
      </MapWrapper>
      <PlaceSearchModal
        isShown={showPlaceSearchModal}
        hide={() => setShowPlaceSearchModal(false)}
        selectPlace={handleSearch}
      />
      {children}
    </MapContext.Provider>
  );
};

Map.displayName = 'Map';
