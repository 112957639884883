import { InputDateRange } from 'components/inputdaterange';
import archiveStore from 'stores/archiveStore';
import { Map as MaplibreMap, IControl } from 'maplibre-gl';
import { Input } from 'components/input';
import { Dropdown } from 'components/dropdown';
import { DropdownOptions } from 'typings';
import { observer } from 'mobx-react-lite';
import {
  BboxMenu,
  BboxWrapper,
  ButtonWrapper,
  ButtonsModeWrapper,
  FiltersWrapper,
  ModeButton,
  Title
} from 'views/archive/archive.styles';
import { Button } from 'components/button';
import { useStores } from 'config/hooks';
import { useCallback, useEffect, useState } from 'react';
import { getCollections, searchCollection } from 'services/archiveService';
import { FiltersIcon } from 'components/icons';
import { FiltersContainer, NumericInputFilter, TitleWithIcon } from './searchTab.styles';
import { MapIcon } from 'components/icons/icons';
import { autorun, toJS } from 'mobx';
import loadingNotificationStore from 'stores/loadingNotificationStore';
import { formatToEndOfDayUTC, formatToStartOfDayUTC } from 'views/archive/helpers';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

const dropdownFiltersQueryables: DropdownOptions[] = [
  {
    value: '=',
    text: 'Equals'
  },
  {
    value: '>',
    text: 'Greater than'
  },
  {
    value: '<',
    text: 'Lower than'
  }
];

export const SearchTab = observer(() => {
  const {
    rootStore: { userStore }
  } = useStores();
  const [disableButtonSearch, setDisableButtonSearch] = useState<boolean>(true);
  const [showBboxInMenu, setShowBboxInMenu] = useState(false);
  const [showActiveDrawButton, setShowActiveDrawButton] = useState(false);

  const getTokenFromStore = useCallback(async () => {
    const token = await userStore.auth0Client?.getTokenSilently();
    return token;
  }, [userStore]);

  useEffect(() => {
    const fetchCollectionsSTAC = async () => {
      const token = await getTokenFromStore();
      getCollections(
        token,
        response => {
          archiveStore.setCollectionsData(response.data.collections);
        },
        e => console.error(e)
      );
    };
    if (archiveStore.collectionsData.length === 0) fetchCollectionsSTAC();
  }, [getTokenFromStore]);

  const handleDropdownChange = (elem: DropdownOptions[]) => {
    const valuesArray = elem.map(item => item.value);
    archiveStore.setCollectionsSearch(valuesArray);
    elem.map(item =>
      archiveStore.setDropdownCollectionSelected({
        value: item.value,
        text: item.text
      })
    );
  };

  const handleDrawPolygon = () => {
    if (!!archiveStore.limitsSearchPolygon) archiveStore.setLimitsSearchPolygon(null);
    if (showBboxInMenu) setShowBboxInMenu(false);
    setShowActiveDrawButton(true);
    if (archiveStore.maplibreMap) {
      const map = archiveStore.maplibreMap as MaplibreMap;
      if (archiveStore.drawRef) {
        archiveStore.removeDrawControl(map);
        archiveStore.setDrawPolygon(null);
      }

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        }
      });

      const originalOnAdd = draw.onAdd.bind(draw);
      draw.onAdd = map => {
        const controlContainer = originalOnAdd(map);
        controlContainer.classList.add('maplibregl-ctrl', 'maplibregl-ctrl-group');
        return controlContainer;
      };

      map.addControl(draw as unknown as IControl);
      archiveStore.setDrawRef(draw);
      archiveStore.changeDrawMode('draw_polygon');
    }
  };

  const handleExitDrawMode = () => {
    if (archiveStore.maplibreMap && archiveStore.drawRef) {
      const map = archiveStore.maplibreMap as MaplibreMap;
      archiveStore.removeDrawControl(map);
      archiveStore.setLimitsSearchPolygon({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: toJS(archiveStore.drawPolygon)
        }
      });
    }
  };

  useEffect(() => {
    if (
      (!!archiveStore.dateStart &&
        !!archiveStore.dateEnd &&
        archiveStore.collectionsData.length > 0 &&
        !!archiveStore.limitSearchAmount &&
        archiveStore.collectionsSearch.length > 0 &&
        showBboxInMenu === false &&
        !!archiveStore.drawPolygon) ||
      (showBboxInMenu && !!archiveStore.bboxSearch)
    ) {
      setDisableButtonSearch(false);
    } else {
      setDisableButtonSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    archiveStore.collectionsData,
    showBboxInMenu,
    archiveStore.dateStart,
    archiveStore.dateEnd,
    archiveStore.limitSearchAmount,
    archiveStore.collectionsSearch,
    archiveStore.drawPolygon,
    archiveStore.bboxSearch
  ]);

  useEffect(() => {
    const dispose = autorun(() => {
      archiveStore.setBBoxSearch([
        archiveStore.bboxInputLeft,
        archiveStore.bboxInputBottom,
        archiveStore.bboxInputRight,
        archiveStore.bboxInputTop
      ]);
    });

    return () => dispose();
  }, []);

  const handleSearchClick = () => {
    if (!!archiveStore.activeThumbnail) {
      archiveStore.setActiveThumbnail(null);
      archiveStore.setActiveTilesData(null);
    }
    setDisableButtonSearch(true);
    loadingNotificationStore.setText('Loading results, almost there...');
    loadingNotificationStore.setLoading(true);
    loadingNotificationStore.setNoResults(false);

    const searchCollectionParams = {
      startDate: formatToStartOfDayUTC(archiveStore.dateStart),
      endDate: formatToEndOfDayUTC(archiveStore.dateEnd),
      collections: ['quickview-visual-thumb'],
      limitSearchAmount: '10',
      bbox: archiveStore.bboxSearch,
      polygon: archiveStore.drawPolygon,
      filter:
        archiveStore.filtersQueriables.filter.args.length > 0
          ? archiveStore.filtersQueriables
          : undefined,
      onSuccess: (response: any) => {
        if (response.data.features.length === 0) {
          loadingNotificationStore.setLoading(false);
          loadingNotificationStore.setNoResults(true);
        } else {
          archiveStore.setSearchResultsThumbnail(response.data);
          archiveStore.setShowMap(true);
          loadingNotificationStore.setLoading(false);
        }
      },
      onError: (e: any) => {
        loadingNotificationStore.setLoading(false);
        loadingNotificationStore.setErrorMessage(
          `There was an error in your search${e.data.detail ? ': ' + e.data.detail : ''}`
        );
        console.error(e);
      }
    };

    const fetchCollectionData = async () => {
      const token = await getTokenFromStore();

      if (!!archiveStore.drawPolygon) {
        searchCollection(token, 'polygon', searchCollectionParams);
      } else {
        searchCollection(token, 'bbox', searchCollectionParams);
      }
    };
    
    fetchCollectionData();
    handleExitDrawMode();
    setDisableButtonSearch(false);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const parsedValue =
      e.target.type === 'number' ? (value === '' ? undefined : parseFloat(value)) : value;

    archiveStore.setDropdownFilterValues(name, parsedValue);
    const operator = archiveStore.inputOperators[name]?.value || '=';
    archiveStore.updateArgs(name, parsedValue, operator);
  };

  const handleDropdownFilterChange = (elem: DropdownOptions[] | any, name: string) => {
    archiveStore.setInputOperators(name, elem[0]);
    archiveStore.updateArgs(name, archiveStore.dropdownFilterValues[name], elem[0].value);
  };

  return (
    <FiltersWrapper>
      <InputDateRange
        name="filter-date"
        startLabel="Date from"
        endLabel="Date to"
        maxStartDate={new Date()}
        maxEndDate={new Date()}
        monthsShown={1}
        startPlacement="bottom-start"
        endPlacement="bottom-end"
        onChangeStart={date => {
          const startDate = new Date(date);
          startDate.setHours(0, 0, 0, 0); // Set time to midnight
          archiveStore.setDateStart(startDate);
        }}
        onChangeEnd={date => {
          const endDate = new Date(date);
          endDate.setHours(23, 59, 59, 999); // Set time to end of the day
          archiveStore.setDateEnd(endDate);
        }}
        startValue={archiveStore.dateStart}
        endValue={archiveStore.dateEnd}
      />

      {/* <Input
        type="number"
        name="idLimit"
        label="Max amount of values returned"
        value={limitSearchAmount}
        onChange={e => setLimitSearchAmount(e.target.value)}
        disabled
      /> */}
      {archiveStore.collectionsData.length > 0 ? (
        <Dropdown
          label="Select the collection"
          options={archiveStore.collectionsData.map(elem => {
            return {
              value: elem.id,
              text: elem.description
            };
          })}
          onChange={elem => handleDropdownChange(elem)}
          defaultOptions={
            !!archiveStore.dropdownCollectionSelected
              ? [archiveStore.dropdownCollectionSelected]
              : undefined
          }
        />
      ) : null}
      <TitleWithIcon>
        <FiltersIcon className="action--icon" />
        <p>Filters</p>
      </TitleWithIcon>
      <FiltersContainer>
        <div className="inner">
          <Input
            name="satl:outcome_id"
            label="Outcome ID"
            onChange={handleInputChange}
            type="text"
            value={archiveStore.dropdownFilterValues['satl:outcome_id'] as string}
          />
          <Input
            name="platform"
            label="Platform"
            onChange={handleInputChange}
            type="text"
            value={archiveStore.dropdownFilterValues['platform'] as string}
          />
          <Input
            name="id"
            label="Item ID"
            onChange={handleInputChange}
            type="text"
            value={archiveStore.dropdownFilterValues['id'] as string}
          />

          <NumericInputFilter>
            <Dropdown
              options={dropdownFiltersQueryables}
              label="Operator"
              onChange={e => handleDropdownFilterChange(e, 'view:off_nadir')}
              className="dropdown"
              defaultOptions={[archiveStore.inputOperators['view:off_nadir']]}
            />
            <Input
              name="view:off_nadir"
              label="ONA"
              onChange={handleInputChange}
              type="number"
              value={archiveStore.dropdownFilterValues['view:off_nadir'] as string}
            />
          </NumericInputFilter>
          <NumericInputFilter>
            <Dropdown
              options={dropdownFiltersQueryables}
              onChange={e => handleDropdownFilterChange(e, 'view:sun_elevation')}
              label="Operator"
              defaultOptions={[archiveStore.inputOperators['view:sun_elevation']]}
            />
            <Input
              name="view:sun_elevation"
              label="Sun Elevation"
              onChange={handleInputChange}
              type="number"
              className="input"
              value={archiveStore.dropdownFilterValues['view:sun_elevation'] as string}
            />
          </NumericInputFilter>
          <NumericInputFilter>
            <Dropdown
              options={dropdownFiltersQueryables}
              label="Operator"
              onChange={e => handleDropdownFilterChange(e, 'eo:cloud_cover')}
              defaultOptions={[archiveStore.inputOperators['eo:cloud_cover']]}
            />
            <Input
              name="eo:cloud_cover"
              label="Cloud Cover"
              onChange={handleInputChange}
              type="number"
              value={archiveStore.dropdownFilterValues['eo:cloud_cover'] as string}
            />
          </NumericInputFilter>
        </div>
      </FiltersContainer>
      <TitleWithIcon>
        <MapIcon className="action--icon" />
        <h3>Select a location area</h3>
      </TitleWithIcon>
      <ButtonsModeWrapper>
        <ModeButton active={showActiveDrawButton}>
          <Button onClick={handleDrawPolygon} text="Draw Polygon" small secondary />
        </ModeButton>
        <ModeButton active={showBboxInMenu}>
          <Button
            text="Bbox coodinates"
            small
            onClick={() => {
              setShowBboxInMenu(!showBboxInMenu);
              setShowActiveDrawButton(false);
              archiveStore.setDrawPolygon(null);
              archiveStore.setLimitsSearchPolygon(null);
              handleExitDrawMode();
            }}
            secondary
          />
        </ModeButton>
      </ButtonsModeWrapper>
      <BboxMenu className={showBboxInMenu ? 'visible' : ''}>
        <Title>Enter bbox coordinates</Title>
        <BboxWrapper>
          <div>
            <Input
              type="text"
              name="bboxLeft"
              value="-89.533"
              label="Left | Min Long"
              onChange={e => archiveStore.setBBoxInputLeft(parseFloat(e.target.value))}
            />
            <Input
              type="text"
              name="bboxBottom"
              value="-52.406"
              label="Bottom | Min Lat"
              onChange={e => archiveStore.setBBoxInputBottom(parseFloat(e.target.value))}
            />
          </div>
          <div>
            <Input
              type="text"
              name="bboxRigth"
              value="-30.066"
              label="Rigth | Max Long"
              onChange={e => archiveStore.setBBoxInputRight(parseFloat(e.target.value))}
            />
            <Input
              type="text"
              name="bboxTop"
              value="20.371"
              label="Top | Max Lat"
              onChange={e => archiveStore.setBBoxInputTop(parseFloat(e.target.value))}
            />
          </div>
        </BboxWrapper>
      </BboxMenu>
      <ButtonWrapper className={showBboxInMenu ? 'move' : ''}>
        <Button text="Search" onClick={handleSearchClick} disabled={disableButtonSearch} />
      </ButtonWrapper>
    </FiltersWrapper>
  );
});

SearchTab.displayName = 'Search';
