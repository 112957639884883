import React from 'react';

import { CrossIcon } from 'components/icons';

import { ActiveFiltersList, ActiveFilterContainer } from './activefilters.styles';

import { classNames } from 'config/helpers';

export interface Props {
  activeFilters: any;
  className?: string;
  clearFilterAction: (filterToClear: string) => void;
  show?: boolean;
}

export const ActiveFilters = ({
  activeFilters,
  className,
  clearFilterAction,
  show = true
}: Props) => {
  const activeFiltersArray: Array<string> = [];
  for (const filter in activeFilters) {
    if (
      (filter === 'startDate' || filter === 'endDate') &&
      activeFilters[filter] !== '' &&
      !activeFiltersArray.includes('Date')
    )
      activeFiltersArray.push('Date');
    if (filter === 'products' && activeFilters[filter].length !== 0)
      activeFiltersArray.push('Product');
    if (filter === 'projects' && activeFilters[filter].length !== 0)
      activeFiltersArray.push('Project');
    if (filter === 'status' && activeFilters[filter].length !== 0)
      activeFiltersArray.push('Status');
    if (filter === 'taskId' && activeFilters[filter].length !== 0)
      activeFiltersArray.push('Task ID');
    if (filter === 'projectId' && activeFilters[filter].length !== 0)
      activeFiltersArray.push('Project ID');
  }
  return show ? (
    <ActiveFiltersList className={classNames(className, { show: activeFiltersArray.length > 0 })}>
      {activeFiltersArray.map((filter: string) => (
        <ActiveFilterContainer key={filter}>
          <CrossIcon className="clear-icon" onClick={() => clearFilterAction(filter)} />
          {filter}
        </ActiveFilterContainer>
      ))}
    </ActiveFiltersList>
  ) : null;
};

ActiveFilters.displayName = 'ActiveFilters';
