import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { MapRef } from 'react-map-gl';
import { FiltersQueriables } from 'typings';
import { Map as MaplibreMap, IControl } from 'maplibre-gl';

class ArchiveStore {
  activeTilesData: StacSearchResponse | null = null;
  drawPolygon: any = null;
  drawRef: MapboxDraw | null = null;
  showMap: boolean = false;
  mapRefStore: MapRef | null = null;
  bboxSearch: number[] = [-89.533, -52.406, -30.0665, 20.371];
  limitSearchAmount: string = '300';
  collectionsData: Collection[] | [] = [];
  collectionsSearch: string[] | [] = [];
  bboxInputLeft: number = -89.533;
  bboxInputBottom: number = -52.406;
  bboxInputRight: number = -30.0665;
  bboxInputTop: number = 20.371;
  dropdownCollectionSelected:
    | {
        value: string;
        text: string;
      }
    | undefined = undefined;

  filtersQueriables: FiltersQueriables = {
    'filter-lang': 'cql2-json',
    filter: {
      op: 'and',
      args: []
    }
  };
  inputOperators: { [key: string]: { value: string; text: string } } = {
    'eo:cloud_cover': {
      value: '<',
      text: 'Lower than'
    },
    'view:off_nadir': {
      value: '<',
      text: 'Lower than'
    },
    'view:sun_elevation': {
      value: '<',
      text: 'Greater than'
    }
  };
  dropdownFilterValues: {
    [key: string]: string | number | undefined;
  } = {
    'eo:cloud_cover': undefined,
    'satl:outcome_id': '',
    platform: '',
    'view:off_nadir': undefined
  };
  searchResultsThumbnail: StacSearchResponse | null = null;
  activeThumbnail: FeatureSearch | null = null;
  dateStart: Date;
  dateEnd: Date;
  maplibreMap: MaplibreMap | null = null;
  limitsSearchPolygon: any = null;

  constructor() {
    this.dateStart = new Date(); // Temporary default value
    this.dateEnd = new Date(); // Temporary default value
    makeAutoObservable(this, {
      setActiveTilesData: action,
      setDrawPolygon: action,
      setShowMap: action,
      setMapRefStore: action,
      setBBoxSearch: action,
      setLimitSearchAmount: action,
      setCollectionsData: action,
      setCollectionsSearch: action,
      setBBoxInputLeft: action,
      setBBoxInputRight: action,
      setBBoxInputBottom: action,
      setBBoxInputTop: action,
      setDropdownCollectionSelected: action,
      setFiltersQueriables: action,
      setInputOperators: action,
      setDropdownFilterValues: action,
      setSearchResultsThumbnail: action
    });
    this.setDatesToTodayAndPastThirtyDays();
  }

  setDatesToTodayAndPastThirtyDays() {
    const today = new Date();
    today.setUTCHours(23, 59, 59, 999); // Set the time to the end of the day UTC

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    thirtyDaysAgo.setUTCHours(23, 59, 59, 999); // Set the time to the beggining of the day UTC

    this.dateStart = thirtyDaysAgo;
    this.dateEnd = today;
  }

  @action
  setLimitsSearchPolygon(value: any) {
    this.limitsSearchPolygon = value;
  }

  @action
  setMaplibreMap(map: MaplibreMap | null) {
    this.maplibreMap = map;
  }

  @action
  changeDrawMode(mode: string) {
    if (this.drawRef) {
      this.drawRef.changeMode(mode);
    }
  }

  @action
  removeDrawControl(map: MaplibreMap) {
    if (this.drawRef) {
      map.removeControl(this.drawRef as unknown as IControl);
      this.drawRef = null;
    }
  }

  @action
  setDateStart(date: Date) {
    this.dateStart = date;
  }

  @action
  setDateEnd(date: Date) {
    this.dateEnd = date;
  }

  @action
  setActiveThumbnail(thumbnail: FeatureSearch | null) {
    this.activeThumbnail = thumbnail;
  }

  setSearchResultsThumbnail(data: StacSearchResponse) {
    this.searchResultsThumbnail = data;
  }

  setFiltersQueriables(filters: FiltersQueriables) {
    this.filtersQueriables = filters;
  }

  setInputOperators(
    key: string,
    operator: {
      value: string;
      text: string;
    }
  ) {
    this.inputOperators[key] = operator;
  }

  setDropdownFilterValues(key: string, value: string | number | undefined) {
    this.dropdownFilterValues[key] = value;
  }

  setDropdownCollectionSelected(
    value:
      | {
          value: string;
          text: string;
        }
      | undefined
  ) {
    this.dropdownCollectionSelected = value;
  }

  setActiveTilesData(data: StacSearchResponse | null) {
    this.activeTilesData = data;
  }

  setMapRefStore(ref: MapRef) {
    this.mapRefStore = ref;
  }

  setDrawPolygon(value: any) {
    this.drawPolygon = value;
  }

  setDrawRef(draw: MapboxDraw | null) {
    this.drawRef = draw;
  }
  setShowMap(value: boolean) {
    this.showMap = value;
  }

  setBBoxSearch(bbox: number[]) {
    this.bboxSearch = bbox;
  }

  setLimitSearchAmount(limit: string) {
    this.limitSearchAmount = limit;
  }

  setCollectionsData(data: Collection[]) {
    this.collectionsData = data;
  }

  setCollectionsSearch(data: string[]) {
    this.collectionsSearch = data;
  }

  setBBoxInputLeft(value: number) {
    this.bboxInputLeft = value;
  }

  setBBoxInputBottom(value: number) {
    this.bboxInputBottom = value;
  }

  setBBoxInputRight(value: number) {
    this.bboxInputRight = value;
  }

  setBBoxInputTop(value: number) {
    this.bboxInputTop = value;
  }

  updateArgs(name: string, value: string | number | undefined, op: string) {
    runInAction(() => {
      const existingIndex = this.filtersQueriables.filter.args.findIndex(
        (arg: any) =>
          arg.args[0] &&
          typeof arg.args[0] === 'object' &&
          'property' in arg.args[0] &&
          arg.args[0].property === name
      );

      let newArgs;
      if (existingIndex >= 0) {
        newArgs = this.filtersQueriables.filter.args
          .map((arg: any, index: any) => {
            if (index === existingIndex) {
              return value !== undefined && value !== ''
                ? { op, args: [{ property: name }, value] }
                : null;
            }
            return arg;
          })
          .filter((arg: any) => arg !== null);
      } else {
        newArgs =
          value !== undefined && value !== ''
            ? [...this.filtersQueriables.filter.args, { op, args: [{ property: name }, value] }]
            : this.filtersQueriables.filter.args;
      }

      this.filtersQueriables.filter.args = newArgs;
    });
  }
}


const archiveStore = new ArchiveStore();
export default archiveStore;
