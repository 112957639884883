import { styled, css } from 'config/common';
import { Link } from 'react-router-dom';

export const ToastDiv = styled.div`
  width: 100%;
  align-items: center;
  background-color: ${(props: any) => props.theme.error};
  background-position: 15px;
  background-repeat: no-repeat;
  border-radius: 3px;
  color: ${(props: any) => props.theme.neutral};
  display: none;
  justify-content: center;
  margin-top: 10px;
  overflow: hidden;
  padding: 15px;
  position: relative;
  &.open {
    display: flex;
  }
`;

export const SimpleDiv = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const buttonStyle = css`
  background-color: transparent;
  border: 0;
  color: black;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  outline: none;
  padding: 0;
  text-transform: uppercase;
`;

export const ToastButton = styled.button`
  ${buttonStyle};

  color: ${(props: any) => props.theme.neutral};
  font-size: 12px;
  margin-right: 15px;
`;

export const ToastLink = styled(Link)`
  ${buttonStyle};

  color: ${(props: any) => props.theme.neutral};
  font-size: 12px;
  margin-right: 15px;
  text-decoration: none;
`;

export const ToastMessage = styled.div`
  font-size: 16px;
  line-height: 20px;
`;

export const List = styled.div`
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  z-index: 9999999999;
  position: fixed;
  top: 56px;
  left: 15px;
`;
