import React, { useState } from 'react';
import { Input } from 'components/input';
import { Button } from 'components/button';
// import { PlusIcon } from 'components/icons'
import { BaseModal } from '../basemodal';

import { ProjectModalContainer, Title } from './projectmodal.styles';

export interface ProjectModalProps {
  name?: string;
  description?: string;
  onClick: (projectName: string, projectDescription?: string | undefined) => void;
  onClose: () => void;
  isShown: boolean;
}

export const ProjectModal = ({
  name,
  description,
  onClick,
  onClose,
  isShown
}: ProjectModalProps) => {
  const [projectName, setProjectName] = useState(name || '');

  React.useEffect(() => {
    if (isShown) {
      (document.querySelector('input[name="projectTitle"]') as HTMLElement)?.focus();
    } else {
      setProjectName('');
    }
  }, [isShown]);

  const editMode = !!name;
  const title = editMode ? 'Edit project' : 'Create new project';
  const confirmAction = () => {
    if (!projectName) return;
    onClick(projectName);
    document.body.style.overflow = 'auto';
    onClose();
  };
  return (
    <BaseModal isShown={isShown} hide={onClose}>
      <ProjectModalContainer>
        <Title>{title}</Title>
        <Input
          className="input"
          name="projectTitle"
          label="Project Name..."
          value={projectName}
          onChange={e => {
            setProjectName(e.target.value);
          }}
        />
        <div className="confirmButton">
          <Button
            text={editMode ? 'Edit' : 'Create'}
            onClick={confirmAction}
            disabled={!projectName}
            small
          />
        </div>
      </ProjectModalContainer>
    </BaseModal>
  );
};

ProjectModal.displayName = 'ProjectModal';
