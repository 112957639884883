import React from 'react'

import { StatusLabelStyled, TooltipDetails } from './statuslabel.styles'

import { classNames, statusFormater } from 'config/helpers'

export interface Props {
  status: string
  small?: boolean
  tooltip?: any
}

export const StatusLabel = ({ status, small, tooltip }: Props) => {
  return (
    <StatusLabelStyled className={classNames(status, { small })}>
      {statusFormater(status)}
      {tooltip ? <TooltipDetails>{tooltip}</TooltipDetails> : ''}
    </StatusLabelStyled>
  )
}
