import styled from '@emotion/styled';

export const MenuContainer = styled.div`
  position: relative;
  z-index: 2;
`;

export const ToggleElement = styled.div`

`;

export const MenuComponent = styled.div`
  width: auto;
  z-index: 9999;
  opacity: 0;
  border-radius: 4px;
  border: 1px solid ${(props: any) => props.theme.primary};
  padding: 5px;
  background: ${(props: any) => props.theme.primary};
  color: white;
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  position: absolute;
  &.showMenu {
    opacity: 1;
    pointer-events: all;
  }

  &.top-left {
    top: 0;
    left: 0;
    transform: translate(calc(-100% - 10px), calc(-100% + 20px))
  }
  &.top-left::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    bottom: 6px;
    right: -5px;
    border-style: solid;
    border-color: transparent transparent transparent ${(props: any) => props.theme.primary};
    border-width: 5px 0 5px 5px;
  }
  &.top-left::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    bottom: 6px;
    right: -6px;
    border-style: solid;
    border-color: transparent transparent transparent ${(props: any) => props.theme.primary};
    border-width: 5px 0 5px 5px;
  }

  &.top-right {
    top: 0;
    right: 0;
    transform: translate(calc(100% + 10px), calc(-100% + 20px))
  }
  &.top-right::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    bottom: 6px;
    left: -5px;
    border-style: solid;
    border-color: transparent ${(props: any) => props.theme.primary} transparent transparent;
    border-width: 5px 5px 5px 0;
  }
  &.top-right::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    bottom: 6px;
    left: -6px;
    border-style: solid;
    border-color: transparent ${(props: any) => props.theme.primary} transparent transparent;
    border-width: 5px 5px 5px 0;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(calc(-100% - 10px), calc(100% - 20px))
  }
  &.bottom-left::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    top: 6px;
    right: -5px;
    border-style: solid;
    border-color: transparent transparent transparent ${(props: any) => props.theme.primary};
    border-width: 5px 0 5px 5px;
  }
  &.bottom-left::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    top: 6px;
    right: -6px;
    border-style: solid;
    border-color: transparent transparent transparent ${(props: any) => props.theme.primary};
    border-width: 5px 0 5px 5px;
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
    transform: translate(calc(100% + 10px), calc(100% - 20px))
  }
  &.bottom-right::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    top: 6px;
    left: -5px;
    border-style: solid;
    border-color: transparent ${(props: any) => props.theme.primary} transparent transparent;
    border-width: 5px 5px 5px 0;
  }
  &.bottom-right::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    top: 6px;
    left: -6px;
    border-style: solid;
    border-color: transparent ${(props: any) => props.theme.primary} transparent transparent;
    border-width: 5px 5px 5px 0;
  }

  &.bottom-center {
    bottom: 0;
    left: 0;
    transform: translate(calc(-50% + 15px), calc(100% + 5px));
  }
  &.bottom-center::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    top: -5px;
    left: calc(50% - 5px);
    border-style: solid;
    border-color: transparent transparent ${(props: any) => props.theme.primary} transparent;
    border-width: 0 5px 5px 5px;
  }
  &.bottom-center::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    top: -6px;
    left: calc(50% - 5px);
    border-style: solid;
    border-color: transparent transparent ${(props: any) => props.theme.primary} transparent;
    border-width: 0 5px 5px 5px;
  }

  &.under {
    bottom: 0;
    left: 0;
    transform: translate(0, calc(100% + 10px))
  }
  &.under::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    top: -5px;
    left: 8px;
    border-style: solid;
    border-color: transparent transparent ${(props: any) => props.theme.primary} transparent;
    border-width: 0 5px 5px 5px;
  }
  &.under::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    top: -6px;
    left: 8px;
    border-style: solid;
    border-color: transparent transparent ${(props: any) => props.theme.primary} transparent;
    border-width: 0 5px 5px 5px;
  }

  &.under-left {
    bottom: 0;
    right: 0;
    transform: translate(0, calc(100% + 5px))
  }
  &.under-left::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    top: -5px;
    right: 8px;
    border-style: solid;
    border-color: transparent transparent ${(props: any) => props.theme.primary} transparent;
    border-width: 0 5px 5px 5px;
  }
  &.under-left::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    top: -6px;
    right: 8px;
    border-style: solid;
    border-color: transparent transparent ${(props: any) => props.theme.primary} transparent;
    border-width: 0 5px 5px 5px;
  }
`
