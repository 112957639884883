import React from 'react';
import { observer } from 'mobx-react-lite';

import { Router } from './routes';
import { useStores } from 'config/hooks';

import { BigToastList } from 'components/bigtoast';
import { ToastList } from 'components/toast';
import { GlobalStyles } from 'components/global';

const App: React.FC = observer(() => {
  const {
    rootStore: { notificationStore }
  } = useStores();

  return (
    <React.Fragment>
      <BigToastList notifications={notificationStore.bigNotifications} />
      <GlobalStyles />
      <Router />
      <ToastList notifications={notificationStore.notifications} />
    </React.Fragment>
  );
});

App.displayName = 'App';

export default App;
