import React, { Fragment } from "react"
import { observer } from "mobx-react-lite"
import { useStores } from "config/hooks"

interface Props {
  children?: React.ReactNode
  scope?: string
}

export const Scope = observer(({scope, children}: Props) => {
  const {
    rootStore: { userStore }
  } = useStores()

  if (scope && !userStore.scope.includes(scope)) return null

  return (
    <Fragment>
      {children}
    </Fragment>
  )

});
