import React, { useEffect, useRef } from 'react';

import { UploadIcon, DeleteIcon, LoadingIcon } from 'components/icons';

import {
  FileUploadWrapper,
  StyledLabel,
  HiddenInput,
  UploadedFile,
  ErrorText,
  HelperText,
  BottomText
} from './fileupload.styles';

import { useMergeState } from 'config/hooks';
import { classes } from 'config/common';

interface Props {
  className?: string;
  label?: string;
  name: string;
  onChange?: (files: FileList | null) => void;
  testid?: string;
  disabled?: boolean;
  helperText?: string;
  // Comma separated, with the . in front and without spaces (Example: '.json,.geojson')
  fileFormats?: string;
  validating?: boolean;
  clearInput?: boolean;
  // File size in kilobytes (Example: '100')
  maxSize?: string;
}

export const FileUpload = ({
  className,
  name,
  onChange,
  testid = '',
  label,
  disabled = false,
  helperText,
  fileFormats = '',
  validating = false,
  clearInput = false,
  maxSize = ''
}: Props) => {
  const [states, setStates] = useMergeState({
    value: '',
    error: { flag: false, type: '', message: '' },
    isOver: false
  });

  const inputRef = useRef<HTMLInputElement>(null);

  //  Clear input
  useEffect(() => {
    if (inputRef.current && clearInput) {
      inputRef.current.value = '';
      setStates({ value: '' });
    }
    // eslint-disable-next-line
  }, [clearInput]);

  // Check if file has the correct format and set error if not
  const hasCorrectFileFormat = (file: File) => {
    const allowedFormats = fileFormats.split(',');
    const fileFormat = `.${file.name.split('.').reverse()[0]}`;

    if (fileFormats && allowedFormats.includes(fileFormat)) {
      return true;
    } else if (fileFormats && !allowedFormats.includes(fileFormat)) {
      const lastFormat = allowedFormats.pop();
      setStates({
        error: {
          flag: true,
          type: 'format',
          message:
            allowedFormats.length + 1 > 1
              ? `Please upload a ${allowedFormats.join(', ')} or ${lastFormat} file`
              : `Please upload a ${lastFormat} file`
        }
      });
      return false;
    } else {
      return true;
    }
  };

  // Check if file doesn't exceed the max size and set error if it does
  const isSmallerThanMaxSize = (file: File) => {
    if (maxSize && file.size <= Number(maxSize) * 1000) {
      return true;
    } else if (maxSize && file.size > Number(maxSize) * 1000) {
      setStates({
        error: { flag: true, type: 'size', message: `The file cannot be larger than ${maxSize} kb` }
      });
      return false;
    } else {
      return true;
    }
  };

  // Handle drag event
  const dragHandler = (e: any) => {
    e.preventDefault();
    if (e.type === 'dragenter') setStates({ isOver: true });
    if (e.type === 'dragleave') setStates({ isOver: false });
  };

  // Handle drop event
  const dropHandler = (e: any) => {
    e.preventDefault();
    const {
      dataTransfer: { files }
    } = e;
    if (files && hasCorrectFileFormat(files[0]) && isSmallerThanMaxSize(files[0])) {
      setStates({ error: { flag: false, type: '', message: '' } });
      if (onChange) onChange(files);
      setStates({ value: files[0].name });
    }
    setStates({ isOver: false });
  };

  return (
    <FileUploadWrapper
      className={classes(className)}
      onDragEnter={e => dragHandler(e)}
      onDragLeave={e => dragHandler(e)}
      onDragOver={e => e.preventDefault()}
      onDrop={e => dropHandler(e)}
    >
      <HiddenInput
        id={name}
        accept={fileFormats}
        data-testid={testid}
        disabled={disabled}
        name={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (
            e.target.files &&
            hasCorrectFileFormat(e.target.files[0]) &&
            isSmallerThanMaxSize(e.target.files[0])
          ) {
            setStates({ error: { flag: false, type: '', message: '' } });
            if (onChange) onChange(e.target.files);
            setStates({ value: e.target.files[0].name });
          }
        }}
        ref={inputRef}
        type="file"
      />
      <StyledLabel
        className={classes({ disabled, isOver: states.isOver, error: !!states.error.flag })}
        htmlFor={name}
        onClick={e => {
          if (states.value) e.preventDefault();
        }}
      >
        {validating ? (
          <LoadingIcon className="loading-icon" />
        ) : (
          <UploadIcon className="upload-icon" />
        )}
        {validating ? (
          'Validating File'
        ) : states.value ? (
          <UploadedFile>
            <span>{states.value}</span>
            <DeleteIcon
              className="delete-icon"
              onClick={(e: any) => {
                // Clear Input
                if (inputRef.current) inputRef.current.value = '';
                // Remove states.value
                setStates({ value: '' });
                // Update parent
                if (onChange) onChange(null);
              }}
            />
          </UploadedFile>
        ) : (
          label
        )}
      </StyledLabel>
      <BottomText className={classes({ shown: !!states.error.flag || !!helperText })}>
        {states.error.flag && <ErrorText>{states.error.message}</ErrorText>}
        {!!helperText && <HelperText>{helperText}</HelperText>}
      </BottomText>
    </FileUploadWrapper>
  );
};

FileUpload.displayName = 'FileUpload';
