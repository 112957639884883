import { fetchItemDataByOutcomeId } from 'services/archiveService';
import archiveStore from 'stores/archiveStore';
import loadingNotificationStore from 'stores/loadingNotificationStore';

export const getRasterCoordinatesFromGeoJSON = (bbox: number[]): number[][] => {
  // For the raster images to work, the order is top left, top right, bottom right, bottom left.
  const coordinates = [
    [bbox[0], bbox[3]], // top-left
    [bbox[2], bbox[3]], // top-right
    [bbox[2], bbox[1]], // bottom-right
    [bbox[0], bbox[1]] // bottom-left
  ];
  return coordinates;
};

export const groupByOutcomeId = (arr: FeatureSearch[]): GroupedFeatureSearch[] => {
  const grouped = arr.reduce(
    (
      acc: Record<
        string,
        {
          datetime: string;
          platform: string;
          collection: string;
          ona: number;
          items: FeatureSearch[];
        }
      >,
      obj: FeatureSearch
    ) => {
      const key = obj.properties['satl:outcome_id'];
      if (!acc[key]) {
        acc[key] = {
          datetime: obj.properties.datetime,
          platform: obj.properties.platform,
          collection: obj.collection,
          ona: obj.properties['view:off_nadir'],
          items: []
        };
      }
      acc[key].items.push(obj);
      return acc;
    },
    {}
  );

  return Object.keys(grouped).map(key => ({
    outcome_id: key,
    datetime: grouped[key].datetime,
    platform: grouped[key].platform,
    collection: grouped[key].collection,
    ona: grouped[key].ona,
    items: grouped[key].items
  }));
};

export const transformDateTime = (input: string): string => {
  const date = new Date(input);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const findRel = (
  links: LinkSearchObject[] | undefined,
  rel: string
): LinkSearchObject | false => {
  const nextRelObject = links?.find(link => link.rel === rel);
  return nextRelObject || false;
};

export const addIdToProperties = (featureCollection: StacSearchResponse): StacSearchResponse => {
  const newFeatures = featureCollection.features.map(feature => {
    const newProperties = {
      ...feature.properties,
      id: feature.id,
      download_cloud: feature.assets.cloud.href,
      download_preview: feature.assets.preview.href,
      download_asset: feature.assets.analytic.href
    };

    return {
      ...feature,
      properties: newProperties
    };
  });

  return {
    type: featureCollection.type,
    features: newFeatures,
    context: featureCollection.context,
    links: featureCollection.links
  };
};

export const handleClickCapture = async (item: FeatureSearch | any, token: string | undefined) => {
  const bbox = item.bbox;
  const center: [number, number] = [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2];

     archiveStore.maplibreMap?.flyTo({
       center,
       zoom: 10,
       essential: true,
       duration: 2500
     });
  loadingNotificationStore.setText('Processing...');
  loadingNotificationStore.setLoading(true);
  fetchTilesDataByOutcomeId(token, item);
};

export const fetchTilesDataByOutcomeId = async (token: string | undefined, item: any) => {
  fetchItemDataByOutcomeId(
    token,
    archiveStore.dateStart.toISOString(),
    archiveStore.dateEnd.toISOString(),
    archiveStore.collectionsSearch,
    !!archiveStore.drawPolygon ? null : archiveStore.bboxSearch,
    !!archiveStore.drawPolygon ? archiveStore.drawPolygon : null,
    archiveStore.limitSearchAmount,
    item.properties['satl:outcome_id'],
    response => {
      if (
        response.status === 200 &&
        response.data.features.length === 0 &&
        isSameDay(archiveStore.dateStart, archiveStore.dateEnd)
      ) {
        loadingNotificationStore.setErrorMessage(
          'We are still generating this data. Please retry later'
        );
      }
      if (response.data.features.length > 0) {
        const processedFeatures = addIdToProperties(response.data);
        archiveStore.setActiveTilesData(processedFeatures);
      }
      loadingNotificationStore.setLoading(false);
    },
    (e: any) => console.error(e)
  );
};

export const formatToStartOfDayUTC = (date: Date): string => {
  const utcDate = new Date(date);

  utcDate.setUTCHours(0, 0, 0, 0);

  // Format the date to YYYY-MM-DDT00:00:00Z
  const year = utcDate.getUTCFullYear();
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(utcDate.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}T00:00:00Z`;
};

export const formatToEndOfDayUTC = (date: Date): string => {
  const utcDate = new Date(date);

  utcDate.setUTCHours(0, 0, 0, 0);

  // Format the date to YYYY-MM-DDT00:00:00Z
  const year = utcDate.getUTCFullYear();
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(utcDate.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}T23:59:59Z`;
}

export const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
