import axios from 'axios';
import { API_URI, BLACKWING_API_URI } from 'config';
import { parseParameters } from 'config/helpers';

export const publishTask = (data: any, token: string, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  if(data.expected_age === '') delete data.expected_age;
  axios.post(`${BLACKWING_API_URI}/tasks/`, data, {
    headers: { authorizationToken: `Bearer ${token}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error.response);
  })
}

export const getTasks = (opt: any, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  const t = opt.token;
  axios.get(`${API_URI}/geodas/tasks/`, {
    params: parseParameters(opt),
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error.response ? error.response : error);
  })
}

export const getTask = (id: string | number, opt: any, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  const t = opt.token;
  axios.get(`${BLACKWING_API_URI}/tasks/${id}`, {
    params: parseParameters(opt),
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error.response ? error.response : error);
  })
}


export const getProducts = (opt: any, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  const t = opt.token;
  axios.get(`${BLACKWING_API_URI}/products/`, {
    params: parseParameters(opt),
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error.response ? error.response : error);
  })
}

export const getProjects = (opt: any, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  const t = opt.token;
  axios.get(`${BLACKWING_API_URI}/projects/`, {
    params: parseParameters(opt),
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error.response ? error.response : error);
  })
}

export const getCapturesPerTask = (taskId: string, opt: any, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  const t = opt.token;
  axios.get(`${BLACKWING_API_URI}/tasks/${taskId}/captures/`, {
    params: parseParameters(opt),
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error.response ? error.response : error);
  })
}

export const cancelTask = (taskId: string, opt: any, onSuccess: (r: any) => void, onError: (r: any) => void) => {
  const t = opt.token;
  axios.patch(`${BLACKWING_API_URI}/tasks/${taskId}/cancel/`, {}, {
    params: parseParameters(opt),
    headers: { authorizationToken: `Bearer ${t}` }
  })
  .then(response => {
    onSuccess(response);
  })
  .catch(error => {
    onError(error.response ? error.response : error);
  })
}
