import styled from '@emotion/styled/macro';

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
`;

export const ModalWindow = styled.div`
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  /* border: 1px solid #CDCDCD; */
  border-radius: 4px;
  margin: 0;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const ModalBase = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999999;
`;
