import React from 'react';

import { Menu } from 'components/menu';

interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <div>
      <Menu />
      {children}
    </div>
  );
};

Layout.displayName = 'Layout';
