import React, { useState, useRef, useEffect } from "react"

import { classes } from 'config/common'

import { Container, TabsHeader, Tab, ActiveTabIndicator, TabContent } from "./tabscontainer.styles"

interface TabObject {
  label: string
  content: string | JSX.Element
}

interface TabsContainerProps {
  className?: string
  tabs: Array<TabObject>
}

export const TabsContainer = ({ className, tabs }: TabsContainerProps) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].label)

  const tabIndex = tabs.findIndex(tab => selectedTab === tab.label)

  const refContainer = useRef<HTMLDivElement>(null)
  const refHeader = useRef<HTMLUListElement>(null)

  const [headerHasScroll, setHeaderHasScroll] = useState(false)
  useEffect(() => {
    if (refContainer.current !== null && refHeader.current !== null) {
      setHeaderHasScroll(refContainer.current.scrollWidth < refHeader.current.scrollWidth)
    }
  }, [])

  const noTabs = tabs.length === 1

  const tabsHeader = (
    <TabsHeader ref={refHeader}>
      {tabs.map(({label}) => {
        const selectedTabHandler = () => { setSelectedTab(label) }

        return  (
          <Tab
            className={classes({isSelected: selectedTab === label})}
            tabCount={tabs.length}
            onClick={selectedTabHandler}
            key={label}
          >
            {label}
          </Tab>
        )
      })}
      <ActiveTabIndicator headerHasScroll={headerHasScroll} tabCount={tabs.length} activeTab={tabIndex} />
    </TabsHeader>
   )

  return (
    <Container className={className} ref={refContainer}>
      {noTabs ? null : tabsHeader}
      <TabContent className={classes({ noTabs })}>
        {tabs[tabIndex] ? tabs[tabIndex].content : tabs[0].content}
      </TabContent>
    </Container>
  )
}
TabsContainer.displayName = 'TabsContainer'
