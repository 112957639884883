import { styled } from '../../config/common';

export const Container = styled.div`
  margin: 48px;
`;

export const Title = styled.div`
  margin: 80px 0 20px;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin-bottom: 20px;
  button, a {
    margin: 0 20px 20px 0;
  }
`;
