import { Tile as TileLayer } from 'ol/layer';
import { OSM, XYZ, BingMaps } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Stroke, Style, Icon, Fill } from 'ol/style';

/**
 * zIndex documentation
 * 0 - 19 for base maps
 * 20 - 99 for vector layers
 * < 100 for tile layers (images)
 **/

export const baseMaps = {
  stadia: new TileLayer({
    source: new XYZ({
      url: `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}.png`
    }),
    preload: Infinity,
    zIndex: 2,
    maxZoom: 28
  }),
  osm: new TileLayer({
    source: new OSM(),
    visible: false
  }),
  bing: new TileLayer({
    visible: false,
    preload: Infinity,
    zIndex: 2,
    maxZoom: 16,
    source: new BingMaps({
      key: 'AsFjwneR-JNeYIIQHn0lWJe0nIug-RmI6Dag7UtAMsZ7IHYv7amCknDatLfAkCWt',
      imagerySet: 'Aerial'
    })
  })
};

export const vectorLayer = new VectorLayer({
  source: new VectorSource({}),
  style: [
    new Style({
      stroke: new Stroke({
        color: '#555555',
        width: 4
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.3)'
      })
    }),
    new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: '/pin.svg',
        scale: 1
      })
    })
  ],
  zIndex: 20
});

export const drawVectorLayer = new VectorLayer({
  source: new VectorSource({}),
  style: [
    new Style({
      stroke: new Stroke({
        color: '#00B6DA',
        width: 2
      }),
      fill: new Fill({
        color: 'rgba(89, 200, 230, 0.25)'
      })
    })
  ],
  zIndex: 21
});

export const handleTooltipPosition = (e: any) => {
  let x = e.clientX;
  let y = e.clientY;

  let tooltip = document.querySelector('.map-tooltip');
  if (tooltip) {
    (tooltip as HTMLElement).style.left = `${x + 20}px`;
    (tooltip as HTMLElement).style.top = `${y - 62}px`;
  }
};
