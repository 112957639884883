/* istanbul ignore file */
import { model, Model, modelAction, tProp, prop, types } from 'mobx-keystone';
import VectorTileLayer from 'ol/layer/VectorTile';

@model('geodas/Layer')
export class LayerItem extends Model({
  name: tProp(types.string),
  // type: tProp(types.enum({WMS: "WMS", WFS: "WFS"})),
  opacity: prop(1),
  visible: tProp(types.boolean, false),
  layerId: tProp(types.string),
  metadata: prop<any>()
}) {
  OLItem?: any | VectorTileLayer;

  setOLItem(item: any | VectorTileLayer) {
    this.OLItem = item;
  }

  getOLItem() {
    return this.OLItem;
  }

  @modelAction
  setLayerMetadata(v: any) {
    this.metadata = v;
  }

  @modelAction
  setVisibility(v: boolean = !this.visible) {
    this.visible = v;
    this.OLItem?.setVisible(v);
  }
}

@model('geodas/Subfolder')
export class LayerSubfolder extends Model({
  name: tProp(types.string),
  type: tProp(types.string),
  layers: tProp(types.array(types.model<LayerItem>(LayerItem))),
  visible: tProp(types.boolean, false),
}) {

  @modelAction
  setVisibility(v: boolean = !this.visible) {
    this.layers.map(subitem => {
      subitem.setVisibility(v);
      return false;
    })
    this.visible = v;
  }
}

@model('geodas/Folder')
export class LayerFolder extends Model({
  name: tProp(types.string),
  items: tProp(types.array(types.or(types.model<LayerSubfolder>(LayerSubfolder), types.model<LayerItem>(LayerItem)))),
  visible: tProp(types.boolean, false),
}) {

  @modelAction
  setVisibility(v: boolean = !this.visible) {
    this.items.map(subitem => {
      subitem.setVisibility(v);
      return false;
    })
    this.visible = v;
  }

  getLayers() {
    return this.items.map(item => {
      if(item instanceof LayerSubfolder){
        return item.layers.map(subitem => subitem)
      } else {
        return item
      }
    }).flat()
  }
}

@model('geodas/FolderList')
export class LayerFolderList extends Model({
  name: tProp(types.string, ''),
  date: tProp(types.string, ''),
  wms: tProp(types.string, ''),
  layers: tProp(types.array(types.model<LayerFolder>(LayerFolder)), () => []),
}) {
  
  updated: boolean = false;

  setVisibility(v: boolean) {
    this.layers.map(item => {
      item.setVisibility(v)
      return false;
    })
  }

  addLayers(folderName: string, layers: Array<LayerItem>) {
    this.updated = true;
    const folder = this.layers.find((item: LayerFolder) => item.name === folderName)
    if (folder) {
      layers.map(item => {
        folder.items.push(item)
        return false
      })
    }
  }

  getLayers() {
    return this.layers.map(item => {
      return item.getLayers()
    }).flat()
  }
  
}
