/* istanbul ignore file */
import { computed } from 'mobx';
import { model, Model, modelAction, tProp, types } from 'mobx-keystone';

import Map from 'ol/Map';
import { transformExtent } from 'ol/proj';
import TileLayer from 'ol/layer/Tile.js';
// eslint-disable-next-line
import { OSM, XYZ, BingMaps } from 'ol/source';
import { NominatimItem } from 'typings/stores';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import WKT from 'ol/format/WKT';
import { fromLonLat } from 'ol/proj';
import { IdentifyStore } from './identify.store';
import { Draw } from 'ol/interaction';
import { Stroke, Style, Icon } from 'ol/style';
import { GeoJSON } from 'ol/format';
import { Geometry, Point, LineString, Polygon, Circle } from 'ol/geom';
import { getArea } from 'ol/sphere';
import * as ol from 'ol';
import Overlay from 'ol/Overlay';

@model('geodas/MapStore')
export class MapStore extends Model({
  showSearch: tProp(types.boolean, false),
  identifyStore: tProp(types.unchecked<IdentifyStore>()),
  editing: tProp(types.boolean, false),
  tableCoordinates: tProp(types.array(types.number), () => []),
  filtering: tProp(types.boolean, false),
  hasPlayground: tProp(types.boolean, false),
  loading: tProp(types.boolean, false)
}) {
  map?: Map;

  baseMaps: any = {
    stadia: new TileLayer({
      source: new XYZ({
        url: `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}.png`
      }),
      preload: Infinity,
      zIndex: 2,
      maxZoom: 28
    })
    // 'osm': new TileLayer({
    //   source: new OSM()
    // }),
    // 'bing': new TileLayer({
    //   visible: false,
    //   preload: Infinity,
    //   zIndex: 2,
    //   maxZoom: 16,
    //   source: new BingMaps({
    //     key: 'AsFjwneR-JNeYIIQHn0lWJe0nIug-RmI6Dag7UtAMsZ7IHYv7amCknDatLfAkCWt',
    //     imagerySet: 'Aerial'
    //   })
    // }),
  };

  format = new WKT();

  vectorLayer = new VectorLayer({
    source: new VectorSource({}),
    style: [
      new Style({
        stroke: new Stroke({
          color: '#555555',
          width: 4
        })
      }),
      new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: '/pin.svg',
          scale: 1
        })
      })
    ],
    zIndex: 20
  });

  draw = new Draw({
    source: this.vectorLayer.getSource() || undefined,
    type: 'Polygon'
  });

  @computed
  get isEditing() {
    return this.editing;
  }

  @modelAction
  setLoading(v: boolean = !this.loading) {
    this.loading = v;
  }

  @modelAction
  setCoordinates(r: number[]) {
    this.tableCoordinates = r;
  }

  @modelAction
  setHasPlayground(v: boolean) {
    this.hasPlayground = v;
  }

  initPlaygroundAOI(gj: any) {
    const ft = new GeoJSON().readFeatures(gj, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    });
    ft[0].setId('playground');
    const vectorSource = new VectorSource({
      features: ft
    });
    this.vectorLayer.setSource(vectorSource);
    this.setHasPlayground(true);
    this.zoomToPlayground();
  }

  zoomToPlayground() {
    const features = this.vectorLayer.getSource()?.getFeatures();
    const playground = features?.find(item => item.getId() === 'playground');
    if (playground) {
      const ext = playground.getGeometry()?.getExtent();
      if (ext) this.getMap.getView().fit(ext);
      let z = this.getMap.getView().getZoom() || 6;
      if (z > 20) z = 18;
      this.getMap.getView().setZoom(z);
    }
  }

  zoomToLayer(layer: any) {
    // this.setLoading(true);
    if (layer.metadata) {
      let ext = transformExtent(
        (new GeoJSON()
          .readFeature(layer.metadata.footprint)
          .getGeometry() as Geometry).getExtent(),
        'EPSG:4326',
        'EPSG:3857'
      );
      this.setLoading(false);
      this.getMap.getView().fit(ext);
      layer.setVisibility(true);
    }
  }

  @computed
  get search() {
    return this.showSearch;
  }

  @computed
  get identify() {
    return this.identifyStore;
  }

  get getMap() {
    return this.map as Map;
  }

  @modelAction
  toggleSearch(b: boolean = !this.search) {
    this.showSearch = b;
  }

  @modelAction
  setFilter(b: boolean = !this.filtering) {
    // const menu = document.querySelector('.ol-overlaycontainer-stopevent');
    // if(menu){menu.classList.toggle('right--move')}
    this.filtering = b;
  }

  @modelAction
  setEditing(b: boolean = !this.editing) {
    this.editing = b;
  }

  addMarkerImage(layer: any) {
    let ext = transformExtent(
      (new GeoJSON().readFeature(layer.metadata.footprint).getGeometry() as Geometry).getExtent(),
      'EPSG:4326',
      'EPSG:3857'
    );
    layer.getOLItem().setExtent(ext);
    var iconFeature = new ol.Feature({
      geometry: new Point(
        fromLonLat(
          [
            layer.metadata.metadata.location_requested.longitude,
            layer.metadata.metadata.location_requested.latitude
          ],
          'EPSG:3857'
        )
      ),
      name: layer.name
    });
    this.vectorLayer.getSource()?.addFeature(iconFeature);
  }

  updateBaseMap(name: string) {
    if (this.baseMaps['osm'].getVisible()) {
      this.baseMaps['stadia'].setVisible(true);
      this.baseMaps['osm'].setVisible(false);
    } else {
      // this.baseMaps[name].setVisible(!this.baseMaps[name].getVisible())
      this.baseMaps['osm'].setVisible(true);
      this.baseMaps['stadia'].setVisible(false);
    }
  }

  setMap(map: Map) {
    this.map = map;
    this.onMapClick();
    this.map.getLayers().extend(Object.entries(this.baseMaps).map(item => item[1] as any));
    this.map.addLayer(this.vectorLayer);
    // this.map.addInteraction(new Modify({source: this.vectorLayer.getSource()}))
  }

  onMapClick(callback?: (e: any) => void) {}

  initDraw() {
    this.getMap.addInteraction(this.draw);
    this.draw.on('drawstart', () => {
      const { r } = this.getVisibleLayers;
      if (r.length) {
        this.setEditing(true);
      } else {
        this.getMap.removeInteraction(this.draw);
        this.vectorLayer.getSource()?.clear();
        this.getMap.addInteraction(this.draw);
      }
    });
    this.draw.on('drawend', e => {
      this.getMap.removeInteraction(this.draw);
    });
  }

  updateDraw(selection: number) {
    this.finishDraw();
    let type: any;
    switch (selection) {
      case 0:
        type = LineString;
        break;
      case 1:
        type = Polygon;
        break;
      case 2:
        type = Circle;
        break;
      case 3:
        type = Point;
        break;
      default:
        type = Polygon;
    }
    this.draw = new Draw({
      source: this.vectorLayer.getSource() || undefined,
      type
    });
    this.initDraw();
  }

  finishDraw() {
    this.getMap.removeInteraction(this.draw);
  }

  setMapExtent({ boundingbox }: NominatimItem) {
    this.toggleSearch(false);
    const ext = transformExtent(
      [
        parseFloat(boundingbox[2]),
        parseFloat(boundingbox[0]),
        parseFloat(boundingbox[3]),
        parseFloat(boundingbox[1])
      ],
      'EPSG:4326',
      'EPSG:3857'
    );
    this.getMap.getView().fit(ext);
    if ((this.getMap.getView().getZoom() as number) > 19) {
      this.getMap.getView().setZoom(19);
    }
  }

  @computed
  // get getVisibleLayers(): Array<number>
  get getVisibleLayers() {
    const r: Array<number> = [];
    let fm: boolean = false;
    this.getMap.getLayers().forEach(item => {
      if (item.getVisible() && item.get('advanced')) {
        r.push(item.get('layer_id'));
        if (item.get('forcemanager')) fm = true;
      }
    });
    return { r, fm };
  }

  getMarker(id: string, props: any = {}) {
    if (this.getMap.getOverlayById(id)) {
      return this.getMap.getOverlayById(id);
    } else {
      return this.addMarker(id, props);
    }
  }

  addMarker(id: string, props: any, element?: HTMLElement) {
    if (!element) {
      element = document.getElementById(id) as HTMLElement;
    }
    this.getMap.addOverlay(
      new Overlay({
        element,
        id: id,
        ...props
      })
    );
    return this.getMap.getOverlayById(id);
  }

  refreshVisibleLayers() {
    this.getMap.getLayers().forEach((item: any) => {
      if (item.getVisible() && item.get('advanced')) {
        item.getSource().refresh();
      }
    });
  }

  addFeature(geom: string) {
    // refactor
    this.setEditing(true);
    this.vectorLayer.getSource()?.clear();
    const ft = this.ftFromGeom(geom);
    this.vectorLayer.getSource()?.addFeature(ft);
  }

  ftFromGeom(geom: string) {
    return this.format.readFeature(geom, {
      featureProjection: 'EPSG:3857'
    });
  }

  saveFeature() {
    if (this.vectorLayer.getSource()?.getFeatures()[0]) {
      const { r } = this.getVisibleLayers;
      const result = this.format.writeGeometry(
        this.vectorLayer.getSource()?.getFeatures()[0].getGeometry() as Geometry
      );
      this.setEditing(false);
      this.identifyStore.saveFeature(result, r[0], (rs: any) => {
        this.vectorLayer.getSource()?.clear();
        this.refreshVisibleLayers();
      });
    }
  }

  deleteFeature() {
    this.setEditing(false);
    this.identifyStore.deleteFeature(rs => {
      this.vectorLayer.getSource()?.clear();
      this.refreshVisibleLayers();
    });
  }

  cancelAction() {
    this.setEditing(false);
    this.vectorLayer.getSource()?.clear();
  }

  formatArea(polygon: Geometry) {
    const area = getArea(polygon);
    return `${Math.round((area / 1000000) * 100) / 100} km2 / ${(Math.round(
      (area / 1000000) * 100
    ) /
      100) *
      100} ha`;
  }
}
