import styled from '@emotion/styled/macro';
import ReactDatePicker from 'react-datepicker';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.span`
  height: 20px;
  background-color: ${(props: any) => props.theme.neutral};
  color: ${(props: any) => props.theme.neutral80};
  ${(props: any) => props.theme.small.styles};
  line-height: 20px;
  margin: auto;
  pointer-events: none;
  padding: 0 2px;
  transition: all 0.2s ease-out;
  z-index: 1;
  position: absolute;
  left: 13px;
  top: 0;
  bottom: 0;
  &:hover {
    color: ${(props: any) => props.theme.primary};
  }
`;

export const InputContainer = styled.div`
  flex: 1 1 50%;
  display: flex;
  position: relative;
  &.active {
    ${Label} {
      color: ${(props: any) => props.theme.neutral40};
      ${(props: any) => props.theme.caption.styles};
      transform: translateY(-20px);
    }
  }
  .react-datepicker-wrapper {
    width: calc(100% - 7px);
  }
  .react-datepicker-popper {
    z-index: 9999;
  }
  .calendar-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  &.start-date {
    .calendar-icon {
      right: 28px;
    }
    .react-datepicker-wrapper {
      margin-right: 7px;
    }
  }
  &.end-date {
    .calendar-icon {
      right: 16px;
    }
    .react-datepicker-wrapper {
      margin-left: 12px;
    }
    ${Label} {
      left: 25px;
    }
  }
  .react-datepicker__input-container {
    width: 100%;
    .input-style {
      width: 100%;
      height: 40px;
      border: 1px solid ${(props: any) => props.theme.neutral40};
      border-radius: 4px;
      cursor: pointer;
      font-family: 'Public Sans', sans-serif;
      outline: none;
      padding: 10px 20px;
      &::placeholder {
        color: ${(props: any) => props.theme.neutral80};
        font-size: 14px;
        font-weight: 500;
      }
    }
    .react-datepicker__aria-live {
      display: none;
    }
  }
  .calendar-style {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border: 1px solid ${(props: any) => props.theme.neutral40};
    border-radius: 0;
    background: white;
    margin: 5px 0;
    z-index: 99999;
    .react-datepicker__aria-live {
      display: none;
    }
  }
  .react-datepicker__navigation {
    width: 24px;
    height: 24px;
    overflow: hidden;
    position: absolute;
    top: 16px;
    outline: none;
    border: none;
    background: none;
    padding: 0;
    color: transparent;
    cursor: pointer;
    transition: transform 0.1s linear;
    &:hover {
      transform: scale(1.3);
    }
    &::before {
      width: 24px;
      height: 24px;
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &--previous {
      left: 16px;
      &::before {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 13L1 7l6-6' stroke='%23002340' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
      }
    }
    &--next {
      right: 16px;
      &::before {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 13l6-6-6-6' stroke='%23002340' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
      }
    }
  }
  .react-datepicker__month-container {
    flex: 0 0 50%;
    padding: 20px;
    border-right: 1px solid ${(props: any) => props.theme.neutral40};
    color: #002340;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    &:last-child {
      border-right: none;
    }
  }
  .react-datepicker__header {
    text-align: center;
    .react-datepicker__day-names {
      display: flex;
      justify-content: flex-start;
      margin-top: 12px;
      .react-datepicker__day-name {
        width: 27px;
        height: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .react-datepicker__month {
    .react-datepicker__week {
      display: flex;
      justify-content: flex-start;
      margin-top: 12px;
      z-index: 1;
      position: relative;
      .react-datepicker__day {
        width: 27px;
        height: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.1s linear, color 0.1s linear, border-radius 0.1s linear;
        &--today {
          border: 1px solid #59c8e6;
          &.react-datepicker__day--disabled {
            border: none;
          }
        }
        &--in-selecting-range {
          position: relative;
          background: #e6e6e6;
          border-radius: 0;
          &:first-of-type {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
          }
          &:first-of-type.react-datepicker__day--selecting-range-end::before {
            background-color: transparent;
          }
          &:last-of-type,
          &[data-last-day] {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
          }
          &:last-of-type.react-datepicker__day--selecting-range-start::before {
            background-color: transparent;
          }
        }
        &:hover,
        &--selecting-range-start,
        &--selecting-range-end {
          border-radius: 50%;
          background: #59c8e6;
          color: white;
        }
        &--selecting-range-start {
          &::before {
            width: 100%;
            height: 100%;
            content: '';
            z-index: -1;
            background-color: #e6e6e6;
            position: absolute;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
          }
        }
        &--selecting-range-end {
          &::before {
            width: 100%;
            height: 100%;
            content: '';
            z-index: -1;
            background-color: #e6e6e6;
            position: absolute;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
          }
        }
        &--outside-month {
          visibility: hidden;
          & + .react-datepicker__day--in-selecting-range {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
          }
        }
        &--disabled {
          color: ${(props: any) => props.theme.neutral40};
          cursor: not-allowed;
          &:hover {
            color: ${(props: any) => props.theme.neutral40};
            background-color: transparent;
          }
        }
      }
    }
  }
  .react-datepicker__close-icon {
    display: none;
  }
`;

export const DateInput = styled(ReactDatePicker as any)`
  flex: 1 1 50%;
`;
