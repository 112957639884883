import styled from '@emotion/styled/macro';

export const Tooltip = styled.div`
  width: auto;
  background-color: ${(props: any) => props.theme.primary};
  border-radius: 4px;
  color: white;
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  opacity: 0;
  padding: 8px 12px;
  transition: background-color 0.3s linear;
  white-space: nowrap;
  z-index: 20;
  position: absolute;
  &.error {
    background-color: ${(props: any) => props.theme.error};
  }
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  &.draw {
    cursor: none;
  }
  &:hover.draw ${Tooltip} {
    opacity: 1;
  }
  &.pointer {
    cursor: pointer;
  }
`;
