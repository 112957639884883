import styled from "@emotion/styled/macro"

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  .clear-icon {
    cursor: pointer;
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity 0.2s linear;
    position: absolute;
    top: 50%;
    right: 12px;
    &.show {
      opacity: 1;
    }
  }
`

export const LeftIconContainer = styled.div`
  width: 24px;
  height: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 12px;
  .search-icon {
    fill: ${(props: any) => props.theme.neutral};
  }
  .loading-icon {
    width: 24px;
    height: 24px;
  }
`

export const InputStyled = styled.input`
  width: 400px;
  background-color: ${(props: any) => props.theme.neutral80};
  border: none;
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral};
  font-family: 'Public Sans', sans-serif;
  font-size: ${(props: any) => props.theme.sizes.small};
  line-height: 18px;
  outline: none;
  padding: 12px 40px 12px 48px;
  transition: border-radius 0.2s linear;
  &.results {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`

export const InputResults = styled.ul`
  width: 100%;
  max-height: 0;
  background-color: ${(props: any) => props.theme.neutral80};
  overflow-y: scroll;
  padding: 0 12px;
  position: absolute;
  transition: max-height 0.2s ease-in-out, padding  0.2s ease-in-out;
  top: 100%;
  &.show {
    max-height: 500px;
    border-top: 1px solid ${(props: any) => props.theme.neutral60};
    padding: 12px;
  }
`

export const Result = styled.li`
  color: ${(props: any) => props.theme.neutral};
  cursor: pointer;
  font-size: ${(props: any) => props.theme.sizes.small};
  line-height: 18px;
  padding: 12px;
  transition: background-color 0.3s linear;
  &:hover {
    background-color: ${(props: any) => props.theme.neutral60};
  }
`
