import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { SearchIcon, CrossIcon, LoadingIcon } from 'components/icons';

import {
  InputWrapper,
  LeftIconContainer,
  InputStyled,
  InputResults,
  Result
} from './placesearchinput.styles';

import { classes } from 'config/common';

interface Props {
  className?: string;
  name: string;
  onOptionSelect: (latLong: Array<number>) => void;
}

export const PlaceSearchInput = ({ className, name, onOptionSelect }: Props) => {
  const [currentValue, setValue] = useState('');
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.getElementById('place-search-input')?.focus();
  }, []);

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      let cancelToken = axios.CancelToken.source();
      const getPlaces = async (q: string) => {
        // Cancel prev req
        cancelToken.cancel('Axios request canceled.');
        // Set new token
        cancelToken = axios.CancelToken.source();

        setIsLoading(true);

        try {
          const { data } = await axios(
            `https://nominatim.openstreetmap.org/search?q=${q}&format=json&limit=30&addressdetails=1`,
            {
              cancelToken: cancelToken.token
            }
          );
          setPlaces(data);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          if (places.length !== 0) setIsLoading(false);
        }
      };
      setValue(e.currentTarget.value);
      getPlaces(e.currentTarget.value);
    },
    [places.length]
  );

  const clearInput = () => {
    setPlaces([]);
    setValue('');
  };

  const handlePlaceSelection = (latLong: Array<number>) => {
    setValue('');
    setPlaces([]);
    onOptionSelect(latLong);
  };

  return (
    <InputWrapper className={classes(className)}>
      <LeftIconContainer>
        {isLoading ? (
          <LoadingIcon className="loading-icon" />
        ) : (
          <SearchIcon className="search-icon" />
        )}
      </LeftIconContainer>
      <InputStyled
        autoComplete="off"
        className={classes({ results: places.length > 0 })}
        id="place-search-input"
        name={name}
        onChange={handleChange}
        type="text"
        value={currentValue}
      />
      <CrossIcon
        className={classes('clear-icon', { show: !!currentValue })}
        size="16"
        onClick={clearInput}
      />
      <InputResults className={classes({ show: places.length > 0 })}>
        {places.map((place: any) => {
          return (
            <Result
              key={place.place_id}
              onClick={() => handlePlaceSelection([place.lat, place.lon])}
            >
              {place.display_name}
            </Result>
          );
        })}
      </InputResults>
    </InputWrapper>
  );
};

PlaceSearchInput.displayName = 'PlaceSearchInput';
