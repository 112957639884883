import { Layout } from 'components/layout';
import { observer } from 'mobx-react-lite';
import Map, { Source, Layer, Popup } from 'react-map-gl/maplibre';
import type { MapRef } from 'react-map-gl/maplibre';
import { IControl, Map as MaplibreMap } from 'maplibre-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BottomContainer, LeftPane, TopBarTitle } from 'views/images/images.styles';
import { MapContainer, PopUpContainer } from './archive.styles';
import { handleClickCapture, transformDateTime } from './helpers';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { TabsContainer } from 'components/tabscontainer';
import archiveStore from 'stores/archiveStore';
import { SearchTab } from './components/searchTab';
import { ResultsTab } from './components/resultsTab';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Button } from 'components/button';
import { DownloadIcon } from 'components/icons';
import { autorun, toJS } from 'mobx';

import { useStores } from 'config/hooks';

export const Archive = observer(() => {
  const {
    rootStore: { userStore }
  } = useStores();
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);
  const [popupInfo, setPopupInfo] = useState<{
    longitude: number;
    latitude: number;
    feature: any;
  } | null>(null);

  const getRasterCoordinatesFromGeoJSON = (bbox: number[]) => {
    return [
      [bbox[0], bbox[3]], // Top left
      [bbox[2], bbox[3]], // Top right
      [bbox[2], bbox[1]], // Bottom right
      [bbox[0], bbox[1]] // Bottom left
    ];
  };
  const getTokenFromStore = useCallback(async () => {
    const token = await userStore.auth0Client?.getTokenSilently();
    return token;
  }, [userStore]);

  useEffect(() => {
    if (mapRef) {
      //@ts-ignore
      archiveStore.setMaplibreMap(mapRef.getMap() as MaplibreMap);
      //@ts-ignore
      archiveStore.setMapRefStore(mapRef);
      // MapboxDraw requires the canvas's class order to have the class
      // "mapboxgl-canvas" first in the list for the key bindings to work
      mapRef.getCanvas().className = 'mapboxgl-canvas maplibregl-canvas';
      mapRef.getContainer().classList.add('mapboxgl-map');
      const canvasContainer = mapRef.getCanvasContainer();
      canvasContainer.classList.add('mapboxgl-canvas-container');
      if (canvasContainer.classList.contains('maplibregl-interactive')) {
        canvasContainer.classList.add('mapboxgl-interactive');
      }

      const initializeDrawControl = () => {
        if (!drawRef.current) {
          drawRef.current = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
              polygon: true,
              trash: true
            }
          });

          const originalOnAdd = drawRef.current.onAdd.bind(drawRef.current);
          drawRef.current.onAdd = map => {
            const controlContainer = originalOnAdd(map);
            controlContainer.classList.add('maplibregl-ctrl', 'maplibregl-ctrl-group');
            return controlContainer;
          };

          const map = mapRef.getMap();
          map.addControl(drawRef.current as unknown as IControl);
          archiveStore.setDrawRef(drawRef.current);

          map.on('draw.create', e => {
            const data = e.features[0];
            archiveStore.setDrawPolygon(data.geometry.coordinates);
          });
        }
      };

      initializeDrawControl();

      const handleMapClick = async (event: any) => {
        const { lng, lat } = event.lngLat;
        const token = await getTokenFromStore();
        if (!!archiveStore.searchResultsThumbnail) {
          archiveStore.searchResultsThumbnail.features.forEach(layer => {
            const [minLng, minLat, maxLng, maxLat] = layer.bbox;
            if (
              lng >= minLng &&
              lng <= maxLng &&
              lat >= minLat &&
              lat <= maxLat &&
              //Check if the clicked raster image tiles were already fetched
              layer.properties['satl:outcome_id'] !==
                archiveStore.activeThumbnail?.properties['satl:outcome_id']
            ) {
              archiveStore.setActiveThumbnail(layer);
              handleClickCapture(layer, token);
              return;
            }
          });
        }

        if (archiveStore.showMap && !!archiveStore.activeTilesData) {
          const { lngLat } = event;
          const map = mapRef?.getMap();
          const features = await map?.queryRenderedFeatures(event.point, {
            layers: ['tileCardLayer']
          });

          if (features && features.length > 0) {
            const clickedFeature = features.find((feature: any) => {
              return feature.layer.id === 'tileCardLayer';
            });
            if (clickedFeature) {
              setPopupInfo({
                longitude: lngLat.lng,
                latitude: lngLat.lat,
                feature: clickedFeature
              });
            }
          }
        }
      };

      const map = mapRef.getMap();

      map.on('click', (e: any) => handleMapClick(e));

      return () => {
        //@ts-ignore
        map.off('click', e => {
          handleMapClick(e);
        });
        if (mapRef && drawRef.current) {
          const map = mapRef.getMap() as MaplibreMap;
          archiveStore.removeDrawControl(map);
          drawRef.current = null;
        }
      };
    }
  }, [mapRef, getTokenFromStore]);

  let tabs: any = [
    {
      label: 'Search',
      content: <SearchTab />
    },
    {
      label: 'Results',
      content: <ResultsTab />
    }
  ];

  useEffect(() => {
    const disposer = autorun(() => {
      if (
        mapRef &&
        mapRef.getMap &&
        !!archiveStore.searchResultsThumbnail &&
        archiveStore.searchResultsThumbnail.features.length > 0
      ) {
        const map = mapRef.getMap();
        const firstFeature = archiveStore.searchResultsThumbnail.features[0];
        const bbox = firstFeature.bbox;
        const center: [number, number] = [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2];

        map.flyTo({
          center,
          zoom: 10,
          essential: true
        });
      }
    });

    return () => disposer();
  }, [mapRef]);

  return (
    <Layout>
      <BottomContainer>
        <LeftPane>
          <TopBarTitle>Archive</TopBarTitle>
          <div style={{ marginTop: '-1rem' }}>
            <TabsContainer tabs={tabs} />
          </div>
        </LeftPane>
        <MapContainer>
          <Map
            ref={ref => setMapRef(ref)}
            style={{ width: '100vw', height: '100vh' }}
            mapStyle="https://tiles.stadiamaps.com/styles/alidade_smooth.json"
            interactive
            initialViewState={{
              longitude: 0,
              latitude: 0,
              zoom: 1.75
            }}
            interactiveLayerIds={['tileCardLayer']}
          >
            {archiveStore.showMap && !!archiveStore.searchResultsThumbnail ? (
              <>
                {archiveStore.searchResultsThumbnail?.features.map(elem => {
                  return (
                    <Source
                      id={elem.id}
                      type="image"
                      url={elem.assets.preview.href}
                      //@ts-ignore
                      coordinates={getRasterCoordinatesFromGeoJSON(elem.bbox)}
                      key={`${elem.id}/${elem.properties.datetime}/${elem.properties['view:off_nadir']}`}
                    >
                      <Layer
                        id={`layer-${elem.id}`}
                        source={elem.id}
                        type="raster"
                        paint={{
                          'raster-fade-duration': 0
                        }}
                      />
                    </Source>
                  );
                })}
              </>
            ) : null}

            {archiveStore.showMap && !!archiveStore.activeTilesData ? (
              <>
                <Source type="geojson" id="searchResultData" data={archiveStore.activeTilesData}>
                  <Layer
                    id="tileCardLayer"
                    type="fill"
                    paint={{
                      'fill-color': '#54B3DB',
                      'fill-opacity': 0.05
                    }}
                  />
                  <Layer
                    id="outlineCard"
                    type="line"
                    paint={{
                      'line-color': '#000000',
                      'line-width': 0.25
                    }}
                  />
                  {popupInfo && (
                    <Popup
                      longitude={popupInfo.longitude}
                      latitude={popupInfo.latitude}
                      onClose={() => setPopupInfo(null)}
                      closeOnClick={false}
                      maxWidth="650px"
                    >
                      <PopUpContainer>
                        <h1>ID: {popupInfo.feature.properties.id}</h1>
                        <p>Outcome ID: {popupInfo.feature.properties['satl:outcome_id']} </p>
                        <p>Product: {popupInfo.feature.properties.platform}</p>
                        <p>Date: {transformDateTime(popupInfo.feature.properties.datetime)}</p>
                        <p>ONA deg: {popupInfo.feature.properties['view:off_nadir'].toFixed(2)}</p>
                        <p>Collection: {popupInfo.feature.properties['satl:product_name']}</p>
                        <p>
                          Collection's version:{' '}
                          {popupInfo.feature.properties['satl:product_version']}
                        </p>
                        <p>GSD: {popupInfo.feature.properties.gsd} </p>
                        <p>
                          Cloud cover: {popupInfo.feature.properties['eo:cloud_cover'].toFixed(1)} %
                        </p>
                        <p>
                          Sun elevation:{' '}
                          {popupInfo.feature.properties['view:sun_elevation'].toFixed(2)}{' '}
                        </p>
                        <p>
                          Sun azimuth: {popupInfo.feature.properties['view:sun_azimuth'].toFixed(1)}{' '}
                        </p>
                        <p className="downloads">Downloads</p>
                        <div className="buttons">
                          <a
                            href={popupInfo.feature.properties.download_preview}
                            rel="noopener noreferrer"
                            download
                            className="link"
                            style={{ textDecoration: 'none' }}
                          >
                            <Button
                              secondary
                              small
                              text="Image preview"
                              className="download"
                              icon={<DownloadIcon />}
                            />
                          </a>
                          <a
                            href={popupInfo.feature.properties.download_asset}
                            rel="noopener noreferrer"
                            download
                            className="link"
                            style={{ textDecoration: 'none' }}
                          >
                            <Button
                              secondary
                              small
                              text="Full resolution tiff"
                              className="download"
                              icon={<DownloadIcon />}
                            />
                          </a>
                          <a
                            href={popupInfo.feature.properties.download_cloud}
                            rel="noopener noreferrer"
                            download
                            className="link"
                            style={{ textDecoration: 'none' }}
                          >
                            <Button
                              secondary
                              small
                              text="Cloud mask"
                              className="download"
                              icon={<DownloadIcon />}
                            />
                          </a>
                        </div>
                      </PopUpContainer>
                    </Popup>
                  )}
                </Source>
              </>
            ) : null}

            {!!archiveStore.limitsSearchPolygon ? (
              <Source
                id="limitsSearchPolygon"
                type="geojson"
                data={archiveStore.limitsSearchPolygon}
              >
                <Layer
                  id="limitsSearchPolygon-dashed"
                  type="line"
                  paint={{
                    'line-color': '#002340',
                    'line-width': 2
                  }}
                />
              </Source>
            ) : null}
          </Map>
        </MapContainer>
      </BottomContainer>
    </Layout>
  );
});

Archive.displayName = 'Archive';
