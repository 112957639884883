import styled from '@emotion/styled/macro';

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ModalWindow = styled.div`
  background-color: ${(props: any) => props.theme.neutral80};
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateX(-50%);
  position: absolute;
  top: 10%;
  left: 50%;
`;

export const ModalBase = styled.div`
  width: 100%;
  height: 100%;
  z-index: 999999;
  position: absolute;
  top: 0;
`;
