import { model, Model, modelAction, prop } from 'mobx-keystone';
import { Props as ToastProps } from 'components/toast/toast';

@model('geodas/Notifications')
export class NotificationStore extends Model({
  notifications: prop<ToastProps[]>(() => []),
  bigNotifications: prop<ToastProps[]>(() => [])
}) {
  defaultCloseTime: number = 7000;
  nextId: number = 0;
  bigNextId: number = 0;

  @modelAction
  add(n: { description: string }) {
    const notis = this.notifications;
    if (this.notifications.length >= 4) notis.pop();
    this.notifications = [{ id: this.nextId, ...n }, ...notis];
    this.triggerClose(this.nextId);
    this.nextId++;
  }

  triggerClose(i: number) {
    setTimeout(() => {
      this.remove(i);
    }, this.defaultCloseTime);
  }

  @modelAction
  remove(i: number) {
    const filtered = this.notifications.filter((item: ToastProps) => item.id !== i);
    this.notifications = filtered;
  }

  @modelAction
  addBig(n: { description: string }) {
    const notis = this.bigNotifications;
    if (this.bigNotifications.length >= 4) notis.pop();
    this.bigNotifications = [{ id: this.bigNextId, ...n }, ...notis];
    this.triggerCloseBig(this.nextId);
    this.bigNextId++;
  }

  triggerCloseBig(i: number) {
    setTimeout(() => {
      this.removeBig(i);
    }, this.defaultCloseTime);
  }

  @modelAction
  removeBig(i: number) {
    const filtered = this.bigNotifications.filter((item: ToastProps) => item.id !== i);
    this.bigNotifications = filtered;
  }
}
