import React from 'react';
import { LoadingIcon } from 'components/icons';
import { BaseModal } from '../basemodal';
import { Button } from 'components/button'

import {
  Title, Text, ActionButtons, ModalContainer
} from './confirmationmodal.styles';

export interface ConfirmationModalProps {
  title: string;
  text: string;
  cancelText?: string;
  acceptText: string;
  onCancel?: () => void;
  onConfirm: (callback?: () => void) => void;
  isShown: boolean;
  onClose: () => void;
}

export const ConfirmationModal = ({ title, text, cancelText, acceptText, onCancel, onConfirm, isShown, onClose }: ConfirmationModalProps) => {

  const [status, setStatus] = React.useState<string>('');

  const onClickAccept = () => {
    setStatus("loading");
    onConfirm(() => setStatus(""))
  }

  return (
    <BaseModal isShown={isShown} hide={onClose}>
      <ModalContainer>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <ActionButtons>
          {cancelText && onCancel && <Button text={cancelText} onClick={onCancel} secondary />}
          <Button
            icon={status === 'loading' ? <LoadingIcon className="loading--icon" /> : undefined}
            text={acceptText}
            onClick={onClickAccept}
            disabled={status === "loading"}
          />
        </ActionButtons>
      </ModalContainer>
    </BaseModal>
  )
};

ConfirmationModal.displayName = "ConfirmationModal";
