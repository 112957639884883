import { styled, css } from 'config/common';
import { Link } from 'react-router-dom';

export const ToastDiv = styled.div`
  width: 100%;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.8);
  background-position: 15px;
  background-repeat: no-repeat;
  border-radius: 3px;
  color: ${(props: any) => props.theme.neutral};
  display: none;
  margin-top: 10px;
  overflow: hidden;
  padding: 15px;
  position: relative;

  &.open {
    display: flex;
  }
`;

export const SimpleDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const buttonStyle = css`
  background-color: transparent;
  border: 0;
  color: black;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  outline: none;
  padding: 0;
  text-transform: uppercase;
`;

export const ToastButton = styled.button`
  ${buttonStyle};

  color: ${(props: any) => props.theme.neutral};
  font-size: 12px;
  margin-right: 15px;
`;

export const ToastLink = styled(Link)`
  ${buttonStyle};

  color: ${(props: any) => props.theme.neutral};
  font-size: 12px;
  margin-right: 15px;
  text-decoration: none;
`;

/**export const ToastButton = styled.button`
  background: 0 0;
  border: none;
  color: ${(props: any) => props.theme.neutral};
  cursor: pointer;
  font-weight: 700;
  opacity: .8;
  outline: none;
  padding-top: 5px;
  position: absolute;
  right: .3em;
  top: .3em;
`;**/

export const ToastMessage = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

export const List = styled.div`
  width: 400px;
  max-width: 490px;
  display: flex;
  flex-direction: column;
  z-index: 9999999999;
  position: fixed;
  bottom: 20px;
  left: 20px;
`;
