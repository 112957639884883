import styled from '@emotion/styled';

export const MenuContainer = styled.div`
  position: relative;
  z-index: 2;
`;

export const ToggleElement = styled.div`

`;

export const MenuComponent = styled.div`
  z-index: 9999;
  position: absolute;
  opacity: 0;
  border-radius: 4px;
  border: 1px solid #E8E8E8;
  padding: 16px;
  background: white;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  &.showMenu {
    opacity: 1;
    pointer-events: all;
  }

  &.top-left {
    top: 0;
    left: 0;
    transform: translate(calc(-100% - 10px), calc(-100% + 20px))
  }
  &.top-left::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    bottom: 6px;
    right: -5px;
    border-style: solid;
    border-color: transparent transparent transparent white;
    border-width: 5px 0 5px 5px;
  }
  &.top-left::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    bottom: 6px;
    right: -6px;
    border-style: solid;
    border-color: transparent transparent transparent #e8e8e8;
    border-width: 5px 0 5px 5px;
  }

  &.top-right {
    top: 0;
    right: 0;
    transform: translate(calc(100% + 10px), calc(-100% + 20px))
  }
  &.top-right::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    bottom: 6px;
    left: -5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
    border-width: 5px 5px 5px 0;
  }
  &.top-right::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    bottom: 6px;
    left: -6px;
    border-style: solid;
    border-color: transparent #e8e8e8 transparent transparent;
    border-width: 5px 5px 5px 0;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(calc(-100% - 10px), calc(100% - 20px))
  }
  &.bottom-left::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    top: 6px;
    right: -5px;
    border-style: solid;
    border-color: transparent transparent transparent white;
    border-width: 5px 0 5px 5px;
  }
  &.bottom-left::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    top: 6px;
    right: -6px;
    border-style: solid;
    border-color: transparent transparent transparent #e8e8e8;
    border-width: 5px 0 5px 5px;
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
    transform: translate(calc(100% + 10px), calc(100% - 20px))
  }
  &.bottom-right::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    top: 6px;
    left: -5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
    border-width: 5px 5px 5px 0;
  }
  &.bottom-right::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    top: 6px;
    left: -6px;
    border-style: solid;
    border-color: transparent #e8e8e8 transparent transparent;
    border-width: 5px 5px 5px 0;
  }

  &.under {
    bottom: 0;
    left: 0;
    transform: translate(0, calc(100% + 10px))
  }
  &.under::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    top: -5px;
    left: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
    border-width: 0 5px 5px 5px;
  }
  &.under::before {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    top: -6px;
    left: 8px;
    border-style: solid;
    border-color: transparent transparent #e8e8e8 transparent;
    border-width: 0 5px 5px 5px;
  }
`

export const ActionsList = styled.ul`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  list-style-type: none;
`;

export const ActionItem = styled.li`
  width: 100%;
  align-items: center;
  color: ${(props: any) => props.theme.neutral80};
  cursor: pointer;
  flex-flow: row nowrap;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 10px;
  padding: 5px;
  transition: transform 0.1s linear;
  white-space: nowrap;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    transform: scale(1.1);
  }
  .item--link{
    align-items: center;
    color: ${(props: any) => props.theme.neutral80};
    display: flex;
    text-decoration: none;
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
`;
