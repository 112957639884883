import styled from '@emotion/styled/macro';



export const TaskAmount = styled.span`
  color: ${(props: any) => props.theme.neutral40};
  ${(props: any) => props.theme.small.styles};
`;

export const TaskColumn = styled.div`
  width: 100%;
  height: 100%;
  max-width: calc(((100% - 37%) / 5) - 10px);
  align-items: center;
  color: ${(props: any) => props.theme.neutral80};
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  ${(props: any) => props.theme.caption.styles};
  @media screen and (max-width: 1270px) {
    max-width: calc(((100% - 30%) / 5) - 10px);
  }
  &:nth-of-type(1) {
    width: 70px;
    margin-left: 15px;
  }
  &:nth-of-type(2) {
    width: 100%;
    max-width: 19%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .chevron--icon {
    cursor: pointer;
    margin-right: 20px;
  }
  &.red {
    color: ${(props: any) => props.theme.errorDark};
  }
  &.flex-start {
    justify-content: flex-start;
  }
  .arrow--up {
    margin-left: 5px;
  }
`;

export const TaskRow = styled.div`
  height: 48px;
  align-items: flex-start;
  border-bottom: 1px solid ${(props: any) => props.theme.neutral10};
  display: flex;
  justify-content: space-between;
  transition: background-color 0.3s linear;
  &:hover {
    &:not(.headers) {
      background-color: ${(props: any) => props.theme.neutral05};
      cursor: pointer;
    }
  }
  &.headers {
    ${TaskColumn} {
      font-weight: 600;
    }
  }
  &.active,
  &.active:hover {
    background-color: ${(props: any) => props.theme.backgroundPrimary};
  }
`;

export const TaskId = styled.span`
  align-items: center;
  background-color: ${(props: any) => props.theme.neutral10};
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral60};
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  line-height: 10px;
  padding: 4px;
`
