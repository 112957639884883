import { useState, useEffect, useContext, RefObject, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { rootContext } from '../contexts';

export function useMergeState<T>(initialState: T): [T, (newState: Partial<T>) => void, any] {
  const [state, setState] = useState(initialState);
  const dataRef: any = useRef(initialState);
  const setMergedState = (newState: Partial<T>) => {
    setState(prevState => {
      const mergeState = Object.assign({}, prevState, newState);
      dataRef.current = mergeState;
      return mergeState;
    });
  };
  return [state, setMergedState, dataRef];
}

export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => setIsShown(!isShown);
  return {
    isShown,
    toggle
  };
};

export const useStores = () => useContext(rootContext);

export const useQuery = () => new URLSearchParams(useLocation().search);

interface IntersectionObserverArgs extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

export function useIntersectionObserver(
  elementRef: RefObject<Element> | null,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false
  }: IntersectionObserverArgs
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
}

export function useAsyncReference<T>(value: any = undefined): [T, (newState: T) => void] {
  const ref: any = useRef(value);
  const [, forceRender] = useState(false);
  const updateState: any = (newState: T) => {
    if (!Object.is(ref.current, newState)) {
      ref.current = newState;
      forceRender(s => !s);
    }
  };
  return [ref.current as T, updateState];
}
