/* eslint-disable react/display-name */
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Tasking } from './views/tasking';
import { Playground } from './views/playground';
import { MyImages } from './views/images';
import { ProjectList } from './views/projects';
import { LoadingIcon } from 'components/icons';

import { PATHS, SCOPES } from 'config';
import { useStores } from 'config/hooks';
import { Archive } from 'views/archive';

type AuthRouteProps = {
  children: JSX.Element
  scope?: string;
};

const NoMatch = observer(() => {
  const location = useLocation()
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  )
});

const AuthRoute = observer(({ children, scope }: AuthRouteProps) => {
  const {
    rootStore: { userStore }
  } = useStores();

  const styles = {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
  
  if (!userStore.scope) {
    return (
      <React.Fragment>
        <div style={styles}>
          <LoadingIcon stroke="#002340" />
        </div>
      </React.Fragment>
    );
  }

  if (scope && !userStore.scope.includes(scope)) return <Navigate to='/' />;

  return children;
});

const NavigateToMain = () => {
  const {
    rootStore: { userStore }
  } = useStores();
  return (
    <Navigate to={userStore.scope.includes(SCOPES.TASKING) ? PATHS.PROJECTLIST : PATHS.IMAGES} />
  );
};

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={PATHS.IMAGES}
          element={
            <AuthRoute scope={SCOPES.DELIVERY}>
              <MyImages />
            </AuthRoute>
          }
        />
        <Route
          path={PATHS.PROJECTLIST}
          element={
            <AuthRoute scope={SCOPES.TASKING}>
              <ProjectList />
            </AuthRoute>
          }
        />
        <Route
          path={PATHS.TASKING}
          element={
            <AuthRoute scope={SCOPES.TASKING}>
              <Tasking />
            </AuthRoute>
          }
        />
        <Route
          path={PATHS.ROOT}
          element={
            <AuthRoute>
              <NavigateToMain />
            </AuthRoute>
          }
        />
        <Route
          path={PATHS.PLAYGROUND}
          element={
            <AuthRoute>
              <Playground />
            </AuthRoute>
          }
        />
        <Route
          path={PATHS.ARCHIVE}
          element={
            <AuthRoute scope={SCOPES.ARCHIVE}>
              <Archive />
            </AuthRoute>
          }
        />
        <Route element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
};
