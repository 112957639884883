import { model, Model, modelAction, tProp, prop, types } from 'mobx-keystone';
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import jwt_decode from 'jwt-decode';
import { config } from 'config/auth';
import { SCOPES, ARCHIVE_COLLECTIONS } from 'config';
import { getGeofence } from 'services';
import { mobxRootContext } from 'contexts';
import { computed, toJS } from 'mobx';
import { reGenerateGeofence } from 'views/tasking/helpers';

@model('geodas/User')
export class UserStore extends Model({
  geofence: prop(),
  scope: tProp(types.string, '')
}) {
  auth0Client: Auth0Client | undefined;

  regeneratedGeofence: number[][] | undefined;

  async onInit() {
    this.auth0Client = await this.createClient();
    this.handleRedirectCallback();
  }

  @modelAction
  setScope(scope: string) {
    this.scope = scope;
  }

  @modelAction
  setGeofence(g: any) {
    this.geofence = g;
  }

  async createClient() {
    var requested_scopes = `openid profile email ${SCOPES.TASKING} ${SCOPES.DELIVERY} ${SCOPES.ALEPH} ${SCOPES.ARCHIVE} ${SCOPES.ARCHIVE_RASTERS} ${ARCHIVE_COLLECTIONS.join(' ')}`;
    return await createAuth0Client({
      domain: config.domain,
      clientId: config.clientId,
      authorizationParams: {
        redirect_uri: window.location.href,
        scope: requested_scopes,
        audience: config.audience
      }
    });
  }

  @computed
  get detachedGeofence(){
    return toJS(this.geofence);
  }

  @computed
  get detachedRegeneratedGeofence(){
    return toJS(this.regeneratedGeofence);
  }


  async isAuthenticated() {
    await this.auth0Client?.isAuthenticated();
  }

  async handleRedirectCallback() {
    const isAuthenticated = await this.auth0Client?.isAuthenticated();
    if (!isAuthenticated) {
      const query = window.location.search;
      if (query.includes('code=') && query.includes('state=')) {
        try {
          await this.auth0Client?.handleRedirectCallback();
        } catch (error) {
          window.location.href = window.location.origin + window.location.pathname;
        }
        this.afterLogin();
      } else {
        await this.login();
      }
    } else {
      this.afterLogin();
    }
  }

  async afterLogin(){
    const token = await this.auth0Client?.getTokenSilently();
    const userData: {scope: string} = jwt_decode(token as string);
    this.setScope(userData.scope);
    if (userData.scope.includes(SCOPES.TASKING)){
      this.loadGeofence(token);
    }
  }

  async login() {
    await this.auth0Client?.loginWithRedirect();
  }

  async loadGeofence(token?: string) {
    if(!token) token = await this.auth0Client?.getTokenSilently();
    getGeofence(
      { token },
      ({ data }) => {
        if (data.geofence !== null) {
          let geofence = typeof data.geofence === 'object' ? data.geofence : JSON.parse(data.geofence);

          const newGeofence: number[][][] = [];
          newGeofence.push([
            [180, 90],
            [-180, 90],
            [-180, -90],
            [180, -90]
          ]);
          for (const l in geofence.coordinates) {
            newGeofence.push(geofence.coordinates[l][0]);
          }
          geofence.coordinates = [newGeofence];
          this.setGeofence(geofence);
          const g = reGenerateGeofence(geofence, true);
          g.shift();
          this.regeneratedGeofence = g;
        } else {
          this.setGeofence({});
        }
      },
      () => {
        mobxRootContext
          .get(this)
          ?.notificationStore.addBig({ description: 'Error while loading geofence' });
      }
    );
  }

  logout() {
    window.location.href = `https://login.satellogic.com/v2/logout?returnTo=${encodeURI(
      window.location.origin
    )}&client_id=${config.clientId}`;
  }
}
