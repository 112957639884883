import { kml } from "@tmcw/togeojson"

import { isGeometryInsidePolygon } from 'config/helpers';
import { TaskData } from 'typings';

export const getGeometry = (geoJSON: any, geoFenceCoordinates?: any) => {
  let error = false;
  let description = 'GeoJSON is not valid';
  let geometry: any = null;

  let polygon: any;

  // Is geoJson a KML file?
  const geojsonIsKml = geoJSON.includes('</kml>');
  if (geojsonIsKml) {
    const DOMParser = require('xmldom').DOMParser;
    const parsedKML = new DOMParser().parseFromString(geoJSON, 'utf8');
    //Convert KML to GeoJSON
    const format = kml(parsedKML);
    const kmlConverted = JSON.stringify(format);

    // Handle KML-JSON error
    try {
      polygon = JSON.parse(kmlConverted);
    } catch {
      return { error, description, geometry };
    }
  } else {
    // Handle JSON error
    try {
      polygon = JSON.parse(geoJSON);
    } catch {
      return { error, description, geometry };
    }
  }

  const isFeatureCollection = polygon.type === 'FeatureCollection';
  const isGeometryCollection = polygon.type === 'GeometryCollection';

  // Handle FeatureCollection
  if (isFeatureCollection) {
    const isEmptyCollection = polygon.features?.length === 0;
    const hasManyFeatures = polygon.features?.length > 1;

    if (isEmptyCollection) {
      error = true;
      return { error, description, geometry };
    }
    if (hasManyFeatures) {
      description = 'Only one feature is allowed';
      error = true;
      return { error, description, geometry };
    }
    geometry = polygon.features[0].geometry;
  }

  // Handle GeometryCollection
  if (isGeometryCollection) {
    const isEmptyCollection = polygon.geometries?.length === 0;
    const isMultipolygon = polygon.geometries?.length > 1;

    if (isEmptyCollection) {
      error = true;
      return { error, description, geometry };
    }
    if (isMultipolygon) {
      description = 'Multipolygons are not allowed';
      error = true;
      return { error, description, geometry };
    }
    geometry = polygon.geometries[0];
  }

  // Handle Single Feature
  if (polygon.type === 'Feature') {
    geometry = polygon.geometry;
  }

  // Handle Polygon
  if (polygon.type === 'Polygon') {
    geometry = polygon;
  }

  // Check for Multipolygon
  if (geometry?.type === 'MultiPolygon') {
    description = 'Multipolygons are not allowed';
    error = true;
    return { error, description, geometry };
  }

  // Check that Geometry has Coordinates
  if (!geometry?.coordinates) {
    description = 'GeoJSON is not valid';
    error = true;
    return { error, description, geometry };
  }

  // Check if there's a GeoFence and if Geometry is inside it
  if (
    geoFenceCoordinates &&
    !isGeometryInsidePolygon(geometry.coordinates[0], geoFenceCoordinates)
  ) {
    description = 'GeoJSON is outside of your allowed tasking area';
    error = true;
    return { error, description, geometry };
  }

  return { error, description, geometry };
};

export const validateData = (data: TaskData, productIsArea: boolean) => {
  let notification: { description: string } | undefined = undefined;
  let dataIsMissing = false;

  if (data.task_name === '') {
    dataIsMissing = true;
    notification = {
      description: 'Please provide a task name'
    };
  } else if (data.project_name === '') {
    dataIsMissing = true;
    notification = {
      description: 'Please select a project'
    };
  } else if (data.product === '-1') {
    dataIsMissing = true;
    notification = {
      description: 'Please select a product'
    };
  } else if (!data.target.type) {
    let description = productIsArea ? 'Please provide a GeoJSON' : 'Please provide one target';
    dataIsMissing = true;
    notification = { description };
  } else if (data.start === null || data.end === null) {
    dataIsMissing = true;
    notification = {
      description: 'Please provide valid dates'
    };
  }

  return { notification, dataIsMissing };
};

export const reGenerateGeofence = (geofence: any, invertCoords?: boolean) => {
  if (geofence?.coordinates) {
    return geofence?.coordinates[0].map((polygon: number[][]) =>
      polygon.map((coordinate: number[]) => [
        coordinate[invertCoords ? 1 : 0],
        coordinate[invertCoords ? 0 : 1]
      ])
    );
  }
  return undefined;
};

export const recurrence_options = [
  {value: 'Daily', text: ''},
  {value: 'Twice', text: ''},
  {value: 'Weekly', text: ''},
  {value: 'Twice', text: ''},
  {value: 'Monthly', text: ''},
]
