import styled from '@emotion/styled/macro';

export const FileUploadWrapper = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.3s linear;
  position: relative;
`;

export const StyledLabel = styled.label`
  width: auto;
  align-items: center;
  background: ${(props: any) => props.theme.neutral};
  border: 1px solid ${(props: any) => props.theme.neutral40};
  border-radius: 4px;
  color: ${(props: any) => props.theme.primary};
  cursor: pointer;
  display: flex;
  font-size: ${(props: any) => props.theme.sizes.small};
  font-weight: 500;
  justify-content: center;
  line-height: 20px;
  outline: 0;
  padding: 5px 10px;
  text-align: center;
  transition: all 0.2s linear;
  white-space: nowrap;
  .loading-icon {
    stroke: ${(props: any) => props.theme.primary};
  }
  .upload-icon {
    width: ${(props: any) => props.theme.sizes.xxlarge};
    height: ${(props: any) => props.theme.sizes.xxlarge};
    fill: ${(props: any) => props.theme.primary};
    margin-right: 5px;
    transition: fill 0.2s linear;
  }
  &:hover,
  &.isOver {
    background-color: ${(props: any) => props.theme.backgroundPrimary};
  }
  &.error {
    border-color: ${(props: any) => props.theme.error};
  }
  &.disabled {
    background: ${(props: any) => props.theme.neutral10};
    border: 1px solid ${(props: any) => props.theme.neutral40};
    color: ${(props: any) => props.theme.neutral40};
    cursor: not-allowed;
    .upload-icon {
      fill: ${(props: any) => props.theme.neutral40};
    }
    &:hover {
      background-color: ${(props: any) => props.theme.neutral10};
      border: 1px solid ${(props: any) => props.theme.neutral40};
      color: ${(props: any) => props.theme.neutral40};
      .upload-icon {
        fill: ${(props: any) => props.theme.neutral40};
      }
    }
  }
`;

export const UploadedFile = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: left;
  word-break: break-word;
  span {
    flex: 1 1 auto;
    padding-right: ${(props: any) => props.theme.sizes.medium};
  }
  .delete-icon {
    cursor: pointer;
    fill: ${(props: any) => props.theme.primary};
    flex: 0 0 auto;
    transition: fill 0.3s linear;
    &:hover {
      fill: ${(props: any) => props.theme.primary10};
    }
  }
`;

export const ErrorText = styled.span`
  color: ${(props: any) => props.theme.errorDark};
  display: block;
  ${(props: any) => props.theme.caption.styles};
  line-height: 20px;
`;

export const HelperText = styled.span`
  color: ${(props: any) => props.theme.neutral60};
  display: block;
  ${(props: any) => props.theme.caption.styles};
  line-height: 20px;
`;

export const BottomText = styled.div`
  max-height: 0;
  overflow: hidden;
  padding-top: 5px;
  transition: max-height 0.2s ease-in-out;
  &.shown {
    max-height: 40px;
  }
`;

export const HiddenInput = styled.input`
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
`;
