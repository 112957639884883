import React, { useState, useEffect } from 'react'

import { EyeIcon, EyeOffIcon, DownloadIcon, InfoIcon } from 'components/icons'
import { Button } from 'components/button'
import { Tooltip } from 'components/tooltip'
import { LayerItem } from 'components/layers/layers.store'

import {
  CaptureCardContainer,
  CaptureCardTitle,
  CaptureCardMetadata,
  Actions,
  ListItem,
  ItemTitle,
  ItemSubtitle,
  TaskId
} from './capturecard.styles'

import { PATHS } from 'config'
import { dateFormater, getTaskById } from 'config/helpers'
import { classes } from 'config/common'
import { useStores, useIntersectionObserver } from 'config/hooks'

interface SimpleProps {
  item: any
  name?: string
}

interface Props {
  buttons?: { onZoomIn?: () => void }
  capture: LayerItem
  className: string
  lazyLoad?: { use: boolean; callback?: () => void }
  visibility?: boolean
  onClick: () => void
}

export const SimpleCaptureCard = ({ item, name }: SimpleProps) => {
  return (
    <ListItem to={`${PATHS.IMAGES}?id=${encodeURI(item.capture_id)}`}>
      <ItemTitle>{name || item.capture_id}</ItemTitle>
      {item.start && <ItemSubtitle>Captured: {dateFormater(item.start)}</ItemSubtitle>}
    </ListItem>
  )
}

export const CaptureCard = ({
  buttons,
  capture,
  className,
  lazyLoad = { use: false },
  visibility,
  onClick
}: Props) => {
  const {
    rootStore: { dashboardStore, taskStore }
  } = useStores()

  const [isVisible, setIsVisible] = useState(false)
  const [downloadURL, setDownloadURL] = useState()

  const lazyLoadRef = React.useRef(null)

  const entry = useIntersectionObserver(lazyLoad.use ? lazyLoadRef : null, { freezeOnceVisible: true })

  const isOnScreen = !!entry?.isIntersecting
  const task = getTaskById(taskStore.taskNames, capture.metadata.metadata.task_id)

  useEffect(() => {
    if (isOnScreen && lazyLoad.callback) lazyLoad.callback()
    // eslint-disable-next-line
  }, [isOnScreen])

  useEffect(() => {
    if (visibility) setIsVisible(true)
  }, [visibility])

  const actions: any = [
    {
      button: <Button icon={<DownloadIcon />} onlyIcon clearIcon to={downloadURL} external />,
      position: 'bottom-center',
      content: 'Download',
      hidden: !downloadURL
    },
    // {
    //   button: (
    //     <Button
    //       icon={<CirclePlus stroke="#002340" />}
    //       onlyIcon
    //       clearIcon
    //       onClick={(e: any) => {
    //         e.stopPropagation()
    //         if (!isVisible ) setIsVisible(true)
    //         if(buttons?.onZoomIn) buttons?.onZoomIn()
    //       }}
    //     />
    //   ),
    //   position: 'bottom-center',
    //   content: 'Zoom in'
    // }
  ]

  if (capture.getOLItem()) {
    actions.push({
      button: (
        <Button
          icon={<InfoIcon />}
          onlyIcon
          clearIcon
          onClick={(e: any) => {
            e.stopPropagation()
            dashboardStore.showMetadataModal(true, capture)
          }}
        />
      ),
      position: 'bottom-center',
      content: 'Show metadata'
    })
    actions.push({
      button: (
        <Button
          icon={isVisible ? <EyeIcon /> : <EyeOffIcon />}
          onlyIcon
          clearIcon
          onClick={(e: any) => {
            e.stopPropagation()
            capture.setVisibility(!isVisible)
            setIsVisible(!isVisible)
          }}
        />
      ),
      position: 'under-left',
      content: `${isVisible ? 'Hide' : 'Show'} capture`
    })
  }


  return (
    <CaptureCardContainer
      key={capture.layerId}
      className={className}
      ref={lazyLoadRef}
      onClick={() => {
        // mapStore.zoomToLayer(capture);
        onClick();
        setIsVisible(true);
      }}
      onMouseEnter={() => {
        if (!downloadURL) {
          dashboardStore.fetchDownload(capture, (response: any) =>
            setDownloadURL(response.data.url)
          )
        }
      }}
    >
      <Actions>
        {actions.map((action: any) => {
          return (
            <Tooltip
              className={classes('action', { hidden: action.hidden })}
              key={action.content}
              toggleElement={action.button}
              position={action.position}
              content={action.content}
            />
          );
        })}
      </Actions>
      <CaptureCardTitle>{capture.metadata.scene_id}</CaptureCardTitle>

      {capture.metadata ? (
        <CaptureCardMetadata>
          Captured: {dateFormater(capture.metadata.timestamp)}
        </CaptureCardMetadata>
      ) : null}

      {task?.task_name || capture.metadata.metadata.task_id ? (
      <CaptureCardMetadata>
        Task: {task?.task_name} <TaskId>{capture.metadata.metadata.task_id}</TaskId>
      </CaptureCardMetadata>
      ) : null}

      {task?.project_name || capture.metadata.metadata.project_id ? (
      <CaptureCardMetadata>
        Project: {task?.project_name}  <TaskId>{capture.metadata.metadata.project_id}</TaskId>
      </CaptureCardMetadata>
      ) : null}

    </CaptureCardContainer>
  );
};

SimpleCaptureCard.displayName = 'SimpleCaptureCard'
CaptureCard.displayName = 'CaptureCard'
