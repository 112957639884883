import { styled } from 'config/common';
import { Link } from 'react-router-dom';

export const StyledButton = styled.button`
  height: 48px;
  align-items: center;
  background-color: ${(props: any) => props.theme.primary};
  border: none;
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral};
  cursor: pointer;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-size: ${(props: any) => props.theme.sizes.medium};
  font-weight: 400;
  justify-content: center;
  line-height: 20px;
  outline: 0;
  padding: ${(props: any) => `${props.theme.sizes.xsmall} ${props.theme.sizes.medium}`};
  transition: background-color 0.2s linear, color 0.2s linear;
  white-space: nowrap;
  svg {
    width: ${(props: any) => props.theme.sizes.medium};
    height: ${(props: any) => props.theme.sizes.medium};
    fill: ${(props: any) => props.theme.neutral};
    margin: 0 12px 0 0;
    transition: fill 0.2s linear;
  }
  &:hover {
    background-color: ${(props: any) => props.theme.primary20};
  }
  &.secondary {
    background: transparent;
    border: 1px solid ${(props: any) => props.theme.primary};
    color: ${(props: any) => props.theme.primary};
    svg {
      fill: ${(props: any) => props.theme.primary};
    }
    &:hover {
      background-color: ${(props: any) => props.theme.primary};
      border: 1px solid ${(props: any) => props.theme.neutral};
      color: ${(props: any) => props.theme.neutral};
      svg {
        fill: ${(props: any) => props.theme.neutral};
      }
    }
  }
  &.small {
    height: 40px;
    padding: ${(props: any) => `0 ${props.theme.sizes.medium}`};
    ${(props: any) => props.theme.small.styles};
    svg {
      width: ${(props: any) => props.theme.sizes.small};
      height: ${(props: any) => props.theme.sizes.small};
    }
  }
  &.onlyText {
    background: none;
    border: none;
    color: ${(props: any) => props.theme.secondary};
    svg {
      fill: ${(props: any) => props.theme.secondary};
    }
    &:hover {
      color: ${(props: any) => props.theme.primary};
      svg {
        fill: ${(props: any) => props.theme.primary};
      }
    }
    &.disabled {
      background: transparent;
      border-color: transparent;
      &:hover {
        background: transparent;
        border-color: transparent;
      }
    }
  }
  &.disabled {
    background: ${(props: any) => props.theme.neutral10};
    border: 1px solid ${(props: any) => props.theme.neutral40};
    color: ${(props: any) => props.theme.neutral40};
    cursor: not-allowed;
    svg {
      fill: ${(props: any) => props.theme.neutral40};
    }
    &:hover {
      background-color: ${(props: any) => props.theme.neutral10};
      border: 1px solid ${(props: any) => props.theme.neutral40};
      color: ${(props: any) => props.theme.neutral40};
      svg {
        fill: ${(props: any) => props.theme.neutral40};
      }
    }
  }
  &.fab {
    width: 125px;
    height: 40px;
    align-items: center;
    background-color: ${(props: any) => props.theme.primary};
    border: none;
    border-radius: 20px;
    color: ${(props: any) => props.theme.neutral};
    display: flex;
    font-size: ${(props: any) => props.theme.sizes.small};
    font-weight: 400;
    justify-content: flex-start;
    outline: none;
    padding: 0 10px;
    text-transform: uppercase;
    svg {
      fill: none;
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.neutral10};
      color: ${(props: any) => props.theme.neutral40};
      svg {
        stroke: ${(props: any) => props.theme.neutral40};
      }
      &:hover {
        border: none;
        svg {
          fill: none;
        }
      }
    }
  }
  &.icon {
    width: 52px;
    height: 52px;
    background-color: ${(props: any) => props.theme.primary};
    border: none;
    border-radius: 50%;
    padding: 0;
    svg {
      width: 19px;
      height: 19px;
      margin: 0;
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.neutral10};
      border: none;
    }
    &.small {
      width: 40px;
      height: 40px;
      svg {
        width: 13px;
        height: 13px;
      }
    }
    &.clearIcon {
      width: auto;
      height: auto;
      background-color: transparent;
      padding: 5px;
      svg {
        fill: ${(props: any) => props.theme.primary};
      }
    }
  }
  &.rightIcon {
    flex-direction: row-reverse;
    svg {
      margin: 0 0 0 12px;
    }
  }
`;

export const StyledLink = styled(Link)`
  height: 48px;
  align-items: center;
  background-color: ${(props: any) => props.theme.primary};
  border: none;
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral};
  cursor: pointer;
  display: flex;
  font-size: ${(props: any) => props.theme.sizes.medium};
  font-weight: 400;
  justify-content: center;
  line-height: 20px;
  outline: 0;
  padding: ${(props: any) => `${props.theme.sizes.xsmall} ${props.theme.sizes.medium}`};
  text-decoration: none;
  transition: background-color 0.2s linear, color 0.2s linear;
  white-space: nowrap;
  svg {
    fill: ${(props: any) => props.theme.neutral};
    margin: 0 12px 0 0;
    transition: fill 0.2s linear;
  }
  &:hover {
    background-color: ${(props: any) => props.theme.primary20};
  }
  &.secondary {
    background: transparent;
    border: 1px solid ${(props: any) => props.theme.primary};
    color: ${(props: any) => props.theme.primary};
    svg {
      fill: ${(props: any) => props.theme.primary};
    }
    &:hover {
      background-color: ${(props: any) => props.theme.primary};
      border: 1px solid ${(props: any) => props.theme.neutral};
      color: ${(props: any) => props.theme.neutral};
      svg {
        fill: ${(props: any) => props.theme.neutral};
      }
    }
  }
  &.small {
    height: 40px;
    padding: ${(props: any) => `0 ${props.theme.sizes.medium}`};
    ${(props: any) => props.theme.small.styles};
  }
  &.onlyText {
    background: none;
    border: none;
    color: ${(props: any) => props.theme.secondary};
    svg {
      fill: ${(props: any) => props.theme.secondary};
    }
    &:hover {
      color: ${(props: any) => props.theme.primary};
      svg {
        fill: ${(props: any) => props.theme.primary};
      }
    }
    &.disabled {
      background: transparent;
      border-color: transparent;
      &:hover {
        background: transparent;
        border-color: transparent;
      }
    }
  }
  &.disabled {
    background: ${(props: any) => props.theme.neutral10};
    border: 1px solid ${(props: any) => props.theme.neutral40};
    color: ${(props: any) => props.theme.neutral40};
    cursor: not-allowed;
    svg {
      fill: ${(props: any) => props.theme.neutral40};
    }
    &:hover {
      background-color: ${(props: any) => props.theme.neutral10};
      border: 1px solid ${(props: any) => props.theme.neutral40};
      color: ${(props: any) => props.theme.neutral40};
      svg {
        fill: ${(props: any) => props.theme.neutral40};
      }
    }
  }
  &.fab {
    width: 125px;
    height: 40px;
    align-items: center;
    background-color: ${(props: any) => props.theme.primary};
    border: none;
    border-radius: 20px;
    color: ${(props: any) => props.theme.neutral};
    display: flex;
    font-size: ${(props: any) => props.theme.sizes.small};
    font-weight: 400;
    justify-content: flex-start;
    outline: none;
    padding: 0 10px;
    text-transform: uppercase;
    svg {
      fill: none;
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.neutral10};
      color: ${(props: any) => props.theme.neutral40};
      svg {
        stroke: ${(props: any) => props.theme.neutral40};
      }
      &:hover {
        border: none;
        svg {
          fill: none;
        }
      }
    }
  }
  &.icon {
    width: 52px;
    height: 52px;
    background-color: ${(props: any) => props.theme.primary};
    border: none;
    border-radius: 50%;
    padding: 0;
    svg {
      width: 19px;
      height: 19px;
      margin: 0;
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.neutral10};
      border: none;
    }
    &.small {
      width: 40px;
      height: 40px;
      svg {
        width: 13px;
        height: 13px;
      }
    }
    &.clearIcon {
      width: auto;
      height: auto;
      background-color: transparent;
      padding: 5px;
      svg {
        fill: ${(props: any) => props.theme.primary};
      }
    }
  }
  &.rightIcon {
    flex-direction: row-reverse;
    svg {
      margin: 0 0 0 12px;
    }
  }
`;

export const StyledExternalLink = styled.a`
  height: 48px;
  align-items: center;
  background-color: ${(props: any) => props.theme.primary};
  border: none;
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral};
  cursor: pointer;
  display: flex;
  font-size: ${(props: any) => props.theme.sizes.medium};
  font-weight: 400;
  justify-content: center;
  line-height: 20px;
  outline: 0;
  padding: ${(props: any) => `${props.theme.sizes.xsmall} ${props.theme.sizes.medium}`};
  text-decoration: none;
  transition: background-color 0.2s linear, color 0.2s linear;
  white-space: nowrap;
  svg {
    fill: ${(props: any) => props.theme.neutral};
    margin: 0 12px 0 0;
    transition: fill 0.2s linear;
  }
  &:hover {
    background-color: ${(props: any) => props.theme.primary20};
  }
  &.secondary {
    background: transparent;
    border: 1px solid ${(props: any) => props.theme.primary};
    color: ${(props: any) => props.theme.primary};
    svg {
      fill: ${(props: any) => props.theme.primary};
    }
    &:hover {
      background-color: ${(props: any) => props.theme.primary};
      border: 1px solid ${(props: any) => props.theme.neutral};
      color: ${(props: any) => props.theme.neutral};
      svg {
        fill: ${(props: any) => props.theme.neutral};
      }
    }
  }
  &.small {
    height: 40px;
    padding: ${(props: any) => `0 ${props.theme.sizes.medium}`};
    ${(props: any) => props.theme.small.styles};
  }
  &.onlyText {
    background: none;
    border: none;
    color: ${(props: any) => props.theme.secondary};
    svg {
      fill: ${(props: any) => props.theme.secondary};
    }
    &:hover {
      color: ${(props: any) => props.theme.primary};
      svg {
        fill: ${(props: any) => props.theme.primary};
      }
    }
    &.disabled {
      background: transparent;
      border-color: transparent;
      &:hover {
        background: transparent;
        border-color: transparent;
      }
    }
  }
  &.disabled {
    background: ${(props: any) => props.theme.neutral10};
    border: 1px solid ${(props: any) => props.theme.neutral40};
    color: ${(props: any) => props.theme.neutral40};
    cursor: not-allowed;
    svg {
      fill: ${(props: any) => props.theme.neutral40};
    }
    &:hover {
      background-color: ${(props: any) => props.theme.neutral10};
      border: 1px solid ${(props: any) => props.theme.neutral40};
      color: ${(props: any) => props.theme.neutral40};
      svg {
        fill: ${(props: any) => props.theme.neutral40};
      }
    }
  }
  &.fab {
    width: 125px;
    height: 40px;
    align-items: center;
    background-color: ${(props: any) => props.theme.primary};
    border: none;
    border-radius: 20px;
    color: ${(props: any) => props.theme.neutral};
    display: flex;
    font-size: ${(props: any) => props.theme.sizes.small};
    font-weight: 400;
    justify-content: flex-start;
    outline: none;
    padding: 0 10px;
    text-transform: uppercase;
    svg {
      fill: none;
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.neutral10};
      color: ${(props: any) => props.theme.neutral40};
      svg {
        stroke: ${(props: any) => props.theme.neutral40};
      }
      &:hover {
        border: none;
        svg {
          fill: none;
        }
      }
    }
  }
  &.icon {
    width: 52px;
    height: 52px;
    background-color: ${(props: any) => props.theme.primary};
    border: none;
    border-radius: 50%;
    padding: 0;
    svg {
      width: 19px;
      height: 19px;
      margin: 0;
    }
    &.disabled {
      background-color: ${(props: any) => props.theme.neutral10};
      border: none;
    }
    &.small {
      width: 40px;
      height: 40px;
      svg {
        width: 13px;
        height: 13px;
      }
    }
    &.clearIcon {
      width: auto;
      height: auto;
      background-color: transparent;
      padding: 5px;
      svg {
        fill: ${(props: any) => props.theme.primary};
      }
    }
  }
  &.rightIcon {
    flex-direction: row-reverse;
    svg {
      margin: 0 0 0 12px;
    }
  }
`;
