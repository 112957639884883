import React from 'react'
import { Polygon } from 'ol/geom'
import { getCenter } from 'ol/extent'

import { Button } from 'components/button'
import {
  CrossIcon,
  LoadingIcon,
  PinIconModal,
  PinIconFilled,
  SatelliteIcon,
  ArrowIcon,
  CalendarIconModal
} from 'components/icons'
import { BaseModal } from '../basemodal'

import { dateFormater, coordinatesFormater, getPreviewImage, repeatingPeriods } from 'config/helpers'
import { TaskData } from 'typings'

import {
  ModalDiv,
  Map,
  ModalInfo,
  ImageOverlay,
  RowText,
  ProjectText,
  TaskText,
  Label,
  ButtonWrapper,
  Divider
} from './previewtargetmodal.styles';
import { AreaIcon, RecurrenceIcon } from 'components/icons/icons'

interface Props {
  className?: string;
  body: TaskData;
  isShown: boolean;
  onClose: () => void;
  onAccept: () => void;
  isArea?: boolean;
  targetArea?: number;
}

export const PreviewTargetModal = ({
  className,
  isShown,
  onClose,
  body,
  onAccept,
  isArea = true,
  targetArea
}: Props) => {
  const [status, setStatus] = React.useState('');

  const customAccept = () => {
    setStatus('loading');
    onAccept();
  };

  let areaCenter: any

  if (isArea) {
    const latLng = getCenter(new Polygon(body.target?.coordinates as any).getExtent())
    areaCenter = { lat: latLng[0], lng: latLng[1] }
  }

  const previewImage = getPreviewImage(
    isArea && areaCenter
      ? [areaCenter.lat, areaCenter.lng]
      : [body.target.coordinates[1], body.target.coordinates[0]],
    { width: 400, height: 350, zoom: 10 },
    isArea ? body.target : undefined
  );

  return (
    <BaseModal isShown={isShown} hide={onClose}>
      <ModalDiv className={className}>
        <Map
          style={{
            backgroundImage: `url("${previewImage}")`
          }}
        >
          <ImageOverlay />
          {!isArea && <PinIconFilled className="pin--icon" />}
        </Map>
        <ModalInfo>
          <CrossIcon onClick={onClose} className="cross--icon" />
          <RowText>
            <ProjectText>{body.project_name}</ProjectText>
          </RowText>
          <RowText>
            <TaskText>{body.task_name}</TaskText>
          </RowText>
          <RowText>
            <SatelliteIcon /> <Label>{body.product_text}</Label>
          </RowText>
          <Divider />
          {isArea ? (
            <RowText>
              <AreaIcon />
              <Label><span>{targetArea} km<sup>2</sup></span></Label>
            </RowText>
          ) : (
            <RowText>
              <PinIconModal />
              <Label>{coordinatesFormater(body.target.coordinates)}</Label>
            </RowText>
          )}
          {body.start && (
            <RowText>
              <CalendarIconModal />
              <Label>{dateFormater(body.start)}</Label>
              {!!body.end && (
                <>
                  <ArrowIcon className="arrow--icon" />
                  <Label>{dateFormater(body.end)}</Label>
                </>
              )}
            </RowText>
          )}

          <RowText>
            <RecurrenceIcon />
            {body.expected_age ?
              <Label>Recurrence: {repeatingPeriods.find(item => item.value === body.expected_age)?.text}</Label>
              :
              <Label>Recurrence: Once</Label>
            }

          </RowText>

          <ButtonWrapper>
            <Button text="Cancel Task" onClick={onClose} secondary />
            <Button
              onClick={customAccept}
              disabled={status === 'loading'}
              icon={status === 'loading' ? <LoadingIcon /> : undefined}
              text={status !== 'loading' ? 'Confirm Task' : undefined}
              rightIcon
            />
          </ButtonWrapper>
        </ModalInfo>
      </ModalDiv>
    </BaseModal>
  );
};

PreviewTargetModal.displayName = 'PreviewTargetModal';
