import { Link } from 'react-router-dom'
import styled from '@emotion/styled/macro';

export const Actions = styled.div`
  width: 37px;
  align-items: center;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%);
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  transition: background-color 0.2s linear, width 0.3s linear;
  position: absolute;
  top: 3px;
  right: 3px;
  .action {
    width: 30px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity 0.2s linear;
    &:last-of-type {
      opacity: 1;
    }
    button {
      transition: transform 0.2s linear;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
`

export const CaptureCardContainer = styled.div`
  width: 100%;
  border: 1px solid ${(props: any) => props.theme.neutral20};
  border-radius: 2px;
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.2s linear;
  position: relative;
  &.active {
    background-color: ${(props: any) => props.theme.neutral05};
    ${Actions} {
      background: linear-gradient(to right, rgba(242, 242, 242, 0) 0%, ${(props: any) => props.theme.neutral05} 50%);
    }
  }
  &:hover {
    background-color: ${(props: any) => props.theme.neutral05};
    ${Actions} {
      width: 100%;
      background: linear-gradient(to right, rgba(242, 242, 242, 0) 0%, ${(props: any) => props.theme.neutral05} 50%);
      overflow: visible;
      .action {
        opacity: 1;
        &.hidden {
          opacity: 0;
        }
      }
    }
  }
`

export const CaptureCardTitle = styled.h1`
  width: calc(100% - 25px);
  ${(props: any) => props.theme.small.styles}
  align-items: center;
  color: ${(props: any) => props.theme.neutral80};
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`


export const TaskId = styled.span`
  align-items: center;
  background-color: ${(props: any) => props.theme.neutral10};
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral60};
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  line-height: 10px;
  margin-left: 8px;
  padding: 4px;
`

export const CaptureCardMetadata = styled.span`
  align-items: center;
  color: ${(props: any) => props.theme.neutral60};
  display: flex;
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  font-weight: 500;
  line-height: 20px;
`

export const ListItem = styled(Link)`
  width: 90%;
  border: 1px solid ${(props: any) => props.theme.neutral20};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 10px;
  text-decoration: none;
  transition: background-color .2s ease-out;
  position: relative;
  &.active{
    background-color: ${(props: any) => props.theme.neutral05};
  }
  & + & {
    margin-top: 10px;
  }
  .dots--menu{
    position: absolute;
    top: 10px;
    right: 15px;
  }
  .zoom-in--icon{
    width: 16px;
    height: 16px;
    path{
      stroke: ${(props: any) => props.theme.primary};
    }
  }
  &:hover {
    background-color: ${(props: any) => props.theme.neutral05};
  }
`

export const ItemTitle = styled.h6`
  ${(props: any) => props.theme.small.styles};
  width: calc(100% - 20px);
  color: ${(props: any) => props.theme.neutral80};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ItemSubtitle = styled.p`
  color: ${(props: any) => props.theme.neutral60};
  ${(props: any) => props.theme.caption.styles};
  line-height: 20px;
`

export const MenuButton = styled.button`
  width: 20px;
  height: 20px;
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  outline: 0;
  padding: 0;
`
