import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { classes } from 'config/common'
import { Action } from 'typings'

import {
  MenuContainer,
  ToggleElement,
  MenuComponent,
  ActionsList,
  ActionItem,
} from './actionmenu.styles';

interface MenuProps {
  className?: string;
  menuClassName?: string;
  toggleElement: JSX.Element
  position: string
  actions: Array<Action>
}

export const ActionMenu = ({ className, toggleElement, position, actions, menuClassName }: MenuProps) => {
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => { setShowMenu(!showMenu) }

  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <MenuContainer className={classes(className)} ref={menuRef}>
      <ToggleElement onClick={toggleMenu}>
        {toggleElement}
      </ToggleElement>

      <MenuComponent className={classes(position, menuClassName, { showMenu })}>
        <ActionsList>
          {actions.map(({ icon, text, action, to }, index) => {
            if (to) return (
              <ActionItem key={index}>
                <Link to={to} className="item--link">
                  {icon} {text}
                </Link>
              </ActionItem>
            )
            return (
              <ActionItem onClick={action} key={index}>
                {icon} {text}
              </ActionItem>
            )
          })}
        </ActionsList>
      </MenuComponent>
    </MenuContainer>
  )
};

ActionMenu.displayName = "ActionMenu";
