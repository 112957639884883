import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import {
  ModalOverlay, ModalWindow, ModalBase
} from './modal.styles';

export interface ModalProps {
  className?: string;
  isShown: boolean;
  hide: () => void;
  children?: React.ReactNode;
}

export const Modal = ({ className, isShown, hide, children }: ModalProps) => {

  const customHide = () => {
    document.body.style.overflow = "auto";
    hide();
  }

  const modal: any = (
    <ModalBase>
      <ModalOverlay onClick={customHide} />
      <ModalWindow className={className} role="dialog">
        {children}
      </ModalWindow>
    </ModalBase>
  );

  const onKeyDown = (event: KeyboardEvent) => {
    isShown ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');
    if (event.keyCode === 27 && isShown) {
      customHide();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  });

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

Modal.displayName = 'Modal';
