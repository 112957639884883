import React, { useContext, useEffect } from 'react';
import { MapContext } from '../mapContext';

import * as ol from 'ol';

import { PopupContainer } from './popup.styles';

import { classNames } from 'config/helpers';

interface PropsFromLayer {
  children?: React.ReactNode;
  position?: number[];
  className?: string;
  autoPan?: boolean;
  show?: boolean;
}

export const Popup = ({
  children,
  position,
  className,
  autoPan = false,
  show = true
}: PropsFromLayer) => {
  const { map } = useContext(MapContext);
  const ref = React.useRef<HTMLDivElement>(null);
  const [overlay, setOverlay] = React.useState<ol.Overlay>();

  useEffect(() => {
    if (!map) return;

    const localPopup = new ol.Overlay({
      element: ref.current as HTMLElement,
      autoPan: autoPan,
      position
    });

    map.addOverlay(localPopup);
    setOverlay(localPopup);

    const close: HTMLElement = document.querySelector('.close--btn') as HTMLElement;
    if (close) {
      close.onclick = function() {
        localPopup.setPosition(undefined);
        close.blur();
        return false;
      };
    }

    return () => {
      try {
        if (map) map.removeOverlay(localPopup);
      } catch (error) {}
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  // Position handler
  useEffect(() => {
    if (!map) return;
    overlay?.setPosition(position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return (
    <PopupContainer ref={ref} className={classNames(className, { show })}>
      {children}
    </PopupContainer>
  );
};

Popup.displayName = 'Popup';
