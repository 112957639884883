import React from 'react';
import * as ol from 'ol';

interface Props {
  map?: ol.Map;
  vectorLayer?: any;
  drawVectorLayer?: any;
}

export const MapContext = React.createContext<Props>({
  map: undefined,
  vectorLayer: undefined,
  drawVectorLayer: undefined
});
