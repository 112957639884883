import React from 'react';
import { StatusLabel } from 'components/statuslabel';

import {
  TaskRow,
  TaskColumn,
  TaskId
} from './taskitem.styles';

import { dateFormater } from 'config/helpers';
import { Task, Product } from 'typings';

interface Props {
  onTaskClick: (i: number) => void;
  task: Task;
  products: Product[];
  className?: string;
}

export const TaskItem = ({
  task,
  onTaskClick,
  products,
  className,
}: Props) => {

  const productMatcher = (pk: number) => products.find(i => i.pk === pk)?.name;

  return (
    <TaskRow
      className={className}
      onClick={() => onTaskClick(parseInt(task.task_id))}
    >
      <TaskColumn><TaskId>{task.task_id}</TaskId></TaskColumn>
      <TaskColumn>{task.task_name}</TaskColumn>
      <TaskColumn>{task.project_name}</TaskColumn>
      <TaskColumn>
        {task.status && (
          <StatusLabel small={true} status={task.status} tooltip={task.status_details?.[0]} />
        )}
      </TaskColumn>
      <TaskColumn>{task.product && productMatcher(task.product)}</TaskColumn>
      <TaskColumn>{task.start ? dateFormater(task.start) : '-'}</TaskColumn>
      <TaskColumn>{task.end ? dateFormater(task.end) : '∞'}</TaskColumn>
    </TaskRow>
  );
};

TaskItem.displayName = 'Task Item';
