import styled from '@emotion/styled/macro';

export const TasksList = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 145px;
  padding-top: 50px;
  transition: all 0.3s ease-in-out;
  &.topBarExpanded {
    margin-top: 201px;
  }
  .container {
    margin-bottom: 60px;
  }
`;

export const TaskDetailWrapper = styled.div`
  width: 350px;
  height: calc(100vh - 151px);
  z-index: 9999;
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 151px;
  right: -350px;
  &.active {
    right: 0;
  }
  &.shadow {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  &.topBarExpanded {
    top: 207px;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 40px 0 0;
  svg {
    stroke: ${(props: any) => props.theme.primary};
  }
`;

export const TasksTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableColumn = styled.div`
  width: 100%;
  height: 100%;
  max-width: calc(((100% - 37%) / 5) - 10px);
  align-items: center;
  color: ${(props: any) => props.theme.neutral80};
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  ${(props: any) => props.theme.caption.styles};
  @media screen and (max-width: 1270px) {
    max-width: calc(((100% - 30%) / 5) - 10px);
  }
  &:nth-of-type(1) {
    width: 70px;
    margin-left: 15px;
  }
  &:nth-of-type(2) {
    width: 100%;
    max-width: 19%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .chevron--icon {
    cursor: pointer;
    margin-right: 20px;
  }
  &.red {
    color: ${(props: any) => props.theme.errorDark};
  }
  &.flex-start {
    justify-content: flex-start;
  }
  .arrow--up {
    margin-left: 5px;
  }
`;
export const ArrowSort = styled.span`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid ${(props: any) => props.theme.neutral60};
  display: table;
  opacity: 0;
  margin: 0 auto 0 10px;
  transition: opacity 0.2s ease-in-out;
  &.up {
    transform: rotate(180deg);
  }
`;

export const TableRow = styled.div`
  height: 48px;
  align-items: flex-start;
  border-bottom: 1px solid ${(props: any) => props.theme.neutral10};
  display: flex;
  justify-content: space-between;
  transition: background-color 0.3s linear;
  &:hover {
    &:not(.headers) {
      background-color: ${(props: any) => props.theme.neutral05};
      cursor: pointer;
    }
  }
  &.headers {
    &.disabled {
      pointer-events: none;
    }
    ${TableColumn} {
      font-weight: 600;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:hover {
        cursor: pointer;
        ${ArrowSort} {
          opacity: 1;
        }
      }
      &.no--sort {
        cursor: default;
      }
    }
  }
  &.active,
  &.active:hover {
    background-color: ${(props: any) => props.theme.backgroundPrimary};
  }
`;

export const PagMoreWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  ${LoadingWrapper} {
    width: auto;
    transform: scale(0.5);
    padding: 0;
    position: relative;
    top: 1px;
  }
`;

export const More = styled.span`
  align-items: center;
  display: flex;
  position: relative;
  top: 1px;
`;

export const MoreText = styled.span`
  color: ${(props: any) => props.theme.neutral80};
  display: block;
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  font-weight: 500;
  white-space: nowrap;
`;

export const Paginator = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const RowsPerPage = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`;

export const PageItem = styled.div`
  width: 100%;
  color: ${(props: any) => props.theme.neutral80};
  display: block;
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  font-weight: 500;
  padding: 5px 5px 5px 10px;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${(props: any) => props.theme.neutral05};
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const PageList = styled.div`
  width: 100%;
  min-width: 45px;
  background-color: ${(props: any) => props.theme.neutral};
  border-radius: 3px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: 0 0;
  position: absolute;
  left: 0;
  bottom: -33px;
`;

export const PageDrop = styled.div`
  align-items: center;
  border-radius: 3px;
  color: ${(props: any) => props.theme.neutral80};
  display: flex;
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  font-weight: 500;
  margin-left: 10px;
  padding: 5px;
  transition: background-color 0.2s ease-in-out;
  position: relative;
  ${ArrowSort} {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid ${(props: any) => props.theme.neutral60};
    margin-left: 6px;
    opacity: 1;
    transition: transform 0.2s ease-in-out;
    position: relative;
    top: 1px;
  }
  &:hover {
    background-color: ${(props: any) => props.theme.neutral05};
    cursor: pointer;
    ${PageList} {
      transform: scale(1);
    }
    ${ArrowSort} {
      transform: rotate(180deg);
    }
  }
`;

export const RowText = styled.div`
  color: ${(props: any) => props.theme.neutral80};
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  font-weight: 500;
  flex: 0 0 auto;
`;

export const Pages = styled.div`
  align-items: center;
  color: ${(props: any) => props.theme.neutral80};
  display: flex;
  font-size: ${(props: any) => props.theme.sizes.xsmall};
  font-weight: 500;
  margin-left: 20px;
`;

export const Arrows = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 20px;
`;

export const ArrowButton = styled.div`
  width: 30px;
  height: 30px;
  flex: 0 0 auto;
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  svg {
    transform: scale(0.4);
    position: relative;
    left: 2px;
    &.prev-page {
      transform: scale(0.4) rotate(180deg);
      left: -2px;
    }
  }
  &:hover {
    background-color: ${(props: any) => props.theme.neutral05};
    cursor: pointer;
  }
  &:first-of-type {
    margin-right: 5px;
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
`;
