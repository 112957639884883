import { observer } from 'mobx-react-lite';
import archiveStore from 'stores/archiveStore';
import {
  CardItem,
  LoadMoreTilesButton,
  ResultsList,
  TruncatedDiv,
  WrapperLoadItems
} from './resultsTabs.styles';
import { useCallback, useState } from 'react';
import { findRel, handleClickCapture, transformDateTime } from 'views/archive/helpers';
import { useStores } from 'config/hooks';
import { loadMoreItems } from 'services/archiveService';
import loadingNotificationStore from 'stores/loadingNotificationStore';

export const ResultsTab = observer(() => {
  const {
    rootStore: { userStore }
  } = useStores();
  const [isDisabled, setIsDisabled] = useState(false);

  const getTokenFromStore = useCallback(async () => {
    const token = await userStore.auth0Client?.getTokenSilently();
    return token;
  }, [userStore]);

  const handleNextItems = () => {
    setIsDisabled(true);
    loadingNotificationStore.setText('Bringing in more items...');
    loadingNotificationStore.setLoading(true);
    const nextRel = findRel(archiveStore.searchResultsThumbnail?.links, 'next');
    const fetchMoreItems = async (nextRel: LinkSearchObject) => {
      const token = await getTokenFromStore();
      loadMoreItems(
        token,
        nextRel.body,
        response => {
          if (response.data.features.length !== 0) {
            archiveStore.setActiveThumbnail(null);
            archiveStore.setActiveTilesData(null);
            archiveStore.setSearchResultsThumbnail(response.data);
            archiveStore.setShowMap(true);
            loadingNotificationStore.setLoading(false);
          }
        },
        (e: any) => console.error(e)
      );
    };

    if (!!nextRel) fetchMoreItems(nextRel);
    setIsDisabled(false);
  };

  const handlePrevItems = () => {
    setIsDisabled(true);
    loadingNotificationStore.setText('Bringing back previous items...');
    loadingNotificationStore.setLoading(true);
    const prevRel = findRel(archiveStore.searchResultsThumbnail?.links, 'previous');
    const fetchPrev = async (prevRel: LinkSearchObject) => {
      const token = await getTokenFromStore();
      loadMoreItems(
        token,
        prevRel.body,
        response => {
          if (response.data.features.length !== 0) {
            archiveStore.setActiveThumbnail(null);
            archiveStore.setActiveTilesData(null);
            archiveStore.setSearchResultsThumbnail(response.data);
            archiveStore.setShowMap(true);
            loadingNotificationStore.setLoading(false);
          }
        },
        (e: any) => console.error(e)
      );
    };

    if (!!prevRel) fetchPrev(prevRel);
    setIsDisabled(false);
  };

  const handleClickCard = async (elem: FeatureSearch) => {
    archiveStore.setActiveThumbnail(elem);
    const token = await getTokenFromStore();
    handleClickCapture(elem, token);
  };

  return (
    <>
      {!!archiveStore.searchResultsThumbnail &&
      archiveStore.searchResultsThumbnail.features.length !== 0 ? (
        <>
          <ResultsList>
            {archiveStore.searchResultsThumbnail.features.map(elem => (
              <CardItem
                isSelected={
                  archiveStore.activeThumbnail?.properties['satl:outcome_id'] ===
                  elem.properties['satl:outcome_id']
                }
                key={`${elem.properties['satl:outcome_id']}/${elem.properties.datetime}/${elem.properties['view:off_nadir']}/${elem.properties.platform}/${elem.collection.length}`}
                onClick={() => {
                  handleClickCard(elem);
                }}
              >
                <TruncatedDiv data-full-name={elem.properties['satl:outcome_id']}>
                  {elem.properties['satl:outcome_id']}
                </TruncatedDiv>
                <div className="row">
                  <p>Date</p>
                  <p>{transformDateTime(elem.properties.datetime)}</p>
                </div>
                <div className="row">
                  <p>Product</p>
                  <p>{elem.properties.platform}</p>
                </div>
                <div className="row">
                  {' '}
                  <p>Cloud Coverage Avarage</p>
                  <p>{elem.properties['eo:cloud_cover'].toFixed(1)} % </p>
                </div>
                <div className="row">
                  {' '}
                  <p>ONA deg</p>
                  <p>{elem.properties['view:off_nadir'].toFixed(2)} </p>
                </div>
              </CardItem>
            ))}
            <WrapperLoadItems>
              {!!findRel(archiveStore.searchResultsThumbnail?.links, 'previous') ? (
                <LoadMoreTilesButton
                  onClick={handlePrevItems}
                  small
                  text="< Prev items"
                  disabled={isDisabled}
                />
              ) : null}
              {!!findRel(archiveStore.searchResultsThumbnail?.links, 'next') ? (
                <LoadMoreTilesButton
                  onClick={handleNextItems}
                  text="Next items >"
                  small
                  disabled={isDisabled}
                />
              ) : null}
            </WrapperLoadItems>
          </ResultsList>
        </>
      ) : (
        <h1>No results found</h1>
      )}
    </>
  );
});

ResultsTab.displayName = 'Results';
