import { model, Model, modelAction, prop, tProp, types } from 'mobx-keystone';
import { toJS, computed } from 'mobx';
import { isAfter, format } from 'date-fns';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Extent, getCenter } from 'ol/extent';
import {
  getTasks as getTasksService,
  getProducts,
  getProjects,
  getCapturesPerTask,
  cancelTask
} from 'services';
import { Project, Task, TaskFiltersData } from 'typings';
import { mobxRootContext } from 'contexts';
import { SCOPES } from 'config';
import { isAreaProductById } from 'config/helpers';
import { getTask } from 'services/taskService';

@model('geodas/Tasking')
export class TaskStore extends Model({
  tasks: prop<any>(),
  products: prop<any[]>(),
  projects: prop<any>(),
  activeTask: prop<string | number>(),
  filtering: tProp(types.boolean, false),
  filters: prop<any>(),
  topBarExpanded: tProp(types.boolean, false),
  hasNext: tProp(types.boolean, true)
}) {
  originalTasks: Task[] = [];

  init() {
    if (mobxRootContext.get(this)?.userStore.scope.includes(SCOPES.TASKING)) {
      if (!this.projects) this.fetchProjects();
      if (this.hasNext && !this.tasks) this.fetchTasks();
      if (this.products?.length === 0) this.fetchProducts();
    }
  }

  @computed
  get detachedTasks() {
    return toJS(this.tasks);
  }

  @computed
  get tasksByDefault() {
    return this.tasks
      ? Object.keys(this.tasks).sort((a: string, b: string) =>
          a.toLowerCase() > b.toLowerCase() ? 1 : -1
        )
      : [];
  }

  @computed
  get taskNames() {
    return this.tasks ?
      this.tasks.map((task: Task) => {
        return {
          task_name: task.task_name,
          task_id: task.task_id,
          project_name: task.project_name
        }
      }) : undefined
  }

  @modelAction
  setFiltering(v: boolean = !this.filtering) {
    this.filtering = v;
  }

  @modelAction
  setErrorsMsg(msg: string, big: boolean = false) {
    big
      ? mobxRootContext.get(this)?.notificationStore.addBig({ description: msg })
      : mobxRootContext.get(this)?.notificationStore.add({ description: msg })
  }

  @modelAction
  setActiveTask(id: number | string) {
    const taskId = id;
    this.activeTask = id;
    if (id) {
      const task = this.tasks.find((item: Task) => parseInt(item.task_id) === taskId);
      if (task && task.status && !task.captures) this.getCaptures(task)
      if (task && typeof task.expected_age === "undefined") this.getTaskInfo(task)
    }
  }

  @modelAction
  setTasks(l: Task[], replace: boolean = true) {
    this.tasks = replace ? l : this.tasks ? [...this.tasks, ...l] : l;
  }

  setOriginalTasks(l: Task[], replace: boolean = true) {
    this.originalTasks = replace ? l : this.originalTasks ? [...this.originalTasks, ...l] : l;
  }

  @modelAction
  setHasNext(l: boolean) {
    this.hasNext = l;
  }

  @modelAction
  setProducts(l: any[]) {
    this.products = l;
  }

  @modelAction
  setFilters(f: TaskFiltersData) {
    this.filters = f;
  }

  @modelAction
  setTopBarExpanded(v: boolean = !this.topBarExpanded) {
    this.topBarExpanded = v;
  }

  setTaskInfo(t: Task, data: any) {
    const i = this.tasks?.findIndex((item: Task) => item.task_id === t.task_id);
    const tasks = this.detachedTasks;

    tasks[i] = {
      ...tasks[i],
      ...data
    };

    this.setTasks(tasks);
  }

  setCaptures(t: Task, captures: any[]) {
    const i = this.tasks?.findIndex((item: Task) => item.task_id === t.task_id);
    const tasks = this.detachedTasks;

    tasks[i] = {
      ...tasks[i],
      captures
    };

    this.setTasks(tasks);
  }

  @modelAction
  setProjects(l: Project[]) {
    this.projects = [];
    this.projects = l;
  }

  @modelAction
  addProject(l: Project, callback?: () => void) {
    const projects = toJS(this.projects) || [];
    projects.push(l);
    this.projects = projects;
    if (callback) callback();
  }

  generateCenter(task: Task) {
    switch (task.target?.type) {
      case 'Polygon':
        const polyLngLat = new Polygon(task.target?.coordinates as any).getInteriorPoint().getCoordinates();
        task.areaCenter = { lat: polyLngLat[0], lng: polyLngLat[1] };
        return task;
      case 'MultiPolygon':
        const multiExtent = new MultiPolygon(task.target?.coordinates as any).getExtent() as Extent
        const multiLngLat = getCenter(multiExtent);
        task.areaCenter = { lat: multiLngLat[0], lng: multiLngLat[1] };
        return task;
      default:
        return task;
    }
  }

  async fetchTasks(offset: number = 0) {
    const limit = 100
    const token = await mobxRootContext.get(this)?.userStore.auth0Client?.getTokenSilently();
    getTasksService(
      { token, limit, offset },
      r => {
        this.setTasks(r.data.results, false);
        this.setOriginalTasks(r.data.results, false);
        if (!!r.data.next) {
          this.fetchTasks(offset + limit);
        } else {
          this.setErrorsMsg(`All tasks were loaded successfully.`);
          this.setHasNext(false);
        }
      },
      error => {
        this.setHasNext(false);
        this.setErrorsMsg(`Error while fetching all tasks. Please reload.`, true);
        console.log(`Error while fetching tasks`);
        console.log(error);
      }
    );
  }

  applyFilters(filters: TaskFiltersData) {
    this.setActiveTask('');
    let { query } = filters;
    const { status, products, startDate, endDate, taskId } = filters;
    this.setFilters({
      query: query,
      status: toJS(status.map(item => toJS(item))),
      products: toJS(products.map(item => toJS(item))),
      startDate: startDate ? format(new Date(startDate), 'yyyy/MM/dd') : '',
      endDate: endDate ? format(new Date(endDate), 'yyyy/MM/dd') : '',
      taskId
    });

    this.setFiltering(
      !!query || status.length > 0 || products.length > 0 || !!startDate || !!endDate || !!taskId
    );

    // Convert query to lowercase
    query = query?.toLocaleLowerCase();

    let statusFilters: string[], productFilters: string[];
    if (status) statusFilters = status.map(statusOption => statusOption.value);
    if (products) productFilters = products.map(productOption => productOption.value.toString());

    const filterCondition = (task: Task) => {
      return (
        (query === '' ||
          (query &&
            (task.task_name.toLowerCase().includes(query) ||
              task.project_name.toLowerCase().includes(query)))) &&
        (status.length === 0 || (!!task.status && statusFilters.includes(task.status))) &&
        (products.length === 0 ||
          (!!task.product && productFilters.includes(task.product.toString()))) &&
        (startDate === '' ||
          (!!task.start && isAfter(new Date(task.start), new Date(startDate)))) &&
        (endDate === '' || (!!task.end && isAfter(new Date(endDate), new Date(task.end)))) &&
        (taskId === '' || (!!taskId && task.task_id.toString().includes(taskId)))
      );
    };

    const filteredTasks: Task[] = this.originalTasks.filter((task: Task) => filterCondition(task));
    this.setTasks(filteredTasks, true);
  }

  @modelAction
  async getTaskInfo(t: Task) {
    const token = await mobxRootContext.get(this)?.userStore.auth0Client?.getTokenSilently();
    getTask(
      t.task_id,
      { token },
      r => {
        const generatedTask = r.data.target?.coordinates && r.data.target.type === 'Point' ? r.data : this.generateCenter(r.data)
        this.setTaskInfo(t, generatedTask)
      },
      () => {
        this.setErrorsMsg(
          `Error while fetching captures for task "${t.task_name}", id: "${t.task_id}". Please try again later.`
        );
        console.log(
          `Error while fetching captures for task "${t.task_name}", id: "${t.task_id}". Please try again later.`
        );
      }
    )
  }

  async getCaptures(t: Task) {
    const token = await mobxRootContext.get(this)?.userStore.auth0Client?.getTokenSilently();
    getCapturesPerTask(
      t.task_id,
      { token },
      r => {
        this.setCaptures(t, r.data.results);
      },
      () => {
        this.setErrorsMsg(
          `Error while fetching captures for task "${t.task_name}", id: "${t.task_id}". Please try again later.`
        );
        console.log(
          `Error while fetching captures for task "${t.task_name}", id: "${t.task_id}". Please try again later.`
        );
      }
    );
  }

  async fetchProducts() {
    const token = await mobxRootContext.get(this)?.userStore.auth0Client?.getTokenSilently();
    getProducts(
      { token },
      r => {
        if (r.data.results.length > 0) this.setProducts(r.data.results);
      },
      r => {
        this.setErrorsMsg('Error while fetching products. Please try again later.');
        console.log('Error while fetching products', r);
      }
    );
  }

  async fetchProjects() {
    const token = await mobxRootContext.get(this)?.userStore.auth0Client?.getTokenSilently();
    getProjects(
      { token },
      r => {
        if (r.data.results) {
          this.setProjects(r.data.results);
        }
      },
      r => {
        this.setErrorsMsg('There has been a problem loading the projects. Please try again later.');
        console.log('Error while fetching projects', r);
      }
    );
  }

  orderTasksByStart(l: Task[]) {
    return l.sort((a: Task, b: Task) => (a.start > b.start ? -1 : 1));
  }

  addTask(t: Task) {
    const task = isAreaProductById(t.product, this.products) ? this.generateCenter(t) : t;
    let tasks: Task[] = this.detachedTasks || [];
    tasks.push(task);
    tasks = this.orderTasksByStart(tasks);
    this.originalTasks.push(task);
    this.originalTasks = this.orderTasksByStart(this.originalTasks);
    this.setTasks(tasks);
  }

  async cancelTask(task: Task, success?: () => void) {
    const token = await mobxRootContext.get(this)?.userStore.auth0Client?.getTokenSilently();
    cancelTask(
      task.task_id,
      { token },
      () => {
        const i = this.tasks.findIndex((item: Task) => item.task_id === task.task_id);
        const tasks = this.detachedTasks;
        tasks[i].status = 'canceled';
        this.setTasks(tasks);
        if (success) success();
      },
      (error) => {
        this.setErrorsMsg(`Error while canceling task "${task.task_name}", id: "${task.task_id}"`);
        console.log('Error while canceling task', task.task_id, task.task_name);
      }
    );
  }
}
