import { DropdownOptions } from "typings"

export const statusOptions: DropdownOptions[] = [
  {
    value: 'received',
    text: 'Received',
  },
  {
    value: 'planning',
    text: 'Planning',
  },
  {
    value: 'pending',
    text: 'Pending',
  },
  {
    value: 'in_progress',
    text: 'In Progress',
  },
  {
    value: 'canceled',
    text: 'Canceled',
  },
  {
    value: 'failed',
    text: 'Failed',
  },
  {
    value: 'rejected',
    text: 'Rejected',
  },
  {
    value: 'partially_completed',
    text: 'Partially Completed',
  },
  {
    value: 'completed',
    text: 'Completed',
  },
]

export const productOptions: DropdownOptions[] = [
  {
    value: 'target-capture',
    text: 'Target Capture',
  },
  {
    value: 'video-capture',
    text: 'Video Capture',
  },
]
