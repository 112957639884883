import styled from '@emotion/styled/macro';

export const SimpleDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DropDownHeader = styled.div`
  height: 40px;
  align-items: center;
  background: ${(props: any) => props.theme.neutral};
  border: 1px solid ${(props: any) => props.theme.neutral40};
  border-radius: 4px;
  color: transparent;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  justify-content: space-between;
  padding: 10px 20px;
  transition: all 0.2s ease-in-out;
  .chevron-icon {
    transform: scale(0.85);
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    border-color: ${(props: any) => props.theme.primary};
    .chevron-icon {
      path {
        stroke: ${(props: any) => props.theme.primary};
      }
    }
  }
`;

export const DropDownContainer = styled.div`
  position: relative;
`;

export const DropDownList = styled.ul`
  width: 100%;
  background: ${(props: any) => props.theme.neutral};
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  list-style: none;
  margin-top: 2px;
  overflow-y: auto;
  padding-top: 10px;
  position: absolute;
  z-index: 9999;
  &.maxHeight {
    max-height: 290px;
  }
`;

export const ListItem = styled.li`
  width: 100%;
  align-items: center;
  color: ${(props: any) => props.theme.neutral80};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: flex-start;
  line-height: 20px;
  &.action {
    border-top: 1px solid #cdcdcd;
    padding: 15px 20px;
    svg {
      margin-right: 5px;
    }
  }
  & .checkbox {
    width: 100%;
    padding: 10px 20px;
  }
  & .list-item {
    width: 100%;
    padding: 10px 20px;
  }
`;

export const Title = styled.h5`
  height: 20px;
  background-color: ${(props: any) => props.theme.neutral};
  color: ${(props: any) => props.theme.neutral80};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  margin: auto;
  transition: all 0.2s ease-out;
  z-index: 1;
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  &:hover {
    color: ${(props: any) => props.theme.primary};
  }
`;

export const Helper = styled.p`
  color: ${(props: any) => props.theme.neutral40};
  font-size: 12px;
  font-weight: 550;
  margin-top: 10px;
`;

export const Main = styled.div`
  width: 100%;
  position: relative;
  &.active {
    ${Title} {
      padding: 0 2px;
      color: ${(props: any) => props.theme.secondary10};
      ${(props: any) => props.theme.caption.styles};
      transform: translateY(-20px);
    }
    ${DropDownHeader} {
      border-color: #00b6da;
      .chevron-icon {
        transform: rotate(180deg) scale(0.85);
        path {
          stroke: #00b6da;
        }
      }
    }
  }
  &.selected {
    ${Title} {
      padding: 0 2px;
      color: ${(props: any) => props.theme.neutral40};
      ${(props: any) => props.theme.caption.styles};
      transform: translateY(-20px);
    }
    ${DropDownHeader} {
      color: ${(props: any) => props.theme.neutral80};
    }
  }
  &.helper {
    ${Title} {
      transform: translateY(-30px);
    }
  }
  &.disabled {
    ${Title} {
      background-color: transparent;
      color: #a8aaaf;
      .disabled-background {
        width: 100%;
        height: 10px;
        background-color: ${(props: any) => props.theme.neutral05};
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    ${DropDownHeader} {
      background-color: ${(props: any) => props.theme.neutral05};
      border-color: ${(props: any) => props.theme.neutral40};
      cursor: not-allowed;
      &:hover {
        border-color: ${(props: any) => props.theme.neutral40};
      }
    }
  }
`;

export const HeaderText = styled.span`
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
