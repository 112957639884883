import styled from '@emotion/styled';

export const BlankContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  & svg {
    margin-bottom: 24px;
  }
  & button {
    margin-bottom: 24px;
  }
`;

export const Title = styled.h3`
  color: ${(props: any) => props.theme.neutral60};
  ${(props: any) => props.theme.subtitle.styles};
  margin-bottom: 24px;
  text-align: center;
`

export const Text = styled.p`
  color: ${(props: any) => props.theme.neutral60};
  ${(props: any) => props.theme.body.styles};
  line-height: 20px;
  margin-bottom: 24px;
  text-align: center;
`

export const Link = styled.a`
  color: ${(props: any) => props.theme.secondary10};
  cursor: pointer;
  ${(props: any) => props.theme.small.styles};
  margin-bottom: 24px;
`
