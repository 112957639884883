import { CrossIcon, LoadingIcon } from 'components/icons';
import { observer } from 'mobx-react-lite';
import { LoadingWrapper } from './loadingNotification.styles';
import loadingNotificationStore from 'stores/loadingNotificationStore';

export const LoadingNotification = observer(() => {
  return (
    <>
      {loadingNotificationStore.loading ? (
        <LoadingWrapper>
          <div className="icon">
            <LoadingIcon width="26px" height="26px" />
          </div>
          <h1>{loadingNotificationStore.text}</h1>
        </LoadingWrapper>
      ) : loadingNotificationStore.noResults ? (
        <LoadingWrapper>
          <CrossIcon size="20px" />
          <h1>No results found</h1>
        </LoadingWrapper>
      ) : loadingNotificationStore.errorMessage ? (
        <LoadingWrapper>
          <CrossIcon size="20px" />
          <h1>{loadingNotificationStore.errorMessage}</h1>
        </LoadingWrapper>
      ) : null}
    </>
  );
});

LoadingNotification.displayName = 'LoadingNotification';
