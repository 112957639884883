import styled from '@emotion/styled/macro';
import { InfoIcon } from 'components/icons';

export const TextareaWrapper = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.2s ease-out;
  position: relative;
  & + & {
    margin-top: 30px;
  }
  &.warning {
    background-color: rgba(253, 226, 109, 0.9);
  }
  &.open {
    height: 35px;
    padding: 10px 0;
    opacity: 1;
  }
  svg {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    path {
      fill: ${(props: any) => props.theme.primary};
    }
  }
`;

export const ErrorText = styled.span`
  color: ${(props: any) => props.theme.errorDark};
  display: block;
  font-family: 'Public Sans', sans-serif;
  ${(props: any) => props.theme.caption.styles};
  line-height: 20px;
`;

export const HelperText = styled.span`
  color: ${(props: any) => props.theme.neutral60};
  display: block;
  font-family: 'Public Sans', sans-serif;
  ${(props: any) => props.theme.caption.styles};
  line-height: 20px;
`;

export const BottomText = styled.div`
  max-height: 0;
  overflow: hidden;
  padding-top: 5px;
  transition: max-height 0.2s ease-in-out;
  &.shown {
    max-height: 40px;
  }
`;

export const Label = styled.label`
  height: 20px;
  background-color: ${(props: any) => props.theme.neutral};
  color: ${(props: any) => props.theme.neutral80};
  ${(props: any) => props.theme.small.styles};
  line-height: 20px;
  margin: auto;
  padding: 0 2px;
  pointer-events: none;
  transition: all 0.2s ease-out;
  z-index: 1;
  position: absolute;
  left: 13px;
  top: 10px;
  &:hover {
    color: ${(props: any) => props.theme.primary};
  }
`;

export const InfoIconStyled = styled(InfoIcon)`
  margin: auto;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
`;

export const TextareaStyled = styled.textarea`
  width: 100%;
  min-height: 120px;
  background-color: ${(props: any) => props.theme.neutral};
  border: 1px solid ${(props: any) => props.theme.neutral40};
  border-radius: 4px;
  font-family: 'Public Sans', sans-serif;
  font-size: 13px;
  line-height: 18px;
  outline: none;
  padding: 12px;
  resize: none;
  &.black {
    border-color: ${(props: any) => props.theme.neutral100};
    color: ${(props: any) => props.theme.neutral100};
    &::placeholder {
      color: #888;
    }
  }
  &.active,
  &:valid {
    & + ${Label} {
      color: ${(props: any) => props.theme.neutral40};
      font-size: 0.75rem;
      font-weight: 400;
      top: -10px;
    }
  }
  &:focus {
    border-color: ${(props: any) => props.theme.primary};
    & + ${Label} {
      color: ${(props: any) => props.theme.primary};
      font-size: 0.75rem;
      font-weight: 400;
      top: -10px;
    }
  }
  &.error {
    border-color: ${(props: any) => props.theme.errorDark};
    & + ${Label} {
      color: ${(props: any) => props.theme.errorDark};
    }
  }
  &:disabled {
    background-color: #f6f6f6;
    border-color: #e8e8e8;
    &:hover {
      border-color: #e8e8e8;
    }
    & + ${Label} {
      background-color: #f6f6f6;
      color: #a8aaaf;
    }
  }
`;
