import styled from '@emotion/styled/macro';
import { Button } from 'components/button';

export const ResultsList = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;
export const CardItem = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  font-size: 14px;
  border: 1px solid ${(props: any) => (props.isSelected ? 'none' : props.theme.neutral20)};
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  background-color: ${props => (props.isSelected ? '#54B3DB70' : '#fff')};
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  :hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    cursor: pointer;
    background-color: #f2f2f2;
  }
`;

export const TruncatedDiv = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;

  &:focus,
  &:hover {
    color: transparent;
  }

  &:focus:after,
  &:hover:after {
    content: attr(data-full-name);
    position: absolute;
    left: 11px;
    top: 11px;
    white-space: nowrap;
    color: #000000;
    padding: 5px;
    border-radius: 3px;
    z-index: 1;
  }
`;

export const LoadMoreTilesButton = styled(Button)`
  //position: absolute;
  left: 70px;
  bottom: 10%;
`;

export const WrapperLoadItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
