import { useContext, useEffect } from 'react';
import { MapContext } from '../mapContext';

import * as ol from 'ol';
import { GeoJSON } from 'ol/format';

interface Props {
  id: string;
  // Geojson
  geojson: any;
  // Zooms to the feature
  zoom?: boolean;
  // Do animation when zooming
  animate?: boolean;
  // Is geofence
  geofence?: boolean;
}

export const VectorFeature = ({ id, geojson, zoom, animate, geofence = false }: Props) => {
  const { map, vectorLayer, drawVectorLayer } = useContext(MapContext);

  const ftFromGeoJson = (geojson: any) => {
    return new GeoJSON().readFeature(geojson, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    });
  };

  useEffect(() => {
    if (!map) return;

    // Generates feature and add it to the map
    let feature: ol.Feature = ftFromGeoJson(geojson);
    feature.setId(id);
    if (!!geofence) {
      vectorLayer?.getSource().addFeature(feature);
    } else {
      drawVectorLayer?.getSource().addFeature(feature);
    }

    if (zoom) {
      if (geojson.coordinates[0][1]) {
        // Unshift it and re-generate geojson
        if (geofence) geojson.coordinates[0].shift();
        feature = ftFromGeoJson(geojson);
        const zoomToFeature = () => {
          let zoom = map.getView().getZoom();
          if (zoom) {
            zoom -= 0.5;
            if (animate) {
              map.getView().animate({
                zoom,
                duration: 1000
              });
            } else {
              map.getView().setZoom(zoom);
            }
          }
        };
        const ext = feature.getGeometry()?.getExtent();
        if (ext) {
          if (geofence) {
            map.getView().fit(ext);
            zoomToFeature();
          } else {
            map.getView().fit(ext, { duration: 1000, callback: zoomToFeature });
          }
        }
      }
    }

    return () => {
      try {
        if (map) {
          if (geofence) {
            vectorLayer?.getSource().removeFeature(vectorLayer?.getSource().getFeatureById(id));
          } else {
            drawVectorLayer
              ?.getSource()
              .removeFeature(drawVectorLayer?.getSource().getFeatureById(id));
          }
        }
      } catch (error) {
        //
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};
