import React from "react";

import { Button } from 'components/button';

import { BlankContainer, Title, Text, Link } from "./notask.styles";

interface props {
  className?: string
  icon: JSX.Element
  title: string
  text: string
  buttonText?: string
  buttonAction?: () => void;
  linkText?: string
  linkAction?: () => void;
}

export const NoTask = ({ className, icon, title, text, buttonText, buttonAction, linkText, linkAction}: props) => {
  return (
    <BlankContainer className={className}>
      {icon}
      <Title>{title}</Title>
      <Text>{text}</Text>
      {buttonText && <Button text={buttonText} onClick={buttonAction} secondary small />}
      {linkText && <Link onClick={linkAction}>{linkText}</Link>}
    </BlankContainer>
  )
}


NoTask.displayName = "NoTask";
