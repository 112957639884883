import styled from '@emotion/styled/macro';

export const TaskDetailStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props: any) => props.theme.neutral};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &.rounded {
    border-radius: 16px 16px 0 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  min-height: 144px;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const ImageContainer = styled.div`
  min-height: 144px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  .close--icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
    z-index: 2;
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.3));
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .pin--icon {
    width: 20px;
    transform: translateY(-50%);
    z-index: 2;
    position: relative;
  }
`;

export const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  right: 0;
`;

export const TaskInfo = styled.div`
  background-color: ${(props: any) => props.theme.primary};
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 15px;
`;

export const TaskTitle = styled.h2`
  color: ${(props: any) => props.theme.neutral};
  ${(props: any) => props.theme.subtitle.styles};
`;

export const TaskCoordinates = styled.div`
  color: ${(props: any) => props.theme.neutral05};
  ${(props: any) => props.theme.small.styles};
`;

export const TaskContent = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

export const TaskDesc = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: ${(props: any) => props.theme.neutral80};
  display: block;
  ${(props: any) => props.theme.caption.styles};
  line-height: 1.25rem;
  margin: 15px auto 0;
  padding: 15px;
`;

export const TaskRow = styled.div`
  border-bottom: 1px solid ${(props: any) => props.theme.neutral10};
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  padding: 15px 0;
  &:last-of-type {
    border-bottom: 0;
  }
  &.space-between {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    &.wrap {
      flex-wrap: wrap;
    }
  }
  .loading--icon {
    width: 14px;
    stroke: ${(props: any) => props.theme.primary};
  }
`;

export const TaskRowTitle = styled.h4`
  color: ${(props: any) => props.theme.neutral60};
  padding-bottom: 15px;
  ${(props: any) => props.theme.small.styles};
`;

export const TaskType = styled.div`
  color: ${(props: any) => props.theme.neutral80};
  ${(props: any) => props.theme.small.styles};
`;

export const TaskDate = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  ${(props: any) => props.theme.small.styles};
  .calendar--icon {
    margin-right: 10px;
  }
  .arrow--icon {
    margin: 0 10px;
    transform: rotate(-90deg);
  }
  .date span {
    display: block;
  }
`;

export const UpcomingCapturesList = styled.div`
  width: 100%;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
`;

export const UCListItem = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  & + & {
    margin-top: 15px;
  }
  svg {
    fill: ${(props: any) => props.theme.primary};
    margin-right: 10px;
  }
`;

export const UCItemDate = styled.span`
  color: ${(props: any) => props.theme.neutral80};
  ${(props: any) => props.theme.small.styles};
  letter-spacing: 0.1px;
  line-height: 24px;
`;

export const UCItemStatus = styled.span`
  color: ${(props: any) => props.theme.neutral40};
  ${(props: any) => props.theme.caption.styles};
  line-height: 20px;
  position: absolute;
  right: 0;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  .loading--icon {
    width: 32px;
    stroke: ${(props: any) => props.theme.primary};
  }
`;

export const DataLabel = styled.span`
  color: ${(props: any) => props.theme.neutral80};
  font-size: ${(props: any) => props.theme.sizes.small};
  font-weight: 500;
  margin-bottom: 15px;
`;

export const NoDataLabel = styled.span`
  color: ${(props: any) => props.theme.neutral40};
  ${(props: any) => props.theme.small.styles};
`;

export const CancelButtonContainer = styled.div`
  width: 100%;
  background-color: ${(props: any) => props.theme.neutral};
  flex: 0 0 auto;
  padding: 15px;
  .cancel-button {
    width: 100%;
  }
`;

export const markerIconStyle = {
  position: 'absolute',
  zIndex: 100,
  top: 'calc(50% - 15px)', //34/2
  left: 'calc(50% - 9px)' //24/2 - 3px de margin
};
