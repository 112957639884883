import { model, Model, prop } from 'mobx-keystone';
import { mobxRootContext } from 'contexts';

import { DashboardStore } from './dashboardStore';
import { MapStore } from 'components/oldMap/map.store';
import { SearchModalStore } from 'components/searchmodal/searchmodal.store';
import { UserStore } from './userStore';
import { NotificationStore } from './notificationStore';
import { TaskStore } from './taskStore';

@model('geodas/RootStore')
export class RootStore extends Model({
  dashboardStore: prop<DashboardStore>(),
  mapStore: prop<MapStore>(),
  searchModalStore: prop<SearchModalStore>(),
  userStore: prop<UserStore>(),
  notificationStore: prop<NotificationStore>(),
  taskStore: prop<TaskStore>()
}) {
  onInit() {
    mobxRootContext.setComputed(this, () => this);
  }
}
