import axios from 'axios';
import { model, Model, modelAction, tProp, types } from 'mobx-keystone';
import { NominatimItem } from 'typings/stores';

@model('geodas/SearchModal')
export class SearchModalStore extends Model({
  q: tProp(types.string, ''),
  fetching: tProp(types.boolean, false),
  items: tProp(types.array(types.object(() => ({
    display_name: types.string,
    boundingbox: types.array(types.string),
    lat: types.string,
    lon: types.string,
  }))), () => [])
}) {

  cancelToken = axios.CancelToken.source();

  @modelAction
  setQuery(q: string) {
    this.q = q;
    this.fetch();
  }

  @modelAction
  setFetching(v: boolean) {
    this.fetching = v;
  }

  @modelAction
  setItems(items: Array<NominatimItem>) {
    this.items = items.map(item => {
      return {
        display_name: item.display_name,
        boundingbox: item.boundingbox,
        lat: item.lat,
        lon: item.lon,
      }
    })
  }

  async fetch(){
    this.setFetching(true);
    // Cancel prev req
    this.cancelToken.cancel('Axios request canceled.')
    // Set new token
    this.cancelToken = axios.CancelToken.source();
    try {
      const { data } = await axios(`https://nominatim.openstreetmap.org/search/?q=${this.q}&format=json&limit=30&addressdetails=1`, {
        cancelToken: this.cancelToken.token
      })
      this.setItems(data);
      this.setFetching(false);
    } catch (error) {
      console.error(error)
    }

  }
}

