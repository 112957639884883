import React, { FC } from 'react';

import {
  TextareaWrapper,
  TextareaStyled,
  ErrorText,
  Label,
  InfoIconStyled as InfoIcon,
  HelperText,
  BottomText
} from './textarea.styles';

import { classes } from 'config/common';
import { isTextValid } from 'config/helpers';

interface Props {
  className?: string;
  error?: { flag?: boolean; text?: string; validator?: any };
  label?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  type?: string;
  value?: string;
  testid?: string;
  color?: 'black';
  disabled?: boolean;
  icon?: any;
  helperText?: string;
}

export const Textarea: FC<Props> = ({
  value = '',
  className,
  error = {},
  type = 'text',
  name,
  onChange,
  testid = '',
  color,
  label,
  disabled = false,
  icon,
  placeholder,
  helperText
}) => {
  const [currentValue, setValue] = React.useState(value);
  const [showError, setError] = React.useState(error.flag ? error.flag : false);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(e);
      }
      setValue(e.currentTarget.value);
    },
    [onChange]
  );

  React.useEffect(() => {
    setValue(value);
  }, [value]);

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (error.text) {
      if (typeof error.validator === 'function') {
        setError(error.validator(e));
      } else {
        setError(!isTextValid(e.currentTarget.value, type));
      }
    }
  };

  return (
    <TextareaWrapper className={classes(className)}>
      <TextareaStyled
        name={name}
        value={currentValue}
        autoComplete="off"
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes(color, { error: showError, active: currentValue ? true : false })}
        data-testid={testid}
        disabled={disabled}
        placeholder={placeholder}
        required={error ? true : false}
      />
      {label && <Label>{label}</Label>}
      {showError && <InfoIcon />}
      {icon}
      <BottomText className={classes({ shown: showError || !!helperText })}>
        {showError && <ErrorText>{error.text}</ErrorText>}
        {!!helperText && <HelperText>{helperText}</HelperText>}
      </BottomText>
    </TextareaWrapper>
  );
};

Textarea.displayName = 'Textarea';
