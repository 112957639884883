import React from 'react';
import { observer } from 'mobx-react-lite';

import { Container } from 'components/layout';
import { Input } from 'components/input';
import { Checkbox } from 'components/checkbox';
import { Dropdown } from 'components/dropdown';
import { InputDateRange } from 'components/inputdaterange';
import { FiltersIcon, SearchIcon, CrossIcon, CirclePlus } from 'components/icons';
import { ActionMenu } from 'components/actionmenu';
import { Button } from 'components/button';
import { ActiveFilters } from 'components/activefilters';

import {
  TopBarStyled,
  Title,
  SearchForm,
  CreateButton,
  FilterButton,
  FiltersWrapper,
  FiltersContainer
} from './topbar.styles';

import { classes } from 'config/common';
import { useMergeState, useStores } from 'config/hooks';
import { statusOptions } from './filters';
import { DropdownOptions, TaskFiltersData, Product } from 'typings';

export interface Props {
  title: string;
  filters?: {
    default?: TaskFiltersData;
    onApply: (filtersData: TaskFiltersData) => void;
  };
  createButton?: {
    options: any[];
  };
}

export const TopBar = observer(({ title, filters, createButton }: Props) => {
  const {
    rootStore: { taskStore }
  } = useStores();

  const [showFilters, setShowFilters] = React.useState(false);
  const [filtersData, setFiltersData] = useMergeState<TaskFiltersData>({
    query: '',
    status: [],
    products: [],
    startDate: '',
    endDate: '',
    taskId: ''
  });

  React.useEffect(() => {
    if (filters?.default) setFiltersData(filters.default);
    // eslint-disable-next-line
  }, [filters]);

  const clearFilterHandler = async (filterToClear?: string) => {
    switch (filterToClear) {
      case 'Date':
        taskStore.applyFilters({ ...filtersData, startDate: '', endDate: '' });
        break;
      case 'Product':
        taskStore.applyFilters({ ...filtersData, products: [] });
        break;
      case 'Status':
        taskStore.applyFilters({ ...filtersData, status: [] });
        break;
      case 'Task ID':
        taskStore.applyFilters({ ...filtersData, taskId: '' });
        break;
      default:
        taskStore.applyFilters({
          query: '',
          startDate: '',
          endDate: '',
          products: [],
          status: [],
          taskId: ''
        });
        break;
    }
  };

  const activeFilters =
    filtersData.startDate !== '' ||
    filtersData.endDate !== '' ||
    filtersData.products.length > 0 ||
    filtersData.status.length > 0 ||
    filtersData.taskId !== ''

  const filterButtonText = () => {
    if (showFilters) return 'Close Filters';
    if (!showFilters && activeFilters) return 'Edit Filters';
    if (!showFilters && !activeFilters) return 'Filters';
  };

  const activeStatuses: DropdownOptions[] = [
    { value: 'pending', text: 'Pending' },
    { value: 'in_progress', text: 'In Progress' },
    { value: 'received', text: 'Received' },
    { value: 'planning', text: 'Planning' }
  ];

  return (
    <TopBarStyled className={classes({ smaller: !filters })}>
      <Container className="container">
        <Title>{title}</Title>
        {filters && (
          <React.Fragment>
            <SearchForm
              className={classes({ disabled: !taskStore.tasks || taskStore.hasNext })}
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <Input
                name="q"
                label="Search by task or project name"
                icon={<SearchIcon />}
                value={taskStore.filters?.query}
                onChange={e => {
                  const query = e.target.value;
                  setFiltersData({ query });
                  filters.onApply({ ...filtersData, query });
                }}
              />
            </SearchForm>
            <FilterButton
              className={classes({ disabled: !taskStore.tasks || taskStore.hasNext})}
              onClick={() => {
                taskStore.setTopBarExpanded(!showFilters);
                setShowFilters(!showFilters);
              }}
            >
              {showFilters ? (
                <CrossIcon className="action--icon close--icon" />
              ) : (
                <FiltersIcon className="action--icon" />
              )}
              {filterButtonText()}
            </FilterButton>
            <Checkbox
              checked={activeStatuses.every(activeStatus => {
                return taskStore.filters?.status.some(
                  (status: any) => status.value === activeStatus.value
                );
              })}
              className={classes('active-tasks-checkbox', { disabled: !taskStore.tasks || taskStore.hasNext })}
              label="Show only active tasks"
              name="showActiveTasks"
              value="activeTasks"
              onChange={checked => {
                if (checked) {
                  setFiltersData({ status: activeStatuses });
                  filters.onApply({
                    ...filtersData,
                    status: [...filtersData.status, ...activeStatuses]
                  });
                } else {
                  const newStatusFilters = filtersData.status.filter(
                    status =>
                      status.value !== 'pending' &&
                      status.value !== 'in_progress' &&
                      status.value !== 'received' &&
                      status.value !== 'planning'
                  );
                  setFiltersData({ status: newStatusFilters });
                  filters.onApply({
                    ...filtersData,
                    status: newStatusFilters
                  });
                }
              }}
            />
            <ActiveFilters
              activeFilters={taskStore.filters}
              className="active-filters"
              clearFilterAction={(filterToClear: string) => clearFilterHandler(filterToClear)}
            />
            <FiltersWrapper className={classes({ show: showFilters })}>
              <FiltersContainer>
                <Input
                  className="idFilter"
                  name="id"
                  label="Search by Task ID"
                  value={taskStore.filters?.taskId}
                  onChange={e => {
                    const id = e.target.value;
                    setFiltersData({ taskId: id });
                  }}
                />
                <Dropdown
                  className="dropdownFilter"
                  label="Status"
                  options={statusOptions}
                  defaultOptions={taskStore.filters?.status}
                  onChange={(options: DropdownOptions[]) => {
                    setFiltersData({ status: options });
                  }}
                  multiple
                />
                <Dropdown
                  className="dropdownFilter"
                  label="Product"
                  options={taskStore.products?.map((item: Product) => {
                    return { value: item.pk.toString(), text: item.name };
                  })}
                  defaultOptions={taskStore.filters?.products}
                  onChange={(options: DropdownOptions[]) => setFiltersData({ products: options })}
                  multiple
                />
                <InputDateRange
                  className="dateFilter"
                  name="filter-date"
                  onChangeStart={(startDate: Date) => setFiltersData({ startDate })}
                  onChangeEnd={(endDate: Date) => setFiltersData({ endDate })}
                  startLabel="Date from"
                  endLabel="Date to"
                  startValue={taskStore.filters?.startDate}
                  endValue={taskStore.filters?.endDate}
                />
              </FiltersContainer>
              <Button
                className="filterButton"
                text="Apply Filters"
                onClick={() => {
                  filters.onApply(filtersData);
                  taskStore.setTopBarExpanded(false);
                  setShowFilters(false);
                }}
                small
              />
              {activeFilters ? (
                <Button text="Clear filters" onClick={() => clearFilterHandler()} small onlyText />
              ) : null}
            </FiltersWrapper>
          </React.Fragment>
        )}
        <div className="right-actions">
          {createButton && (
            <ActionMenu
              className="fab-button"
              menuClassName="fab-button--menu"
              position="under"
              toggleElement={
                <CreateButton>
                  <CirclePlus className="plus-icon" /> Create
                </CreateButton>
              }
              actions={createButton.options}
            />
          )}
        </div>
      </Container>
    </TopBarStyled>
  );
});

TopBar.displayName = 'TopBar';
